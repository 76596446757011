import React from 'react';
import './messages.css'; // Import your CSS styles
import dayjs from 'dayjs';
// @ts-ignore
import agent from './cato.png';
// @ts-ignore
import customer from './customer.jpeg';
import { Icon } from '@cloudscape-design/components';

export interface UserFeedbackItem {
  id: string;
  question: string;
  rating: string | null;
  comment: string | null;
  answer?: string;
  userType: string | null;
  createdAt: string;
  conversationId: string;
}

interface ConversationProps {
  messages: UserFeedbackItem[];
}

const Conversation: React.FC<ConversationProps> = ({ messages }) => {
  return (
    <div className="message-container" style={{ overflow: 'auto' }}>
      START
      {messages.map((message, index) => (
        <div key={index}>
          <div className={'chat chat-end'}>
            <div className="chat-image">
              <div className="avatar-img">
                <img alt={'Customer'} src={customer} />
              </div>
            </div>
            <div className="chat-content">
              <div className={`chat-header-end`}>
                Customer
                <time className="chat-time"> {dayjs(message.createdAt).format('YYYY MMM DD HH:mm')}</time>
              </div>
              <div className={`chat-bubble chat-bubble-success`}>{message.question}</div>
            </div>
          </div>
          <div className={'chat chat-start'}>
            <div className="chat-image">
              <div className="avatar-img">
                <img alt={'Jeni'} src={agent} />
              </div>
            </div>
            <div className="chat-content">
              <div className={`chat-header-start`}>
                Jeni
                <time className="chat-time"> {dayjs(message.createdAt).format('YYYY MMM DD HH:mm')}</time>
              </div>
              <div className={`chat-bubble chat-bubble-info`}>{message.answer}</div>
              {message.rating === '1' && (
                <div className={'chat-header-end chat chat-end'}>
                  <Icon name="thumbs-up-filled" variant="success" />
                  <div className="feedback-container-1 feedback-container">
                    {message.comment && message.comment.length > 0 ? message.comment : 'No comment'}{' '}
                  </div>
                </div>
              )}
              {message.rating === '0' && (
                <div className={'chat-header-end chat chat-end'}>
                  <Icon name="thumbs-down-filled" variant="error" />
                  <div className="feedback-container-0 feedback-container">
                    {message.comment && message.comment.length > 0 ? message.comment : 'No comment'}{' '}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ))}
      END
    </div>
  );
};

export default Conversation;
