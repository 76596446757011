import React, { useState, useEffect, useRef } from "react";
import { Group } from "@visx/group";
import { Circle } from "@visx/shape";
import { scaleBand, scaleLinear } from "@visx/scale";
import { AxisBottom, AxisLeft } from "@visx/axis";
import { useTooltip, useTooltipInPortal, defaultStyles } from "@visx/tooltip";
import dayjs from "dayjs";
import { localPoint } from "@visx/event";

interface SentimentData {
  interval_start: string;
  queue_name: string;
  customer_average_sentiment: number | null;
  agent_average_sentiment: number | null;
  contact_count: string;
}

interface HeatmapProps {
  data: SentimentData[];
}

const Heatmap: React.FC<HeatmapProps> = ({ data }) => {
  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip<SentimentData>();

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    detectBounds: true,
    scroll: true,
  });

  useEffect(() => {
    const handleResize = () => {
      setContainerWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [containerWidth, setContainerWidth] = useState(window.innerWidth);
  const svgRef = useRef<SVGSVGElement>(null);

  const width = containerWidth;
  const height = 300;
  const margin = { top: 0, right: 20, bottom: 50, left: 100 };

  const queueNames = Array.from(new Set(data.map((d) => d.queue_name))).sort(
    (a, b) => b.localeCompare(a),
  );
  const intervalStarts = Array.from(new Set(data.map((d) => d.interval_start)));

  const xScale = scaleBand({
    domain: intervalStarts,
    range: [margin.left, width - margin.right],
    padding: 0.5,
  });

  const yScale = scaleBand({
    domain: queueNames,
    range: [height - margin.bottom, margin.top],
    padding: 0.5,
  });

  const radiusScale = scaleLinear<number>()
    .domain([-5, 0, 5])
    .range([30, 2, 30]);

  function formatDate(dateStr: string) {
    const date = new Date(dateStr);
    return date.toLocaleDateString("en-US", { month: "short", day: "2-digit" });
  }

  const handleMouseOver = (
    event: React.MouseEvent | React.TouchEvent,
    datum: SentimentData,
  ) => {
    const coords = localPoint(event.currentTarget as Element, event);
    if (coords) {
      showTooltip({
        tooltipLeft: coords.x,
        tooltipTop: coords.y,
        tooltipData: datum,
      });
    }
  };

  if (!data) {
    return null;
  }

  const tooltipStyles = {
    ...defaultStyles,
    minWidth: 60,
    backgroundColor: "white",
    color: "black",
    border: "2px solid #ccc", // Soft grey border
    borderRadius: "8px", // Rounded corners
    padding: "10px",
  };

  function formatDateToCustom(dateStr: string) {
    return dayjs(dateStr).format("MMM D HH:mm");
  }

  return (
    <div style={{ position: "relative" }} ref={containerRef}>
      <svg ref={svgRef} width={width} height={height}>
        <Group>
          <AxisBottom
            top={height - margin.bottom}
            scale={xScale}
            tickFormat={(d) => formatDate(d)}
            tickLabelProps={() => ({ fontSize: 14, textAnchor: "middle" })}
          />

          <AxisLeft
            left={margin.left}
            scale={yScale}
            tickLabelProps={() => ({ fontSize: 14, textAnchor: "end" })}
          />

          {data.map((d) => {
            const x = xScale(d.interval_start);
            const y = yScale(d.queue_name);

            // Skip rendering this circle if x or y is undefined
            if (x === undefined || y === undefined) return null;

            const radius =
              d.customer_average_sentiment !== null
                ? radiusScale(Math.abs(d.customer_average_sentiment))
                : 5;

            return (
              <Circle
                key={d.interval_start + d.queue_name}
                cx={x + xScale.bandwidth() / 2}
                cy={y + yScale.bandwidth() / 2}
                r={radius} // Set the radius dynamically based on the sentiment value
                fill={
                  d.customer_average_sentiment !== null
                    ? d.customer_average_sentiment >= 0.5
                      ? "#67a353"
                      : d.customer_average_sentiment <= -0.5
                      ? "#d13313"
                      : "#529ccb"
                    : "white"
                } // Filling with white where there's no data
                stroke={d.customer_average_sentiment !== null ? "none" : "gray"} // Setting the border to gray where there's no data
                strokeWidth={d.customer_average_sentiment !== null ? 0 : 2} // Setting the border width where there's no data
                onMouseOver={(e) => handleMouseOver(e, d)}
                onMouseOut={hideTooltip}
              />
            );
          })}
        </Group>
        {tooltipOpen && (
          <TooltipInPortal
            key={Math.random()}
            top={tooltipTop}
            left={tooltipLeft}
            style={tooltipStyles}
          >
            <div>
              <p>
                <strong>
                  {formatDateToCustom(tooltipData?.interval_start || "")}
                </strong>
              </p>
              <p>
                <strong>Queue:</strong> {tooltipData?.queue_name}
              </p>
              <p>
                <strong>Sentiment:</strong>{" "}
                {tooltipData?.customer_average_sentiment || "no data"}
              </p>
            </div>
          </TooltipInPortal>
        )}
      </svg>
    </div>
  );
};

export default Heatmap;
