import React from 'react';
import { CollectionPreferences, StatusIndicator, Select } from '@cloudscape-design/components';
import { getAttributeValue, getStatusIndicatorType, getAccountStatusIndicatorType } from './helpers';

const rawColumns = [
  {
    id: 'username',
    header: 'Username',
    cell: (item) => item.Username || '-',
    sortingField: 'Username',
  },
  {
    id: 'name',
    header: 'Full Name',
    cell: (item) => {
      const givenName = getAttributeValue(item.Attributes, 'given_name') || '';
      const familyName = getAttributeValue(item.Attributes, 'family_name') || '';
      return givenName || familyName ? `${givenName} ${familyName}`.trim() : '-';
    },
  },
  {
    id: 'status',
    header: 'Account Status',
    cell: (item) => {
      const statusType = getStatusIndicatorType(item.UserStatus);
      return <StatusIndicator type={statusType}>{item.UserStatus}</StatusIndicator>;
    },
    sortingField: 'UserStatus',
  },
  {
    id: 'status2',
    header: 'User Status',
    cell: (item) => {
      const statusType = getAccountStatusIndicatorType(item.Enabled);
      return <StatusIndicator type={statusType}>{item.Enabled ? 'Active' : 'Disabled'}</StatusIndicator>;
    },
    sortingField: 'Enabled',
  },
  {
    id: 'securityProfile',
    header: 'Security Profile',
  },
];

export const COLUMN_DEFINITIONS = rawColumns.map((column) => ({ ...column }));

const editableColumns = {
  securityProfile: {
    minWidth: 176,
    editConfig: {
      // validation: (item, value) => {
      //     if (item.Username === currentUser) {
      //         return "Editing not allowed on self";
      //     }
      // },
      ariaLabel: 'Security Profile',
      errorIconAriaLabel: 'Security Profile Validation Error',
      editIconAriaLabel: 'editable',
      editingCell: (item, { setValue, currentValue }) => {
        const value = currentValue ?? getAttributeValue(item.Attributes, 'custom:securityProfile');

        return (
          <Select
            autoFocus={true}
            expandToViewport={true}
            ariaLabel="Select desired security profile"
            selectedOption={
              [
                { label: 'Administrator', value: 'Administrator' },
                // {label: "User", value: "User"}
              ].find((option) => option.value === value) ?? null
            }
            onChange={(event) => {
              setValue(event.detail.selectedOption.value);
            }}
            options={[
              { label: 'Administrator', value: 'Administrator' },
              // { label: 'User', value: 'User' },
            ]}
          />
        );
      },
    },
    cell: (item) => {
      return getAttributeValue(item.Attributes, 'custom:securityProfile');
    },
  },
};

export const EDITABLE_COLUMN_DEFINITIONS = COLUMN_DEFINITIONS.map((column) => {
  if (editableColumns[column.id]) {
    return {
      ...column,
      minWidth: Math.max(column.minWidth || 0, 176),
      ...editableColumns[column.id],
    };
  }
  return column;
});

const CONTENT_DISPLAY_OPTIONS = [
  { id: 'username', label: 'Username', alwaysVisible: true },
  { id: 'name', label: 'Full Name' },
  { id: 'status', label: 'Users Status' },
  { id: 'status2', label: 'Account Status' },
  { id: 'securityProfile', label: 'Security Profile' },
];

export const PAGE_SIZE_OPTIONS = [
  { value: 10, label: '10 Users' },
  { value: 30, label: '30 Users' },
  { value: 50, label: '50 Users' },
];

export const DEFAULT_PREFERENCES = {
  pageSize: 10,
  contentDisplay: [
    { id: 'username', visible: true },
    { id: 'name', visible: true },
    { id: 'status', visible: true },
    { id: 'status2', visible: true },
    { id: 'securityProfile', visible: true },
  ],
  wrapLines: false,
  stripedRows: false,
  contentDensity: 'comfortable',
  stickyColumns: { first: 1, last: 0 },
};

export const Preferences = ({
  preferences,
  setPreferences,
  disabled = false,
  pageSizeOptions = PAGE_SIZE_OPTIONS,
  contentDisplayOptions = CONTENT_DISPLAY_OPTIONS,
}) => (
  <CollectionPreferences
    title={'Preferences'}
    cancelLabel={'Cancel'}
    confirmLabel={'Confirm'}
    disabled={disabled}
    preferences={preferences}
    onConfirm={({ detail }) => setPreferences(detail)}
    pageSizePreference={{ title: 'Page size', options: pageSizeOptions }}
    wrapLinesPreference={{ label: 'Wrap lines', description: 'Select to see all the text and wrap the lines' }}
    stripedRowsPreference={{ label: 'Striped rows', description: 'Select to add alternating shaded rows' }}
    contentDensityPreference={{
      label: 'Compact mode',
      description: 'Select to display content in a denser, more compact mode',
    }}
    contentDisplayPreference={{ title: 'Select visible content / order', options: contentDisplayOptions }}
    stickyColumnsPreference={{
      firstColumns: {
        title: 'Stick first column(s)',
        description: 'Keep the first column(s) visible while horizontally scrolling the table content.',
        options: [
          { label: 'None', value: 0 },
          { label: 'First column', value: 1 },
          { label: 'First two columns', value: 2 },
        ],
      },
      lastColumns: {
        title: 'Stick last column',
        description: 'Keep the last column visible while horizontally scrolling the table content.',
        options: [
          { label: 'None', value: 0 },
          { label: 'Last column', value: 1 },
        ],
      },
    }}
  />
);
