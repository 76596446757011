import React, { createContext, useState } from "react";
import { Badge, Header } from "@cloudscape-design/components";
import { WidgetConfig } from "../interfaces";
import ParentSize from "@visx/responsive/lib/components/ParentSize";
import { allContent, Content, WidgetPreferences } from "../preferences";
import IVRLinkTypeChart from "./IVRLinkTypeChart";

export const getCustomerJourney: WidgetConfig = {
  definition: { defaultRowSpan: 4, defaultColumnSpan: 12, minRowSpan: 2 },
  data: {
    icon: "mixedContent",
    title: "Customer Journey",
    description: "Shows the customers path through self service",
    header: ChartHeader,
    content: CustomerJourneyContent,
    provider: CustomerJourneyProvider,
    staticMinHeight: 560,
    multipleAllowed: true,
  },
};

interface CustomerJourneyWidgetContextType {
  visibleContent: ReadonlyArray<Content>;
  openPreferences: () => void;
}

const CustomerJourneyWidgetContext =
  createContext<CustomerJourneyWidgetContextType>({
    visibleContent: [],
    openPreferences: () => {},
  });

function CustomerJourneyProvider({
  children,
  widgetId,
}: {
  children: React.ReactElement;
  widgetId: string;
}) {
  const [preferencesVisible, setPreferencesVisible] = useState(false);
  const [visibleContent, setVisibleContent] =
    useState<ReadonlyArray<Content>>(allContent);
  return (
    <CustomerJourneyWidgetContext.Provider
      value={{
        visibleContent,
        openPreferences: () => setPreferencesVisible(true),
      }}
    >
      {React.cloneElement(React.Children.only(children), {
        removeConfirmationText: "Operational metrics",
      })}
      {preferencesVisible && (
        <WidgetPreferences
          widgetId={widgetId}
          preferences={visibleContent}
          onConfirm={(visibleContent) => {
            setVisibleContent(visibleContent);
            setPreferencesVisible(false);
          }}
          onDismiss={() => setPreferencesVisible(false)}
        />
      )}
    </CustomerJourneyWidgetContext.Provider>
  );
}

function ChartHeader() {
  return (
    <Header variant="h2" description="">
      Customer journey <Badge color="blue">Beta</Badge>
    </Header>
  );
}

export default function CustomerJourneyContent({
  widgetId,
}: {
  widgetId: string;
}) {
  return (
    <ParentSize>
      {({ width, height }) => (
        <IVRLinkTypeChart width={width} height={height} />
      )}
    </ParentSize>
  );
}
