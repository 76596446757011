import React, { useState } from 'react';
import Header from '@cloudscape-design/components/header';
import { Pagination, Spinner, Table } from '@cloudscape-design/components';
import PropertyFilter from '@cloudscape-design/components/property-filter';
import Alert from '@cloudscape-design/components/alert';
import { getTextFilterCounterText } from '../../../../../../../utils/text-filter';
import { useCollection } from '@cloudscape-design/collection-hooks';
import { FILTERING_PROPERTIES as filteringProperties } from './filtering_poperties';
import { useSplitPanelControl } from '../../../../../../../utils/hooks/splitPanelContext';
import { DEFAULT_PREFERENCES, EDITABLE_COLUMN_DEFINITIONS, Preferences } from './table.config';

import { TableNoMatchState } from '../../../../../../../components/table/commons';
import { useColumnWidths } from '../../../../../../../components/table/use-column-width';
import { useLocalStorage } from '../../../../../../../components/use-local-storage';

import UserFeedbackSplitPanelDetails from './userFeedbackSplitPanelDetails';

export interface UserFeedbackItem {
  id: string;
  question: string;
  rating: string | null;
  comment: string | null;
  answer?: string;
  userType: string | null;
  createdAt: string;
  conversationId: string;
}

export interface UserCommentsTableProps {
  isLoading: boolean;
  userFeedback: UserFeedbackItem[];
  error: string;
}

export default function UserCommentsTable({ isLoading, userFeedback = [], error }: UserCommentsTableProps) {
  const [columnDefinitions, saveWidths] = useColumnWidths(
    'Cato-UserCommentsTable-TableEditable-Widths',
    EDITABLE_COLUMN_DEFINITIONS
  );
  const [preferences, setPreferences] = useLocalStorage(
    'Cato-UserCommentsTable-TableEditable-Preferences',
    DEFAULT_PREFERENCES
  );
  const [selectedItems, setSelectedItems] = useState<UserFeedbackItem[]>([]);

  // Hooks
  const { setSplitPanelOpen, setSplitPanelContent } = useSplitPanelControl();

  function onSelectionChange(data: { detail: any }) {
    setSplitPanelContent(<UserFeedbackSplitPanelDetails selectedItems={data.detail[0]} />);
    setSplitPanelOpen(true);
  }

  const { items, actions, filteredItemsCount, collectionProps, propertyFilterProps, paginationProps } = useCollection(
    userFeedback,
    {
      propertyFiltering: {
        filteringProperties,
        noMatch: (
          <TableNoMatchState
            onClearFilter={() => {
              actions.setPropertyFiltering({ tokens: [], operation: 'and' });
            }}
          />
        ),
      },
      filtering: {
        noMatch: (
          <TableNoMatchState
            onClearFilter={() => {
              actions.setPropertyFiltering({ tokens: [], operation: 'and' });
            }}
          />
        ),
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: {},
      selection: {},
    }
  );

  return (
    <>
      <Table
        {...collectionProps}
        stickyHeader={true}
        resizableColumns={true}
        onColumnWidthsChange={saveWidths}
        columnDefinitions={columnDefinitions}
        items={items}
        selectedItems={selectedItems}
        selectionType={'single'}
        onSelectionChange={({ detail: { selectedItems } }) => {
          onSelectionChange({ detail: selectedItems });
          if (selectedItems && selectedItems.length > 0) {
            const selectedItem = selectedItems[0];
            setSelectedItems(() => [selectedItem]);
          }
        }}
        variant="embedded"
        trackBy="id"
        loadingText="Loading..."
        columnDisplay={preferences.contentDisplay}
        wrapLines={preferences.wrapLines}
        stripedRows={preferences.stripedRows}
        // stickyColumns={preferences.stickyColumns} // Do not use, causes react resizer error. Leaving in comment to avoid accidental issues.
        contentDensity={preferences.contentDensity as 'compact' | 'comfortable'}
        loading={isLoading}
        header={
          <Header variant="awsui-h1-sticky">
            Feedback {userFeedback && !isLoading ? `(${userFeedback.length})` : <Spinner />}
          </Header>
        }
        filter={
          <PropertyFilter
            {...propertyFilterProps}
            countText={getTextFilterCounterText(filteredItemsCount)}
            expandToViewport={true}
            filteringAriaLabel={'Filter'}
            filteringPlaceholder={'Filter'}
          />
        }
        pagination={<Pagination {...paginationProps} />}
        preferences={<Preferences preferences={preferences} setPreferences={setPreferences} />}
      />
      {error && (
        <Alert statusIconAriaLabel="Error" type="error" header="Error getting data">
          Refresh the page to try again.
        </Alert>
      )}
    </>
  );
}
