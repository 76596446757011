import React from 'react';
import useSWR from 'swr';
import { SplitPanel, Spinner } from '@cloudscape-design/components';
import fetcher from '../../../../../../../utils/fetcher';
import Conversation from '../../../../../../../components/conversationBubbles/chatbotMessage';

export interface UserFeedbackItem {
  id: string;
  question: string;
  rating: string | null;
  comment: string | null;
  answer?: string;
  userType: string | null;
  createdAt: string;
  conversationId: string;
}

interface ExtendedUserFeedbackItem {
  selectedItems: UserFeedbackItem;
}

const UserFeedbackSplitPanelDetails: React.FC<any> = (selectedItem: ExtendedUserFeedbackItem) => {
  // ${selectedItem.selectedItems.conversationId}
  const { data, isLoading, error } = useSWR(
    `/queries?templateType=getUserFeedbackCommentsId&conversationId=${selectedItem.selectedItems.conversationId}`,
    fetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshInterval: 0,
    }
  );

  if (isLoading || !data || error) {
    return (
      <SplitPanel header={`User Comment Detail:`} closeBehavior="collapse">
        {error ? <>{error.info.message}</> : <Spinner />}
      </SplitPanel>
    );
  }

  const conversation: UserFeedbackItem[] = data.data.slice().reverse();

  return (
    <SplitPanel header={`Conversation id: ${selectedItem.selectedItems.id}`} closeBehavior="hide">
      <Conversation messages={conversation} />
    </SplitPanel>
  );
};

export default UserFeedbackSplitPanelDetails;
