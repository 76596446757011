/* eslint-disable react/prop-types */
import React from 'react';
import { downloadSampleContactFlow } from './downloadSampleContactFlow';
import {
  Header,
  SpaceBetween,
  Box,
  Container,
  ColumnLayout,
  Button,
  Popover,
  StatusIndicator,
} from '@cloudscape-design/components';
import { Link } from 'react-router-dom';
import { useApiWithFlash } from '../../../utils/hooks/useApiWithFlash';
import sampleMessageGroup from '../../../objects/AmznContactFlows/SampleMessageGroup.json';
import sampleClosureGroup from '../../../objects/AmznContactFlows/SampleClosureGroup.json';
import sampleDialPlan from '../../../objects/AmznContactFlows/SampleDialPlan.json';
import Alert from '@cloudscape-design/components/alert';
import fetcher from '../../../utils/fetcher';

// @ts-ignore
const ValueWithLabel = ({ label, children }) => (
  <div>
    <Box variant="awsui-key-label">{label}</Box>
    <div>{children}</div>
  </div>
);

const stopContactCode = `import { ConnectClient, StopContactCommand } from "@aws-sdk/client-connect";

const client = new ConnectClient();

export const handler = async (event) => {

    
    const { Details } = event;
    const { ContactData } = Details || {};
    const { ContactId, InstanceARN } = ContactData || {};

    if (!ContactId || !InstanceARN) {
        console.error('Missing required parameters');
        return {
            statusCode: '400'
        };
    }

    let InstanceId = InstanceARN.split('/').pop(); // Get the instanceId from the instanceARN


    // Construct the command
    const command = new StopContactCommand({
        ContactId,
        InstanceId
    });

    try {
        // Send the command to stop the contact
        await client.send(command);
        return {
            statusCode: 200,
        };
    } catch (error) {
        console.error('Error stopping the contact:', error);
        return {
            statusCode: 500,
        };
    }
};
`;

export default function SimplifySteps({ apiArn }) {
  // Hooks
  const { handleApiWithFlash } = useApiWithFlash();

  // Event handler function
  const handleDeploySampleGroup = async () => {
    try {
      await fetcher(`/messages/SampleMessageGroup`, 'POST', sampleMessageGroup);
      await handleApiWithFlash(`/messages/SampleMessageGroup`, 'PUT', {
        successMessage: `Successfully created sample message group`,
        errorMessage: `Error creating sample message group}`,
        data: sampleMessageGroup,
      });
      await fetcher(`/closures/SampleClosureGroup`, 'POST', sampleClosureGroup);
      await handleApiWithFlash(`/closures/SampleClosureGroup`, 'PUT', {
        successMessage: `Successfully created sample closure group`,
        errorMessage: `Error creating sample closure group}`,
        data: sampleClosureGroup,
      });
      downloadSampleContactFlow('cxStudioSample', apiArn);
    } catch (error) {
      console.error('Error while handling API with flash:', error);
    }
  };

  const handleDeployDialPlan = async () => {
    try {
      await fetcher(`/dialPlans/SampleResourceGroup`, 'POST', sampleDialPlan);
      await handleApiWithFlash(`/dialPlans/SampleResourceGroup`, 'PUT', {
        successMessage: `Successfully created sample dial plan resource group`,
        errorMessage: `Error creating sample dial plan resource group`,
        data: sampleDialPlan,
      });
      downloadSampleContactFlow('cxStudioSampleOutbound', apiArn);
    } catch (error) {
      console.error('Error while handling API with flash:', error);
    }
  };

  return (
    <Container header={<Header headingTagOverride="h3">Simplify Quick Start</Header>}>
      <ColumnLayout columns={2} variant="text-grid">
        <SpaceBetween size="l">
          <div className="divider">Simplify Quick Start for messages and closures</div>
          <ValueWithLabel label="Step 1">
            <SpaceBetween size={'xs'}>
              <p>
                Press the button to create the 'SampleMessageGroup' and 'SampleClosureGroup' resources. Once completed
                the inbound contact flow will be downloaded to your machine
              </p>
              <Button onClick={handleDeploySampleGroup}>Create & Download</Button>
            </SpaceBetween>
          </ValueWithLabel>

          <ValueWithLabel label="Step 2">
            Import the downloaded JSON file into your instance, using <b>Contact flow</b> and publish
          </ValueWithLabel>
          <ValueWithLabel label="Step 3">
            Assign a phone number or use the Amazon Connect test chat widget
          </ValueWithLabel>
          <ValueWithLabel label="Step 4">
            Make changes to the SampleMessageGroup and SampleClosureGroup as needed. Try adding your e164 number as a
            BLOCKED or VIP item within <Link to={'/dial-plan'}>Dial Plan</Link>
          </ValueWithLabel>
        </SpaceBetween>
        <SpaceBetween size="l">
          <div className="divider">Simplify Quick Start for outbound dial plans</div>
          <ValueWithLabel label="Step 1">
            <SpaceBetween size={'xs'}>
              <p>
                Press the button to create the SampleResourceGroup for Dial Plan resources. Once completed the outbound
                contact flow will be downloaded to your machine
              </p>
              <Button onClick={handleDeployDialPlan}>Create & Download</Button>
            </SpaceBetween>
          </ValueWithLabel>
          <ValueWithLabel label="Step 2">
            Import the downloaded JSON file into your instance, using <b>Outbound whisper flow</b>
            <Alert statusIconAriaLabel="Info" header="Known issues/limitations">
              This contact flow requires a lambda to trigger StopContactCommand based. A cloudformation template
              repository will be available shorty to support simple deployments. You're free to copy the code here.
              (Ensure you provide appropriate permissions for the lambda.
              <Popover
                size="small"
                position="top"
                triggerType="custom"
                dismissButton={false}
                content={<StatusIndicator type="success">Code copied</StatusIndicator>}
              >
                <Button
                  iconName="copy"
                  onClick={() => {
                    navigator.clipboard.writeText(stopContactCode);
                  }}
                >
                  Copy function code
                </Button>
              </Popover>
            </Alert>
          </ValueWithLabel>
          <ValueWithLabel label="Step 3">
            Amend the default outbound queue, to use this new outbound contact flow.
          </ValueWithLabel>
          <ValueWithLabel label="Step 4">Dial a number within the list and not in the list</ValueWithLabel>
        </SpaceBetween>
      </ColumnLayout>
    </Container>
  );
}
