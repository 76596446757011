import React from 'react';
import GetAudioFromS3 from './getAudioFileFromS3';
import Waveform from './WaveForm';
import { Spinner } from '@cloudscape-design/components';
import Alert from '@cloudscape-design/components/alert';

// eslint-disable-next-line react/prop-types
export default function AudioPlaybackWidget({ contactObject, setAudioPosition, audioURL }) {
  return (
    <GetAudioFromS3 url={audioURL}>
      {({ blobURL, isLoading, error }) =>
        error ? (
          <Alert statusIconAriaLabel="Warning" type="warning">
            Unable to access the audio file
          </Alert>
        ) : isLoading ? (
          <Spinner />
        ) : blobURL ? (
          <main style={{ padding: '16px' }}>
            <Waveform audio={blobURL} contactObject={contactObject} setAudioPosition={setAudioPosition} />
          </main>
        ) : (
          <div>Waiting for audio...</div>
        )
      }
    </GetAudioFromS3>
  );
}
