import React, { useEffect, useState } from 'react';
import TopNavigation from '@cloudscape-design/components/top-navigation';
import { ButtonDropdownProps } from '@cloudscape-design/components/button-dropdown';
import { useNavigate } from 'react-router-dom';
import { UserProfile, fetchUserProfile } from '../../components/auth/userProfile';
import { useAppSession } from '../../utils/hooks/sessionContext';
import { signOut } from './signout';

export default function TopBarNavigation() {
  const navigate = useNavigate();
  const [profile, setProfile] = useState<UserProfile | null>(null);
  const appSession = useAppSession();

  useEffect(() => {
    async function getUserProfile() {
      const profileData = await fetchUserProfile();
      setProfile(profileData);
    }

    getUserProfile();
  }, []);

  const handleUtilityItemClick = (event: CustomEvent<ButtonDropdownProps.ItemClickDetails>) => {
    const details = event.detail;

    if (details.id === 'sign-out') {
      console.log('logout clicked');
      signOut();
    }
  };

  function handleIdentityClick(event: CustomEvent & { preventDefault: () => void }) {
    event.preventDefault();
    navigate('/');
  }

  return (
    <TopNavigation
      identity={{
        href: '/',
        title: 'Cato',
        onFollow: handleIdentityClick,
      }}
      i18nStrings={{
        overflowMenuTriggerText: 'More',
        overflowMenuTitleText: 'All',
      }}
      utilities={[
        {
          type: 'button',
          iconName: appSession?.fullScreen ? 'shrink' : 'expand',
          title: 'fullscreen',
          ariaLabel: 'fullscreen',
          disableUtilityCollapse: false,
          onClick: () => appSession?.setFullScreen(!appSession?.fullScreen),
        },
        {
          type: 'menu-dropdown',
          text: `${profile?.given_name} ${profile?.family_name}`,
          description: profile?.email,
          iconName: 'user-profile',
          items: [{ id: 'sign-out', text: 'Sign out' }],
          onItemClick: handleUtilityItemClick,
        },
      ]}
    />
  );
}
