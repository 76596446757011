/*
    This file sets the side menu navigation items. It pulls in the items from the navigationMapping.tx file.
    We then filter the menu options based on the tenant feature access. We always show Administration.
 */
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import SideNavigation from '@cloudscape-design/components/side-navigation';
import { items, NavigationItem } from './navigationMapping';
import { useAppSession } from '../../utils/hooks/sessionContext';

export default function Navigation() {
  // Hooks
  const navigate = useNavigate();
  const location = useLocation();
  const tenantData = useAppSession();

  // State
  const [activeHref, setActiveHref] = useState(location.pathname);
  const [filteredItems, setFilteredItems] = useState<NavigationItem[] | undefined>(undefined);
  const memberType = tenantData?.memberType;

  // Here we check the tenant feature access and only show the menu items that they have signed up for.
  useEffect(() => {
    if (memberType) {
      const matchedItems = items.filter(
        (item) =>
          item.type === 'section-group' &&
          (memberType.toLowerCase().includes(item.title.toLowerCase()) || item.title === 'Administration') // Always show the Administration menu
      );

      // Insert dividers between the filtered items
      const itemsWithDividers: NavigationItem[] = [];
      matchedItems.forEach((item, index) => {
        if (index > 0) {
          itemsWithDividers.push({ type: 'divider' });
        }
        itemsWithDividers.push(item);
      });

      setFilteredItems(itemsWithDividers);
    }
  }, [memberType]);

  const handleClick = (event: any) => {
    event.preventDefault();
    navigate(event.detail.href);
  };

  useEffect(() => {
    setActiveHref(location.pathname);
  }, [location.pathname]);

  return (
    <>
      <SideNavigation
        activeHref={activeHref}
        onFollow={(event) => handleClick(event)}
        header={{ href: '/', text: 'Home' }}
        items={filteredItems}
      />
    </>
  );
}
