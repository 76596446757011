import React from "react";
import { Link } from "react-router-dom";
import { CollectionPreferences } from "@cloudscape-design/components";

const rawColumns = [
  {
    id: "channel",
    header: "channel",
    cell: (item) => item.channel || "-",
    sortingField: "channel",
  },
  {
    id: "contactId",
    header: "Contact Id",
    cell: (item) =>
      (
        <Link
          target="_blank"
          rel="noopener noreferrer"
          to={`/contact-search/view/${item.contactId}`}
        >
          {item.contactId}
        </Link>
      ) || "-",
    sortingField: "contactId",
  },
  {
    id: "agentUsername",
    header: "Agent",
    cell: (item) => item.agentUsername || "-",
    sortingField: "agentUsername",
  },
  {
    id: "agentRoutingProfileName",
    header: "Routing Profile",
    cell: (item) => item.agentRoutingProfileName || "-",
    sortingField: "agentRoutingProfileName",
  },
  {
    id: "customerSentiment",
    header: "Customer Sentiment",
    cell: (item) =>
      item.conversationCharacteristics?.Sentiment?.OverallSentiment?.CUSTOMER ||
      "-",
  },
  {
    id: "agentSentiment",
    header: "Agent Sentiment",
    cell: (item) =>
      item.conversationCharacteristics?.Sentiment?.OverallSentiment?.AGENT ||
      "-",
  },
  {
    id: "queueName",
    header: "Queue",
    cell: (item) => item.queueName || "-",
    sortingField: "queueName",
  },
  {
    id: "disconnectReason",
    header: "Disconnect Reason",
    cell: (item) => item.disconnectReason || "-",
    sortingField: "disconnectReason",
  },
  {
    id: "queueDuration",
    header: "queueDuration",
    cell: (item) => item.queueDuration || "-",
    sortingField: "queueDuration",
  },
  {
    id: "connectedToSystemTimestamp",
    header: "connectedToSystemTimestamp",
    cell: (item) => item.connectedToSystemTimestamp || "-",
  },
  {
    id: "matchedCategories",
    header: "Matched Categories",
    cell: (item) => item.categories?.MatchedCategories || "-",
    sortingField: "categories.MatchedCategories",
  },
  {
    id: "matchedDetails",
    header: "Matched Details",
    cell: (item) => JSON.stringify(item.categories?.MatchedDetails) || "-",
    sortingField: "categories.MatchedDetails",
  },
];

export const COLUMN_DEFINITIONS = rawColumns.map((column) => ({ ...column }));

const editableColumns = {};

export const EDITABLE_COLUMN_DEFINITIONS = COLUMN_DEFINITIONS.map((column) => {
  if (editableColumns[column.id]) {
    return {
      ...column,
      minWidth: Math.max(column.minWidth || 0, 176),
      ...editableColumns[column.id],
    };
  }
  return column;
});

const CONTENT_DISPLAY_OPTIONS = [
  { id: "channel", label: "Channel", alwaysVisible: true },
  { id: "contactId", label: "Contact Id", alwaysVisible: true },
  { id: "agentUsername", label: "Agent Username", alwaysVisible: false },
  {
    id: "agentRoutingProfileName",
    label: "Routing Profile",
    alwaysVisible: false,
  },
  {
    id: "customerSentiment",
    label: "Customer Sentiment",
    alwaysVisible: false,
  },
  { id: "agentSentiment", label: "Agent Sentiment", alwaysVisible: false },
  { id: "queueName", label: "Queue Name", alwaysVisible: false },
  { id: "disconnectReason", label: "Disconnect Reason", alwaysVisible: false },
  { id: "queueDuration", label: "queueDuration", alwaysVisible: false },
  {
    id: "connectedToSystemTimestamp",
    label: "connectedToSystemTimestamp",
    alwaysVisible: false,
  },
  { id: "matchedCategories", label: "matchedCategories", alwaysVisible: false },
  { id: "matchedDetails", label: "matchedDetails", alwaysVisible: false },
];

export const PAGE_SIZE_OPTIONS = [
  { value: 10, label: "10 Contacts" },
  { value: 30, label: "30 Contacts" },
  { value: 50, label: "50 Contacts" },
  { value: 100, label: "100 Contacts" },
];

export const DEFAULT_PREFERENCES = {
  pageSize: 50,
  contentDisplay: [
    { id: "contactId", visible: true },
    { id: "channel", visible: true },
    { id: "connectedToSystemTimestamp", visible: true },
    { id: "agentUsername", visible: true },
    { id: "agentRoutingProfileName", visible: true },
    { id: "customerSentiment", visible: true },
    { id: "agentSentiment", visible: true },
    { id: "queueName", visible: true },
    { id: "disconnectReason", visible: true },
    { id: "queueDuration", visible: true },
    { id: "matchedCategories", visible: true },
    { id: "matchedDetails", visible: true },
  ],
  wrapLines: false,
  stripedRows: false,
  contentDensity: "comfortable",
  stickyColumns: { first: 1, last: 0 },
};

export const Preferences = ({
  preferences,
  setPreferences,
  disabled = false,
  pageSizeOptions = PAGE_SIZE_OPTIONS,
  contentDisplayOptions = CONTENT_DISPLAY_OPTIONS,
}) => (
  <CollectionPreferences
    title={"Preferences"}
    cancelLabel={"Cancel"}
    confirmLabel={"Confirm"}
    disabled={disabled}
    preferences={preferences}
    onConfirm={({ detail }) => setPreferences(detail)}
    pageSizePreference={{ title: "Page size", options: pageSizeOptions }}
    wrapLinesPreference={{
      label: "Wrap lines",
      description: "Select to see all the text and wrap the lines",
    }}
    stripedRowsPreference={{
      label: "Striped rows",
      description: "Select to add alternating shaded rows",
    }}
    contentDensityPreference={{
      label: "Compact mode",
      description: "Select to display content in a denser, more compact mode",
    }}
    contentDisplayPreference={{
      title: "Select visible content / order",
      options: contentDisplayOptions,
    }}
    stickyColumnsPreference={{
      firstColumns: {
        title: "Stick first column(s)",
        description:
          "Keep the first column(s) visible while horizontally scrolling the table content.",
        options: [
          { label: "None", value: 0 },
          { label: "First column", value: 1 },
          { label: "First two columns", value: 2 },
        ],
      },
      lastColumns: {
        title: "Stick last column",
        description:
          "Keep the last column visible while horizontally scrolling the table content.",
        options: [
          { label: "None", value: 0 },
          { label: "Last column", value: 1 },
        ],
      },
    }}
  />
);
