import React from "react";
import Header from "@cloudscape-design/components/header";
import {Pagination, Spinner, Table} from '@cloudscape-design/components';
import PropertyFilter from '@cloudscape-design/components/property-filter';
import Alert from "@cloudscape-design/components/alert";
import {getTextFilterCounterText} from "../../../../../../../utils/text-filter";
import {useCollection} from '@cloudscape-design/collection-hooks';
import {useLocalStorage} from "../../../../../../../components/use-local-storage";
import {useColumnWidths} from "../../../../../../../components/table/use-column-width";
import {FILTERING_PROPERTIES as filteringProperties} from "./filtering_properties"

import {
    DEFAULT_PREFERENCES,
    EDITABLE_COLUMN_DEFINITIONS,
    Preferences,
} from './table.config'


import {
    TableNoMatchState
} from "../../../../../../../components/table/commons";


export default function WidgetTable({isLoading, data = [], error}: any) {
    // State
    const [columnDefinitions, saveWidths] = useColumnWidths('Cato-getInstanceQueueMetrics-TableEditable-Widths', EDITABLE_COLUMN_DEFINITIONS)
    const [preferences, setPreferences] = useLocalStorage('Cato-getInstanceQueueMetrics-TableEditable-Preferences', DEFAULT_PREFERENCES);

    // Hooks
    const {items, actions, filteredItemsCount, collectionProps, propertyFilterProps, paginationProps} = useCollection(
        data,
        {
            propertyFiltering: {
                filteringProperties,
                noMatch: (
                    <TableNoMatchState
                        onClearFilter={() => {
                            actions.setPropertyFiltering({tokens: [], operation: 'and'});
                        }}
                    />
                ),
            },
            filtering: {
                noMatch: (
                    <TableNoMatchState
                        onClearFilter={() => {
                            actions.setPropertyFiltering({tokens: [], operation: 'and'});
                        }}
                    />
                ),
            },
            pagination: {pageSize: preferences.pageSize},
            sorting: {},
            selection: {},
        }
    );


    return (
        <>
            <Table
                {...collectionProps}
                stickyHeader={true}
                resizableColumns={true}
                onColumnWidthsChange={saveWidths}
                columnDefinitions={columnDefinitions}
                items={items}
                trackBy="feature"
                loadingText="Loading data"
                columnDisplay={preferences.contentDisplay}
                wrapLines={preferences.wrapLines}
                stripedRows={preferences.stripedRows}
                stickyColumns={preferences.stickyColumns}
                contentDensity={preferences.contentDensity as "comfortable" | "compact"}
                variant="embedded"
                loading={isLoading}
                header={
                    <Header
                        variant="awsui-h1-sticky"
                    >
                        Results {data && !isLoading ? `(${data.length})` : <Spinner/>}
                    </Header>
                }
                filter={
                    <PropertyFilter
                        {...propertyFilterProps}
                        countText={getTextFilterCounterText(filteredItemsCount)}
                        expandToViewport={true}
                        filteringAriaLabel={"Filter table"}
                        filteringPlaceholder={"Filter table"}
                    />
                }
                pagination={<Pagination {...paginationProps} />}
                preferences={<Preferences preferences={preferences} setPreferences={setPreferences}/>}
            />
            {error &&
                <Alert
                    statusIconAriaLabel="Error"
                    type="error"
                    header="Error getting data"
                >
                    Refresh the page to try again.
                </Alert>}
        </>
    );
}