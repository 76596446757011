import { useCallback } from 'react';
import updateMessageWithLang from '../lib/updateMessagesWithLang';

export const useInlineEdit = (setItem: Function) => {
  return {
    handleEdit: useCallback(
      (field: string, targetItem: any, column: any, newValue: any) => {
        if (field === 'active') {
          // Toggle the active status
          setItem((prevState: { data: { messages: any[] } }) => {
            const updatedMessages = prevState.data.messages.map((message) =>
              message.key === targetItem.key ? { ...message, active: newValue } : message
            );

            return {
              ...prevState,
              data: {
                ...prevState.data,
                messages: updatedMessages,
              },
            };
          });
        } else if (field === 'key') {
          // Handle key edit
          setItem((prevState: { data: { messages: any[] } }) => {
            const updatedMessages = prevState.data.messages.map((message) =>
              message.key === targetItem.key ? { ...message, key: newValue } : message
            );
            return {
              ...prevState,
              data: {
                ...prevState.data,
                messages: updatedMessages,
              },
            };
          });
        } else {
          // Handle language inline edit
          setItem((prevState: { data: { messages: any } }) => {
            const updatedMessages = updateMessageWithLang(prevState.data.messages, targetItem.key, newValue, field);

            return {
              ...prevState,
              data: {
                ...prevState.data,
                messages: updatedMessages,
              },
            };
          });
        }
      },
      [setItem]
    ),

    handleAttributeEdit: useCallback(
      (field: string, targetItem: any, column: any, newValue: any) => {
        if (column?.id === 'key' || column?.id === 'value') {
          console.log('field', field);
          console.log('column', column);
          setItem((prevState: { data: { attributes: any[] } }) => {
            const updatedAttributes = prevState.data.attributes.map((attribute) =>
              attribute.key === targetItem.key ? { ...attribute, [column.id]: newValue } : attribute
            );

            return {
              ...prevState,
              data: {
                ...prevState.data,
                attributes: updatedAttributes,
              },
            };
          });
        }
      },
      [setItem]
    ),

    removeRow: useCallback(
      (event: React.MouseEvent<SVGSVGElement, MouseEvent>, keyToRemove: any, type: string = 'message') => {
        event.stopPropagation(); // To prevent any other unintended event triggers

        setItem((prevState: { data: { messages: any[]; attributes: any[] } }) => {
          let updatedData;

          if (type === 'attribute') {
            updatedData = prevState.data.attributes.filter((attribute) => attribute.key !== keyToRemove);
            return {
              ...prevState,
              data: {
                ...prevState.data,
                attributes: updatedData,
              },
            };
          } else {
            updatedData = prevState.data.messages.filter((message) => message.key !== keyToRemove);
            return {
              ...prevState,
              data: {
                ...prevState.data,
                messages: updatedData,
              },
            };
          }
        });
      },
      [setItem]
    ),

    addRow: useCallback(
      (type = 'message') => {
        setItem((prevState: { data: { messages: any[]; attributes: any[] } }) => {
          if (type === 'attribute') {
            let defaultKey = 'newKey';
            let counter = 1;
            while (
              prevState.data.attributes.some(
                // eslint-disable-next-line no-loop-func
                (attribute) => attribute.key === defaultKey
              )
            ) {
              defaultKey = 'newKey' + counter;
              counter++;
            }

            const defaultAttribute = { key: defaultKey, value: 'newValue' }; // Adjust value if needed

            const updatedAttributes = [...prevState.data.attributes, defaultAttribute];
            return {
              ...prevState,
              data: {
                ...prevState.data,
                attributes: updatedAttributes,
              },
            };
          } else {
            let defaultKey = 'newKey';
            let counter = 1;
            while (
              prevState.data.messages.some(
                // eslint-disable-next-line no-loop-func
                (message) => message.key === defaultKey
              )
            ) {
              defaultKey = 'newKey' + counter;
              counter++;
            }

            const defaultRow = {
              message: [
                {
                  lang: 'default',
                  message: [
                    {
                      type: 'paragraph',
                      children: [
                        {
                          text: '',
                        },
                      ],
                    },
                  ],
                },
              ],
              active: type === 'normal' ? null : true,
              key: defaultKey,
            };

            const updatedMessages = [...prevState.data.messages, defaultRow];
            return {
              ...prevState,
              data: {
                ...prevState.data,
                messages: updatedMessages,
              },
            };
          }
        });
      },
      [setItem]
    ),
  };
};
