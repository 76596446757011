import React, { useState, useEffect } from "react";
import useSWR from "swr";
import fetcher from "../../utils/fetcher";
import Header from "@cloudscape-design/components/header";
import SpaceBetween from "@cloudscape-design/components/space-between";
import { ContentLayout } from "@cloudscape-design/components";
import ContactSearchTable from "./components/table";

export default function ContactSearch() {
  const [submitSearch, setSubmitSearch] = useState<boolean>(false);
  const [changesMade, setChangesMade] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState({
    startDate: "6hour",
    endDate: "now",
  });
  const [searchFilters, setSearchFilters] = useState({});
  const [currentFetchUrl, setCurrentFetchUrl] = useState<string | null>(null);

  useEffect(() => {
    const constructFetchUrl = () => {
      const newQueryParams = {
        start: dateRange.startDate,
        end: dateRange.endDate,
        templateType: "getContactSearch",
        searchFilters: JSON.stringify(searchFilters),
      };

      const queryString = Object.entries(newQueryParams)
        .map(
          ([key, value]) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
        )
        .join("&");

      let url = `/queries?${queryString}`;
      console.log("url", url);
      return url;
    };

    if (!currentFetchUrl || submitSearch) {
      setSubmitSearch(false);
      setCurrentFetchUrl(constructFetchUrl());
    }
  }, [
    submitSearch,
    currentFetchUrl,
    dateRange.startDate,
    dateRange.endDate,
    searchFilters,
  ]);

  useEffect(() => {
    setChangesMade(true);
  }, [dateRange, searchFilters]);

  const { data, isLoading, error } = useSWR(currentFetchUrl, fetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshInterval: 0,
    shouldRetryOnError: false,
  });

  useEffect(() => {
    setChangesMade(false);
  }, [data]);

  return (
    <ContentLayout
      header={
        <Header
          variant="h1"
          description="Search for all contacts within the system"
        >
          Contact Search
        </Header>
      }
    >
      <SpaceBetween size="m">
        <ContactSearchTable
          isLoading={isLoading}
          error={error}
          contactSearchResults={data?.data}
          setDateRange={setDateRange}
          setSearchFilters={setSearchFilters}
          setSubmitSearch={setSubmitSearch}
          setChangesMade={setChangesMade}
          changesMade={changesMade}
        />
      </SpaceBetween>
    </ContentLayout>
  );
}
