import * as React from "react";
import BarChart from "@cloudscape-design/components/bar-chart";
import Button from "@cloudscape-design/components/button";

export default function QaBarchart() {
  return (
    <BarChart
      series={[
        {
          title: "Contact",
          type: "bar",
          valueFormatter: (e) => `${(100 * e).toFixed(0)}%`,
          data: [
            { x: "Greeting", y: 0.8 },
            { x: "Security", y: 1 },
            { x: "Empathy", y: 0.9 },
          ],
        },
        {
          title: "Company Average",
          type: "bar",
          valueFormatter: (e) => `${(100 * e).toFixed(0)}%`,
          data: [
            { x: "Greeting", y: 0.85 },
            { x: "Security", y: 0.95 },
            { x: "Empathy", y: 0.9 },
          ],
        },
      ]}
      xDomain={["Greeting", "Security", "Empathy"]}
      yDomain={[0, 1.01]}
      i18nStrings={{
        yTickFormatter: (e) => `${(100 * e).toFixed(0)}%`,
      }}
      ariaLabel="Stacked, horizontal bar chart"
      detailPopoverFooter={(xValue) => (
        <Button ariaLabel={`View details for ${xValue}`}>View details</Button>
      )}
      emphasizeBaselineAxis={false}
      height={200}
      hideFilter
      horizontalBars
    />
  );
}
