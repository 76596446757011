import React, { useEffect, useState } from 'react';
import Board, { BoardProps } from '@cloudscape-design/board-components/board';
import Button from '@cloudscape-design/components/button';
import ContentLayout from '@cloudscape-design/components/content-layout';
import SplitPanel from '@cloudscape-design/components/split-panel';
import SpaceBetween from '@cloudscape-design/components/space-between';
import { EmptyState } from './components/empty-state';
import { getBoardWidgets, getPaletteWidgets, exportLayout } from './widgets';
import Palette from './components/palette';
import { ConfigurableWidget } from './components/configurable-widget';
import { boardI18nStrings } from './i18n-strings';
import { useItemsLayout } from './use-items-layout';
import { useSplitPanelControl } from '../../../../utils/hooks/splitPanelContext';
import { useItemContext } from '../dashboardContext';
import { WidgetDataType } from './components/widgets/interfaces';
import Header from '@cloudscape-design/components/header';
import CxDateRangePicker from '../../../../components/dataRangePicker';
import { Box, Popover } from '@cloudscape-design/components';

export function App() {
  const [ref, layout, handleLayoutChange, resetLayout, saveLayout] = useItemsLayout();
  const { changesDetected, item, setItem } = useItemContext();
  const [isSaving, setIsSaving] = useState(false);
  const { setSplitPanelOpen, setSplitPanelContent, setSplitPanelPreferences } = useSplitPanelControl();

  async function handleSaveLayout() {
    setIsSaving(true);
    await saveLayout();
    setIsSaving(false);
  }

  function handleDateChange(value: any) {
    setItem((prevItem: { data: any }) => ({
      ...prevItem,
      data: {
        ...prevItem.data,
        period: value,
      },
    }));
  }

  function handleAddNewItem() {
    setSplitPanelContent(
      <SplitPanel header="Add widgets" closeBehavior="hide" hidePreferencesButton={true}>
        <Palette items={getPaletteWidgets(layout)} />
      </SplitPanel>
    );
    setSplitPanelOpen(true);
    setSplitPanelPreferences({ position: 'side' });
  }

  function onItemsChange(items: readonly BoardProps.Item<WidgetDataType>[]) {
    handleLayoutChange(exportLayout(items));
  }

  useEffect(() => {
    setSplitPanelContent(
      <SplitPanel header="Add widgets" closeBehavior="hide" hidePreferencesButton={true}>
        <Palette items={getPaletteWidgets(layout)} />
      </SplitPanel>
    );
  }, [layout, setSplitPanelContent]);

  return (
    <ContentLayout
      header={
        <>
          <Header
            actions={
              <SpaceBetween size="xs" direction="horizontal">
                {item?.data?.period?.type === 'relative' && (
                  <Box color="text-status-info" display="inline">
                    <Popover
                      header="Live data"
                      size="medium"
                      triggerType="text"
                      content={<>When using relative time ranges, data is automatically updated every 30 seconds.</>}
                      renderWithPortal={true}
                    >
                      <Box color="text-status-info" fontSize="body-s" fontWeight="bold">
                        Live data
                      </Box>
                    </Popover>
                  </Box>
                )}
                {changesDetected && <Button onClick={() => resetLayout()}>Reset</Button>}
                {changesDetected && (
                  <Button variant={'primary'} onClick={handleSaveLayout} loading={isSaving}>
                    Save Changes
                  </Button>
                )}
                <CxDateRangePicker
                  setDateRangeValue={handleDateChange}
                  defaults={item?.data?.period}
                  limitDuration={true}
                  rangeSelectorMode={'default'}
                />
                <Button iconName="add-plus" onClick={() => handleAddNewItem()}>
                  Add widget
                </Button>
              </SpaceBetween>
            }
          >
            {item?.feature}
          </Header>
        </>
      }
    >
      <div ref={ref}>
        <Board
          empty={
            <EmptyState
              title="No widgets"
              description="There are no widgets on the dashboard."
              verticalCenter={true}
              action={
                <SpaceBetween direction="horizontal" size="xs">
                  {changesDetected && <Button onClick={() => resetLayout()}>Reset to last saved</Button>}
                  <Button iconName="add-plus" onClick={() => handleAddNewItem()}>
                    Add widget
                  </Button>
                </SpaceBetween>
              }
            />
          }
          i18nStrings={boardI18nStrings}
          items={getBoardWidgets(layout, item)}
          onItemsChange={({ detail: { items } }) => {
            onItemsChange(items);
          }}
          renderItem={(item, actions) => {
            const Wrapper = item.data?.provider ?? React.Fragment;
            return (
              <Wrapper widgetId={item.id}>
                <ConfigurableWidget config={item?.data} id={item.id} onRemove={actions?.removeItem} />
              </Wrapper>
            );
          }}
        />
      </div>
    </ContentLayout>
  );
}
