/* eslint-disable react/prop-types */
import React from 'react';
import dayjs from 'dayjs';
import {Box, ColumnLayout, Container, Header, SpaceBetween} from '@cloudscape-design/components';
import SentimentLineChart from "./sentimentLineChart";
import {Link} from "react-router-dom";

function ValueWithLabel({label, children}) {
    return (
        <div>
            <Box variant="awsui-key-label">{label}</Box>
            <div>{children}</div>
        </div>
    );
}

export default function ContactSummary({contactObject}) {
    return (
        <Container header={<Header headingTagOverride="h3">Contact Summary</Header>}>
            <ColumnLayout columns={3} variant="text-grid">
                <SpaceBetween size="xs">
                    <ValueWithLabel label="Contact id">{contactObject.contactId}</ValueWithLabel>
                    <ValueWithLabel label="Channel">{contactObject.channel}</ValueWithLabel>
                    <ValueWithLabel label="Initiation method">
                        {contactObject.initiationMethod}
                    </ValueWithLabel>
                    <ValueWithLabel label="Disonnected Reason">
                        {contactObject.disconnectReason}
                    </ValueWithLabel>
                    {contactObject.previousContactId && (
                        <ValueWithLabel label="Previous contact id">
              <span className="text-info underline">
                <Link href={`/contactSearch/${contactObject.previousContactId}`} target="_blank">
                  {contactObject.previousContactId}
                </Link>
              </span>
                        </ValueWithLabel>
                    )}
                    {contactObject.nextContactId && (
                        <ValueWithLabel label="Previous contact id">
                    <span className="text-info underline">
                                <Link target="_blank" rel="noopener noreferrer"
                                      to={`/contact-search/view/${contactObject.nextContactId}`}>{contactObject.nextContactId}</Link>

                    </span>
                        </ValueWithLabel>
                    )}
                    {contactObject.relatedContactId && (
                        <ValueWithLabel label="Previous contact id">
              <span className="text-info underline">
                <Link href={`/contactSearch/${contactObject.relatedContactId}`} target="_blank">
                  {contactObject.relatedContactId}
                </Link>
              </span>
                        </ValueWithLabel>
                    )}
                </SpaceBetween>
                <SpaceBetween size="xs">
                    <ValueWithLabel label="Connected to syetem">
                        {dayjs(contactObject.connectedToSystemTimestamp).format('YYYY-MM-DD HH:mm')}
                    </ValueWithLabel>
                    <ValueWithLabel label="Disonnected">
                        {dayjs(contactObject.disconnectTimestamp).format('YYYY-MM-DD HH:mm')}
                    </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size="xs">
                    {contactObject?.conversationCharacteristics?.Sentiment?.SentimentByPeriod && (
                        <SentimentLineChart
                            SentimentByPeriod={
                                contactObject.conversationCharacteristics.Sentiment.SentimentByPeriod
                            }
                        />
                    )}
                </SpaceBetween>
            </ColumnLayout>
        </Container>
    );
}
