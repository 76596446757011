import React, { useEffect } from "react";
import { App } from "./configurable-dashboard/app";
import { AppLoadingError } from "./configurable-dashboard/appLoadingError";
import { DashboardProvider, useItemContext } from "./dashboardContext";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import { SWRResponse } from "../../messages/types";
import fetcher from "../../../utils/fetcher";
import { AppLoading } from "./configurable-dashboard/appLoading";

export default function VisualizationsView() {
  return (
    <DashboardProvider>
      <VisualizationContent />
    </DashboardProvider>
  );
}

function VisualizationContent() {
  const { setItem, setItemShadow } = useItemContext();
  const { featureId } = useParams();

  const { data, isLoading, error } = useSWR<SWRResponse>(
    `/dashboards/${featureId}`,
    fetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshInterval: 0,
    },
  );

  useEffect(() => {
    if (data?.data) {
      setItem(data.data);
      setItemShadow(data.data);
    }
  }, [data, setItem, setItemShadow]);

  if (isLoading) {
    return <AppLoading />;
  } else if (error) {
    return <AppLoadingError />;
  } else {
    return <App />;
  }
}
