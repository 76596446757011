import React from 'react';
import useSWR from 'swr';
import fetcher from '../../utils/fetcher';
import Header from '@cloudscape-design/components/header';
import SpaceBetween from '@cloudscape-design/components/space-between';
import { Box, Button, ContentLayout, Popover, Spinner, StatusIndicator } from '@cloudscape-design/components';
import Alert from '@cloudscape-design/components/alert';
import TenantData from './components/tenantData';
import SimplifySteps from './components/simplifySteps';
import IntuitionSteps from './components/intuitionSteps';

export default function Settings() {
  const { data, isLoading, error } = useSWR('/tenantProfile', fetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshInterval: 0,
  });

  console.log(data);

  const { lambdaArn, s3AccessPointAlias, memberType } = data?.data || {};

  return (
    <ContentLayout
      header={
        <Header variant="h1" description="Your tenant settings and quick start guides">
          Settings
        </Header>
      }
    >
      <SpaceBetween size="m">
        <TenantData data={data?.data} isLoading={isLoading} error={error} />
        {data?.externalId && (
          <Alert statusIconAriaLabel="Info" header="Cross Account Role ExternalId">
            <div className="custom-wrapping">
              <Box margin={{ right: 'xxs' }} display="inline-block">
                <Popover
                  size="small"
                  position="top"
                  dismissButton={false}
                  triggerType="custom"
                  content={<StatusIndicator type="success">ARN ExternalId</StatusIndicator>}
                >
                  <Button
                    variant="inline-icon"
                    iconName="copy"
                    ariaLabel="Copy ExternalId"
                    onClick={() => {
                      navigator.clipboard.writeText(data?.ExternalId);
                    }}
                  />
                </Popover>
              </Box>
            </div>
            Keep this secure it adds an extra layer of protection for AWS Cross Account roles.
          </Alert>
        )}
        {memberType?.includes('simplify') ? (
          lambdaArn?.includes('arn:aws:lambda') ? (
            <SimplifySteps apiArn={lambdaArn} />
          ) : (
            <Alert statusIconAriaLabel="Info" header={`Simplify access`}>
              <Spinner />
              Your tenant services are being provisioned, please check back in a few minutes.
            </Alert>
          )
        ) : null}
        {memberType?.includes('intuition') ? (
          s3AccessPointAlias?.includes('tenant') ? (
            <IntuitionSteps />
          ) : (
            <Alert statusIconAriaLabel="Info" header={`Intuition access`}>
              <Spinner />
              Your tenant services are being provisioned, please check back in a few minutes.
            </Alert>
          )
        ) : null}
      </SpaceBetween>
    </ContentLayout>
  );
}
