import { useEffect, useRef, useState } from "react";
import { useContainerQuery } from "@cloudscape-design/component-toolkit";
import { StoredWidgetPlacement } from "./interfaces";
import { useItemContext } from "../dashboardContext";
import { v4 as uuidv4 } from "uuid";
import { allWidgets } from "./widgets";
import { useApiWithFlash } from "../../../../utils/hooks/useApiWithFlash";

export function useItemsLayout() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [width, ref] = useContainerQuery((entry) => entry.contentBoxWidth);
  const { setItem, itemShadow, item } = useItemContext();
  const [layout, setLayout] =
    useState<ReadonlyArray<StoredWidgetPlacement> | null>(null);
  const itemsChanged = useRef(layout !== null);

  // Hooks
  const { handleApiWithFlash } = useApiWithFlash();

  useEffect(() => {
    if (item && item.data && item.data.widgets) {
      setLayout(
        item.data.widgets.map((widget: any) => ({
          id: widget.id,
          columnSpan: widget.columnSpan,
          columnOffset: widget.columnOffset,
          rowSpan: widget.rowSpan,
        })),
      );
    }
  }, [item]);

  function handleLayoutChange(layout: ReadonlyArray<StoredWidgetPlacement>) {
    itemsChanged.current = true;

    let updatedLayout = [...layout]; // Clone the layout for modification

    const updatedWidgets = updatedLayout.map((l, index) => {
      const originalWidget = item.data.widgets.find(
        (w: { id: string }) => w.id === l.id,
      );

      if (originalWidget) {
        return {
          ...originalWidget,
          columnSpan: l.columnSpan,
          columnOffset: l.columnOffset,
          rowSpan: l.rowSpan,
        };
      } else {
        // Create the widget from the template
        const newWidget = createWidgetFromTemplate(l);

        // If dynamicId is set to true and the newWidget's id is different from the layout's, update both
        if (newWidget.id !== l.id) {
          updatedLayout[index] = {
            ...l,
            id: newWidget.id, // Update the layout ID to match the widget's
          };
        }

        return newWidget;
      }
    });

    setLayout(updatedLayout);
    setItem((prevItem: { data: any }) => ({
      ...prevItem,
      data: {
        ...prevItem.data,
        widgets: updatedWidgets,
      },
    }));
  }

  function resetLayout() {
    setItem(itemShadow);
  }

  async function saveLayout() {
    await handleApiWithFlash(`/dashboards/${item.feature}`, "PUT", {
      successMessage: `Successfully updated dashboard  ${item.feature}`,
      errorMessage: `Error updating dashboard ${item.feature}`,
      mutateKey: `/dashboards/${item.feature}`,
      data: item,
    });
  }

  return [
    ref,
    layout ?? [],
    handleLayoutChange,
    resetLayout,
    saveLayout,
  ] as const;
}

function createWidgetFromTemplate(layoutData: StoredWidgetPlacement): any {
  const widgetConfig = allWidgets[layoutData.id];

  if (!widgetConfig) {
    throw new Error(
      `Template type ${layoutData.id} is not defined in the allWidgets`,
    );
  }

  return {
    columnSpan: layoutData.columnSpan,
    rowSpan: layoutData.rowSpan,
    templateType: layoutData.id,
    columnOffset: layoutData.columnOffset || { "1": 0 },
    data: widgetConfig.data,
    definition: widgetConfig.definition,
    preferences: widgetConfig.preferences || [],
    filters: {
      channels: ["*"],
      queues: ["*"],
    },
    id: widgetConfig.data.multipleAllowed ? uuidv4() : layoutData.id,
  };
}
