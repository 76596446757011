import React, { createContext, useState } from "react";
import { Header, Spinner, Toggle } from "@cloudscape-design/components";
import { WidgetConfig } from "../interfaces";

import GetWidgetData from "../../../../../../../utils/getWidgetData";
import { useItemContext } from "../../../../dashboardContext";
import { allContent, Content, WidgetPreferences } from "../preferences";
import Heatmap from "./heatmap";
import SentimentLineChart from "./lineChart";
import Alert from "@cloudscape-design/components/alert";

export const getSentimentByQueue: WidgetConfig = {
  definition: { defaultRowSpan: 1, defaultColumnSpan: 1, minRowSpan: 1 },
  data: {
    icon: "mixedContent",
    title: "Sentiment by Queue",
    description: "Displays the queue sentiment, for each queue over time",
    header: ChartHeader,
    content: SentimentByQueueContent,
    provider: SentimentByQueueProvider,
    staticMinHeight: 560,
    multipleAllowed: true,
  },
};

interface SentimentByQueueWidgetContextType {
  visibleContent: ReadonlyArray<Content>;
  openPreferences: () => void;
}

const SentimentByQueueWidgetContext =
  createContext<SentimentByQueueWidgetContextType>({
    visibleContent: [],
    openPreferences: () => {},
  });

function SentimentByQueueProvider({
  children,
  widgetId,
}: {
  children: React.ReactElement;
  widgetId: string;
}) {
  const [preferencesVisible, setPreferencesVisible] = useState(false);
  const [visibleContent, setVisibleContent] =
    useState<ReadonlyArray<Content>>(allContent);
  return (
    <SentimentByQueueWidgetContext.Provider
      value={{
        visibleContent,
        openPreferences: () => setPreferencesVisible(true),
      }}
    >
      {React.cloneElement(React.Children.only(children), {
        removeConfirmationText: "Operational metrics",
        actions: [
          { text: "Preferences", onClick: () => setPreferencesVisible(true) },
        ],
      })}
      {preferencesVisible && (
        <WidgetPreferences
          widgetId={widgetId}
          preferences={visibleContent}
          onConfirm={(visibleContent) => {
            setVisibleContent(visibleContent);
            setPreferencesVisible(false);
          }}
          onDismiss={() => setPreferencesVisible(false)}
        />
      )}
    </SentimentByQueueWidgetContext.Provider>
  );
}

function ChartHeader() {
  return (
    <Header variant="h2" description="">
      Sentiment by queue
    </Header>
  );
}

export default function SentimentByQueueContent({
  widgetId,
}: {
  widgetId: string;
}) {
  const [checked, setChecked] = React.useState(false);
  const { item } = useItemContext();
  const widget = item.data.widgets.find(
    (widget: { id: string }) => widget.id === widgetId,
  );
  const period = item.data.period;
  const { data, isLoading, error } = GetWidgetData({ widget, period });

  if (isLoading) {
    return <Spinner />;
  }

  if (error) {
    return (
      <Alert
        statusIconAriaLabel="Error"
        type="error"
        header="Unable to load widget"
      >
        {error.status}
      </Alert>
    );
  }

  return (
    <>
      <Toggle
        onChange={({ detail }) => setChecked(detail.checked)}
        checked={checked}
      >
        Toggle {checked ? "Line Chart" : "Bubble chart"}
      </Toggle>
      {checked ? (
        <Heatmap data={data?.data} />
      ) : (
        <SentimentLineChart data={data?.data} />
      )}
    </>
  );
}
