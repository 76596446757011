import React from 'react';
import { Header } from '@cloudscape-design/components';
import { WidgetConfig } from '../interfaces';
import GetWidgetData from '../../../../../../../utils/getWidgetData';
import { useItemContext } from '../../../../dashboardContext';
import UserCommentsTable from './table';

export const getUserFeedbackComments: WidgetConfig = {
  definition: { defaultRowSpan: 4, defaultColumnSpan: 2, minRowSpan: 3 },
  data: {
    icon: 'table',
    title: 'Chatbot Feedback Comments',
    description: 'Verbatim feedback and Thumbs up/down from users of the chatbot (xQ)',
    header: GetHeader,
    content: GetContents,
    staticMinHeight: 200,
    multipleAllowed: false,
  },
};

function GetHeader() {
  return (
    <Header
      variant="h2"
      description="Vebatim feedback provided by the user. Users can provide feedback per message. Score is mandatory, comments are optional"
    >
      User Feedback
    </Header>
  );
}

export default function GetContents({ widgetId }: { widgetId: string }) {
  const { item } = useItemContext();
  const widget = item.data.widgets.find((widget: { id: string }) => widget.id === widgetId);

  const period = item.data.period;

  const { data, isLoading, error } = GetWidgetData({ widget, period });

  return <UserCommentsTable userFeedback={data?.data} error={error} isLoading={isLoading} />;
}
