import React from 'react';
import { Header, SpaceBetween, Box, Container, ColumnLayout } from '@cloudscape-design/components';

// @ts-ignore
const ValueWithLabel = ({ label, children }) => (
  <div>
    <Box variant="awsui-key-label">{label}</Box>
    <div>{children}</div>
  </div>
);

export default function IntuitionSteps() {
  return (
    <Container header={<Header headingTagOverride="h3">Intuition Quick Start</Header>}>
      <ColumnLayout columns={1} variant="text-grid">
        <div className="divider">Intuition Quick Start</div>
        <SpaceBetween size="l">
          <ValueWithLabel label="Step 1">Perform steps in Essentials </ValueWithLabel>
          <ValueWithLabel label="Step 2">Install the Cato tenant integration</ValueWithLabel>
          <ValueWithLabel label="Step 3">Make test contacts and verify integrations</ValueWithLabel>
          <ValueWithLabel label="Step 4">See the help pages within 'Insights' for more information</ValueWithLabel>
        </SpaceBetween>
      </ColumnLayout>
    </Container>
  );
}
