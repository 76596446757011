import React, { useState, useEffect } from "react";
import fetcher from "../../../utils/fetcher";
import {
  ColumnLayout,
  Container,
  Header,
  ContentLayout,
  SpaceBetween,
  Textarea,
  Form,
  Input,
  Toggle,
  Button,
  FormField,
  Table,
  Spinner,
} from "@cloudscape-design/components";
import { useNavigate, useParams } from "react-router-dom";
import useChangeDetector from "../../../utils/useChangeDetector";
import { Message, Attribute, SWRResponse } from "../types";
import useSWR from "swr";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLanguage, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useInlineEdit } from "../lib/useInlineEdit";
import { handleFieldChange } from "../lib/fieldChangeUtils";
import TranslationModal from "./components/translationModal";
import { useApiWithFlash } from "../../../utils/hooks/useApiWithFlash";
import Alert from "@cloudscape-design/components/alert";

export default function MessagesEdit() {
  // state to control changes made
  const { item, setItem, setItemShadow, changesDetected } =
    useChangeDetector<any>({});

  // state
  const [formSubmited, setFormSubmited] = useState<boolean>(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedMessageKey, setSelectedMessageKey] = useState<string | null>(
    null,
  );

  // Hooks
  const navigate = useNavigate();
  const { featureId } = useParams();
  const { handleApiWithFlash } = useApiWithFlash();
  const { handleEdit, handleAttributeEdit, addRow, removeRow } =
    useInlineEdit(setItem);

  const openModalWithMessageKey = (key: string) => {
    setSelectedMessageKey(key);
    setModalOpen(true);
  };

  const { data, isLoading, error } = useSWR<SWRResponse>(
    `/messages/${featureId}`,
    fetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshInterval: 0,
    },
  );

  // Saves the results form the API into local state
  useEffect(() => {
    if (data?.data) {
      setItem(data.data);
      setItemShadow(data.data);
    }
  }, [data, setItem, setItemShadow]);

  if (error) {
    return (
      <Alert
        statusIconAriaLabel="Error"
        type="error"
        header="Unable to load component"
      >
        {error.status}
      </Alert>
    );
  }

  // This is used by the other fields in the form to update local state
  const setItemFieldChange = handleFieldChange(setItem, item);

  // Handle form submission
  async function handleFormSubmit() {
    setFormSubmited(true);
    await handleApiWithFlash(`/messages/${item.feature}`, "PUT", {
      successMessage: `Successfully updated message group ${item.feature}`,
      errorMessage: `Error updating message group ${item.feature}`,
      mutateKey: `/messages/${item.feature}`,
      data: item,
    });
    setFormSubmited(false);
  }

  const messages: Message[] = item?.data?.messages || [];
  const attributes: Attribute[] = item?.data?.attributes || [];

  return (
    <ContentLayout header={<Header variant="h1"></Header>}>
      <Form
        variant="embedded"
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              formAction="none"
              variant="link"
              onClick={() => navigate("/messages")}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              loading={formSubmited}
              onClick={handleFormSubmit}
              disabled={!changesDetected}
            >
              Submit
            </Button>
          </SpaceBetween>
        }
      >
        <SpaceBetween direction="vertical" size="xl">
          <Container
            header={
              <Header variant="h2">
                {isLoading ? <Spinner /> : item?.feature}
              </Header>
            }
          >
            <SpaceBetween direction="vertical" size="xl">
              <ColumnLayout columns={1} variant="text-grid">
                <FormField label="Description">
                  <Textarea
                    onChange={({ detail }) =>
                      setItemFieldChange("description", detail.value)
                    }
                    value={item?.description}
                  />
                </FormField>
              </ColumnLayout>
            </SpaceBetween>
          </Container>

          <Table
            wrapLines
            header={
              <Header
                variant="h2"
                actions={
                  <SpaceBetween direction="horizontal" size="xs">
                    <Button
                      variant="normal"
                      onClick={(e) => {
                        addRow("situational");
                      }}
                    >
                      Add situational message
                    </Button>
                    <Button
                      variant="primary"
                      onClick={(e) => {
                        addRow("normal");
                      }}
                    >
                      Add message
                    </Button>
                  </SpaceBetween>
                }
              >
                Messages
              </Header>
            }
            columnDefinitions={[
              {
                id: "key",
                header: "Message Key",
                cell: (message) => message.key || "-",
                width: 150,
                minWidth: 150,
                editConfig: {
                  ariaLabel: "Message Key",
                  editIconAriaLabel: "editable",
                  errorIconAriaLabel: "Key Error",
                  editingCell: (message, cellContext) => {
                    const defaultMessagePath = message.key;
                    return (
                      <Input
                        autoFocus={true}
                        value={cellContext.currentValue ?? defaultMessagePath}
                        onChange={(event) => {
                          cellContext.setValue(event.detail.value);
                        }}
                      />
                    );
                  },
                },
              },
              {
                id: "default",
                header: "Default Message",
                cell: (message) =>
                  message.message.find((m) => m.lang === "default")?.message[0]
                    ?.children[0]?.text || "-",
                minWidth: 300,
                editConfig: {
                  ariaLabel: "Name",
                  editIconAriaLabel: "editable",
                  errorIconAriaLabel: "Name Error",
                  editingCell: (message, cellContext) => {
                    const defaultMessagePath = message.message.find(
                      (m) => m.lang === "default",
                    )?.message[0]?.children[0]?.text;
                    return (
                      <Input
                        autoFocus={true}
                        value={cellContext.currentValue ?? defaultMessagePath}
                        onChange={(event) => {
                          cellContext.setValue(event.detail.value);
                        }}
                      />
                    );
                  },
                },
              },
              {
                id: "status",
                header: "Active Status",
                cell: (message) => {
                  if (message.active === null) {
                    return (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                        }}
                      >
                        <Toggle checked disabled></Toggle>
                      </div>
                    );
                  } else if (
                    message.active === false ||
                    message.active === true
                  ) {
                    return (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                        }}
                      >
                        <Toggle
                          checked={message.active}
                          onChange={() =>
                            handleEdit("active", message, null, !message.active)
                          }
                        ></Toggle>
                      </div>
                    );
                  } else {
                    return "-";
                  }
                },
                width: 176,
                minWidth: 176,
              },
              {
                id: "translations",
                header: "Translations",
                cell: (message) => (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faLanguage}
                      style={{ cursor: "pointer" }}
                      onClick={() => openModalWithMessageKey(message.key)}
                      title="View Translations"
                    />
                  </div>
                ),
                width: 30,
                minWidth: 30,
              },
              {
                id: "remove",
                header: "Remove",
                cell: (item) => (
                  <FontAwesomeIcon
                    icon={faTrash}
                    style={{ color: "#ff0000", cursor: "pointer" }}
                    onClick={(event) => removeRow(event, item.key)}
                  />
                ),
                width: 30,
                minWidth: 30,
              },
            ]}
            submitEdit={(item, column, newValue) => {
              handleEdit(column.id as string, item, column, newValue);
            }}
            items={messages}
            loadingText="Loading messages..."
          />

          <Table
            header={
              <Header
                variant="h2"
                actions={
                  <SpaceBetween direction="horizontal" size="xs">
                    <Button
                      variant="primary"
                      onClick={(e) => {
                        addRow("attribute");
                      }}
                    >
                      Add attribute
                    </Button>
                  </SpaceBetween>
                }
              >
                Attributes
              </Header>
            }
            columnDefinitions={[
              {
                id: "key",
                header: "Key",
                cell: (attribute) => attribute.key || "-",
                width: 200,
                minWidth: 176,
                isRowHeader: true,
                editConfig: {
                  ariaLabel: "key",
                  editIconAriaLabel: "editable",
                  errorIconAriaLabel: "Key Error",
                  editingCell: (attribute, cellContext) => {
                    return (
                      <Input
                        autoFocus={true}
                        value={cellContext.currentValue ?? attribute.key}
                        onChange={(event) => {
                          cellContext.setValue(event.detail.value);
                        }}
                      />
                    );
                  },
                },
              },
              {
                id: "value",
                header: "Value",
                cell: (attribute) => attribute.value || "-",

                minWidth: 176,
                isRowHeader: true,
                editConfig: {
                  ariaLabel: "key",
                  editIconAriaLabel: "editable",
                  errorIconAriaLabel: "Key Error",
                  editingCell: (attribute, cellContext) => {
                    return (
                      <Input
                        autoFocus={true}
                        value={cellContext.currentValue ?? attribute.value}
                        onChange={(event) => {
                          cellContext.setValue(event.detail.value);
                        }}
                      />
                    );
                  },
                },
              },
              {
                id: "remove",
                header: "Remove",
                cell: (item) => (
                  <FontAwesomeIcon
                    icon={faTrash}
                    style={{ color: "#ff0000", cursor: "pointer" }}
                    onClick={(event) => removeRow(event, item.key, "attribute")}
                  />
                ),
                width: 30,
                minWidth: 30,
              },
            ]}
            submitEdit={(item, column, newValue) => {
              handleAttributeEdit(column.id as string, item, column, newValue);
            }}
            items={attributes}
            loadingText="Loading attributes..."
          />
        </SpaceBetween>
      </Form>
      {selectedMessageKey && (
        <TranslationModal
          visible={isModalOpen}
          setVisible={setModalOpen}
          messageKey={selectedMessageKey}
          item={item}
          setItem={setItem}
        />
      )}
    </ContentLayout>
  );
}
