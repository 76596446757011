import React from 'react'
import useChangeDetector from "../../../utils/useChangeDetector";

const defaultContext = {
    item: null as any,
    itemShadow: null as any,
    changesDetected: false as boolean,
    setItem: (() => {
    }) as React.Dispatch<any>,
    setItemShadow: (() => {
    }) as React.Dispatch<any>
};

const ItemContext = React.createContext<typeof defaultContext>(defaultContext);

export const useItemContext = () => {
    const context = React.useContext(ItemContext);
    if (!context) {
        throw new Error("useItemContext must be used within a ItemProvider");
    }
    return context;
}

interface ItemProviderProps {
    children: React.ReactNode;
}

export const DashboardProvider: React.FC<ItemProviderProps> = ({children}) => {
    const {
        item,
        setItem,
        setItemShadow,
        changesDetected,
        itemShadow,
    } = useChangeDetector<any>({});

    return (
        <ItemContext.Provider value={{item, setItem, setItemShadow, changesDetected, itemShadow}}>
            {children}
        </ItemContext.Provider>
    );
}
