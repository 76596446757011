import {useCallback} from 'react';
import {v4 as uuidv4} from 'uuid';
import _ from 'lodash';

export const useInlineHoopEdit = (setItem: Function) => {

    return {
        handleHoopEdit: useCallback((field: string, targetItem: any, column: any, newValue: any) => {
            console.log("handleHoopEdit", field, targetItem, column, newValue);

            setItem((prevState: any) => {
                let updatedData = _.cloneDeep(prevState.data);
                if (['openTime', 'closeTime', 'day'].includes(column?.id)) {
                    updatedData.hoops.days = prevState.data.hoops.days.map((day: any) => {
                        const updateValue = column?.id === 'day' && typeof newValue === 'object' ? newValue.value : newValue;
                        return day.key === targetItem.key ? {...day, [column.id]: updateValue} : day;
                    });
                }
                
                return {
                    ...prevState,
                    data: updatedData,
                };
            });
        }, [setItem]),

        removeHoopRow: useCallback((event: React.MouseEvent<SVGSVGElement, MouseEvent>, keyToRemove: any, type: string = 'closure') => {
            event.stopPropagation(); // To prevent any other unintended event triggers

            setItem((prevState: any) => {
                // Deep clone the existing data to avoid direct mutation
                let updatedData = JSON.parse(JSON.stringify(prevState.data));

                // Filter out the row with the keyToRemove from daysOfWeek
                if (type === 'closure') {
                    updatedData.hoops.days = updatedData.hoops.days.filter((day: any) => day.key !== keyToRemove);
                }

                return {
                    ...prevState,
                    data: updatedData
                };
            });
        }, [setItem]),

        addHoopRow: useCallback((type = "closure") => {
            setItem((prevState: any) => {
                // Deep clone the existing data to avoid direct mutation
                let updatedData = JSON.parse(JSON.stringify(prevState.data));

                // Update daysOfWeek to include the new row
                if (type === "addHoop") {
                    const newRow = {
                        closeTime: "17:00",
                        openTime: "09:00",
                        day: "Monday",
                        key: uuidv4()
                    };
                    updatedData.hoops.days.push(newRow);
                }

                return {
                    ...prevState,
                    data: updatedData
                };
            });
        }, [setItem]),

    };
}
