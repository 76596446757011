import React from 'react';
import {
    Header,
    SpaceBetween,
    ColumnLayout,
    Container, Toggle,
} from '@cloudscape-design/components';
import {UnplannedClosure} from "../../types";
import ClosureMessages from "./closureMessages";
import _ from 'lodash';
import Alert from "@cloudscape-design/components/alert"; // if you are using lodash

interface Props {
    unplannedClosure: UnplannedClosure;
    setItem: Function;
}

const Unplanned: React.FC<Props> = ({unplannedClosure, setItem}) => {

    function toggleActive() {
        setItem((prevState: any) => {
            // Deep clone the state to avoid direct mutations
            const newState = _.cloneDeep(prevState);

            // Toggle the active field
            newState.data.unplannedClosure.active = !newState.data.unplannedClosure.active;

            return newState;
        });
    }

    return (
        <Container
            header={
                <Header
                    variant="h2"
                    actions={
                        <SpaceBetween
                            direction="horizontal"
                            size="xs"
                        >
                            <Toggle
                                onChange={({detail}) =>
                                    toggleActive()
                                }
                                checked={unplannedClosure.active}
                            >
                                On/off
                            </Toggle>
                        </SpaceBetween>
                    }
                >
                    Unplanned closure
                </Header>
            }
        >

            <SpaceBetween direction="vertical" size="xs">
                {unplannedClosure.active &&
                    <Alert
                        statusIconAriaLabel="Warning"
                        type="warning"
                        header="Unplanned message is currently active"
                    />
                }
                <ColumnLayout columns={1} variant="text-grid">
                    <ClosureMessages messages={unplannedClosure?.message} setItem={setItem}
                                     messageKey={"unplannedClosure"}/>
                </ColumnLayout>
            </SpaceBetween>
        </Container>
    );
};

export default Unplanned;
