/* eslint-disable react/prop-types */
import React from "react";
import { ColumnLayout, Container, Header } from "@cloudscape-design/components";
import Beta from "../../../../components/commons/beta";

// prop to be: contactObject.cxConversationSummary
export default function ConversationSummary() {
  return (
    <Container
      header={
        <Header headingTagOverride="h3">
          Conversation Summary <Beta type={"preview"} />
        </Header>
      }
    >
      <ColumnLayout columns={1} variant="text-grid">
        A customer contacted CX Studio about a missed payment. The agent
        confirmed the account details and found a £20 pending payment. With the
        customer&apos;s consent, the agent used the on-file debit card to
        process the payment, unblocking the card. The customer left the
        conversation satisfied.
      </ColumnLayout>
    </Container>
  );
}
