import { useState, useEffect } from 'react';
import useSWR from 'swr';
import fetcher from '../../../../../utils/fetcher';

const base64ToBlob = (base64) => {
  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i += 1) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray]);
};

export default function GetAudioFromS3({ url, children }) {
  const [blobURL, setBlobURL] = useState(null);
  const [error, setError] = useState(null);

  // Define the SWR URL conditionally
  const swrUrl = url === 'demo' ? null : `/tenant-file?path=${url}`;

  const {
    data,
    error: fetchError,
    isLoading,
  } = useSWR(swrUrl, fetcher, {
    shouldRetryOnError: false,
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  useEffect(() => {
    if (fetchError) {
      setError('Failed to fetch audio');
      return;
    }

    if (data) {
      try {
        const blob = base64ToBlob(data.body);
        const audioSrc = URL.createObjectURL(blob);
        setBlobURL(audioSrc);
      } catch (e) {
        setError('Invalid data received');
      }
    }

    if (url === 'demo') {
      // Load the local sound file
      fetch('/sampleCallRecording.wav')
        .then((response) => response.blob())
        .then((blob) => {
          const audioSrc = URL.createObjectURL(blob);
          setBlobURL(audioSrc);
        })
        .catch((err) => {
          setError('Failed to load local audio', err);
        });
    }
  }, [data, fetchError, url]);

  // Pass the blobURL and error to children as a function
  return children({ blobURL, isLoading: isLoading, error });
}
