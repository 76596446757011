/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Badge from '@cloudscape-design/components/badge';
import { Box, ColumnLayout, Container, Header, SpaceBetween } from '@cloudscape-design/components';
import AudioPlaybackWidget from './audioPlaybackWidget';
import AudioTranscriptDisplay from './audioTranscriptDisplay';

function ValueWithLabel({ label, children }) {
  return (
    <div>
      <Box variant="awsui-key-label">{label}</Box>
      <div>{children}</div>
    </div>
  );
}

export default function ContactAnalysisVoice({ contactObject }) {
  const [audioPosition, setAudioPosition] = useState(200);
  // create an object that maps overs the MatchedCategories array such that "greeting (2)" can be displayed.
  const categoryCounts = {};
  contactObject?.categories?.MatchedCategories?.forEach((item) => {
    if (categoryCounts[item]) {
      categoryCounts[item] += 1;
    } else {
      categoryCounts[item] = 1;
    }
  });

  // Create an object to get the Issues Detected text to display if present
  let issueTexts = [];
  contactObject?.transcript?.forEach((entry) => {
    entry?.IssuesDetected?.forEach((issue) => {
      issueTexts.push(issue.Text);
    });
  });

  // if there are no issues, set issueTexts to null
  if (issueTexts.length === 0) issueTexts = null;

  return (
    <Container header={<Header headingTagOverride="h3">Contact Analysis</Header>}>
      <ColumnLayout columns={3} variant="text-grid">
        <SpaceBetween size="xs">
          <ValueWithLabel label="Talk speed - Agent">
            {contactObject.conversationCharacteristics?.TalkSpeed?.DetailsByParticipant?.AGENT?.AverageWordsPerMinute ??
              '-'}
          </ValueWithLabel>
          <ValueWithLabel label="Talk speed - Customer">
            {contactObject.conversationCharacteristics?.TalkSpeed?.DetailsByParticipant?.CUSTOMER
              ?.AverageWordsPerMinute ?? '-'}
          </ValueWithLabel>
          <ValueWithLabel label="Overall Sentiment - Agent">
            {contactObject.conversationCharacteristics?.Sentiment?.OverallSentiment?.AGENT ?? '-'}
          </ValueWithLabel>
          <ValueWithLabel label="Overall Sentiment - Customer">
            {contactObject.conversationCharacteristics?.Sentiment?.OverallSentiment?.CUSTOMER ?? '-'}
          </ValueWithLabel>
        </SpaceBetween>
        <SpaceBetween size="xs">
          <ValueWithLabel label="Total Interruptions">
            {contactObject.conversationCharacteristics?.Interruptions?.TotalCount ?? '-'}
          </ValueWithLabel>
          <ValueWithLabel label="Silence">
            {contactObject?.conversationCharacteristics?.NonTalkTime &&
            contactObject?.conversationCharacteristics?.TotalConversationDurationMillis
              ? `${Math.round(
                  (contactObject.conversationCharacteristics.NonTalkTime.TotalTimeMillis /
                    contactObject.conversationCharacteristics.TotalConversationDurationMillis) *
                    100
                )}%`
              : '-'}
          </ValueWithLabel>
        </SpaceBetween>
        <SpaceBetween size="xs">
          <Box variant="h3" padding="n">
            Matched Categories
          </Box>
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
            {Object.entries(categoryCounts).map(([category, count], index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Badge color="blue" key={index}>
                {category}
                {count > 1 ? ` (${count})` : ''}
              </Badge>
            ))}
          </div>
          <Box variant="h3" padding="n">
            Issues Detected
          </Box>
          {issueTexts ? (
            issueTexts.map((text, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Badge color="red" key={index}>
                {text}
              </Badge>
            ))
          ) : (
            <p>No issues detected.</p>
          )}
        </SpaceBetween>
      </ColumnLayout>
      <ColumnLayout columns={1} variant="text-grid">
        <SpaceBetween size="xs">
          <Box variant="h3" padding="xl">
            Audio playback
          </Box>
          <AudioPlaybackWidget
            contactObject={contactObject}
            setAudioPosition={setAudioPosition}
            audioURL={
              !contactObject?.inputS3Uri && process.env.REACT_APP_ENV !== 'prod'
                ? 'demo'
                : contactObject?.inputS3Uri?.replace('s3://', '')
            }
          />
        </SpaceBetween>
        {contactObject?.transcript && (
          <SpaceBetween size="xs">
            <Box variant="h3" padding="xl">
              Transcription
            </Box>
            <AudioTranscriptDisplay transcript={contactObject?.transcript} audioPosition={audioPosition} />
          </SpaceBetween>
        )}
      </ColumnLayout>
    </Container>
  );
}
