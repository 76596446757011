import React, { createContext, useState } from "react";
import { Header } from "@cloudscape-design/components";
import { WidgetConfig } from "../interfaces";
import GetWidgetData from "../../../../../../../utils/getWidgetData";
import { useItemContext } from "../../../../dashboardContext";
import { allContent, Content, WidgetPreferences } from "../preferences";
import WidgetTable from "./table";

export const getQueueSummaryStats: WidgetConfig = {
  definition: { defaultRowSpan: 4, defaultColumnSpan: 2, minRowSpan: 3 },
  data: {
    icon: "table",
    title: "Queue performance",
    description: "Table with key metrics, grouped by queue",
    header: ChartHeader,
    content: QueueSummaryStatsContent,
    provider: QueueSummaryStatsProvider,
    staticMinHeight: 560,
    multipleAllowed: true,
  },
};

interface QueueSummaryStatsWidgetContextType {
  visibleContent: ReadonlyArray<Content>;
  openPreferences: () => void;
}

const QueueSummaryStatsWidgetContext =
  createContext<QueueSummaryStatsWidgetContextType>({
    visibleContent: [],
    openPreferences: () => {},
  });

function QueueSummaryStatsProvider({
  children,
  widgetId,
}: {
  children: React.ReactElement;
  widgetId: string;
}) {
  const [preferencesVisible, setPreferencesVisible] = useState(false);
  const [visibleContent, setVisibleContent] =
    useState<ReadonlyArray<Content>>(allContent);
  return (
    <QueueSummaryStatsWidgetContext.Provider
      value={{
        visibleContent,
        openPreferences: () => setPreferencesVisible(true),
      }}
    >
      {React.cloneElement(React.Children.only(children), {
        removeConfirmationText: "Operational metrics",
        actions: [
          { text: "Preferences", onClick: () => setPreferencesVisible(true) },
        ],
      })}
      {preferencesVisible && (
        <WidgetPreferences
          widgetId={widgetId}
          preferences={visibleContent}
          onConfirm={(visibleContent) => {
            setVisibleContent(visibleContent);
            setPreferencesVisible(false);
          }}
          onDismiss={() => setPreferencesVisible(false)}
        />
      )}
    </QueueSummaryStatsWidgetContext.Provider>
  );
}

function ChartHeader() {
  return (
    <Header variant="h2" description="Table with key metrics, grouped by queue">
      Queue Performance
    </Header>
  );
}

export default function QueueSummaryStatsContent({
  widgetId,
}: {
  widgetId: string;
}) {
  const { item } = useItemContext();
  const widget = item.data.widgets.find(
    (widget: { id: string }) => widget.id === widgetId,
  );
  const period = item.data.period;

  const { data, isLoading, error } = GetWidgetData({ widget, period });

  return (
    <>
      <WidgetTable isLoading={isLoading} data={data?.data} error={error} />
    </>
  );
}
