import React from 'react';
import useSWR from 'swr';
import fetcher from '../../utils/fetcher'
import Header from '@cloudscape-design/components/header';
import SpaceBetween from '@cloudscape-design/components/space-between';
import {ContentLayout} from '@cloudscape-design/components';
import DialPlanTable from "./components/table";

export default function DialPlan() {
    const {data, isLoading, error} = useSWR('/dialPlans', fetcher, {
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        refreshInterval: 0,
    });


    return (
        <ContentLayout
            header={
                <Header
                    variant="h1"
                    description="Manage dial plan configuration"
                >
                    Manage dial plan configuration
                </Header>
            }
        >
            <SpaceBetween size="m">
                <DialPlanTable isLoading={isLoading} error={error} dialPlan={data?.data}/>
            </SpaceBetween>
        </ContentLayout>
    );
}

