import React, { useState, useEffect } from "react";
import fetcher from "../../../utils/fetcher";
import {
  ColumnLayout,
  Container,
  Header,
  ContentLayout,
  SpaceBetween,
  Textarea,
  Form,
  Button,
  FormField,
  Spinner,
} from "@cloudscape-design/components";
import { useNavigate, useParams } from "react-router-dom";
import useChangeDetector from "../../../utils/useChangeDetector";
import { SingleClosureAPIResponse } from "../types";
import useSWR from "swr";
import { handleFieldChange } from "../lib/fieldChangeUtils";
import OpeningHours from "./components/openingHours";
import PlannedClosures from "./components/plannedClosures";
import Unplanned from "./components/unplannedClosure";
import TimeZoneSelect from "../../../components/messages/timezoneSelect";
import { useApiWithFlash } from "../../../utils/hooks/useApiWithFlash";

export default function ClosuresEdit() {
  // state to control changes made
  const { item, setItem, setItemShadow, changesDetected } =
    useChangeDetector<any>({});

  // state
  const [formSubmited, setFormSubmited] = useState<boolean>(false);

  // Hooks
  const navigate = useNavigate();
  const { featureId } = useParams();
  const { handleApiWithFlash } = useApiWithFlash();

  const { data, isLoading, error } = useSWR<SingleClosureAPIResponse>(
    `/closures/${featureId}`,
    fetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshInterval: 0,
    },
  );

  // Saves the results form the API into local state
  useEffect(() => {
    if (data?.data) {
      setItem(data.data);
      setItemShadow(data.data);
    }
  }, [data, setItem, setItemShadow]);

  if (error) return <div>Error loading data</div>;
  if (!data) return <div>Loading...</div>;

  const hoops = item?.data?.hoops || [];
  const plannedClosures = item?.data?.plannedClosures || [];
  const unplannedClosure = item?.data?.unplannedClosure || [];

  // This is used by the other fields in the form to update local state
  const setItemFieldChange = handleFieldChange(setItem, item);

  // Handle form submission
  async function handleFormSubmit() {
    setFormSubmited(true);
    await handleApiWithFlash(`/closures/${item.feature}`, "PUT", {
      successMessage: `Successfully updated closure group ${item.feature}`,
      errorMessage: `Error updating closure group ${item.feature}`,
      mutateKey: `/closures/${item.feature}`,
      data: item,
    });
    setFormSubmited(false);
  }

  return (
    <ContentLayout header={<Header variant="h1"></Header>}>
      <Form
        variant="embedded"
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              formAction="none"
              variant="link"
              onClick={() => navigate("/closures")}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              loading={formSubmited}
              onClick={handleFormSubmit}
              disabled={!changesDetected}
            >
              Submit
            </Button>
          </SpaceBetween>
        }
      >
        <SpaceBetween direction="vertical" size="xl">
          <Container
            header={
              <Header variant="h2">
                {isLoading ? <Spinner /> : item?.feature}
              </Header>
            }
          >
            <ColumnLayout columns={2}>
              <FormField label="Description">
                <Textarea
                  onChange={({ detail }) =>
                    setItemFieldChange("description", detail.value)
                  }
                  value={item?.description}
                />
              </FormField>
              <FormField label="Timezone">
                <TimeZoneSelect
                  onChange={(tz) =>
                    setItemFieldChange("data.timezone", tz.value)
                  }
                  value={{
                    label: item?.data?.timezone,
                    value: item?.data?.timezone,
                  }}
                />
              </FormField>
            </ColumnLayout>
          </Container>
          <OpeningHours hoops={hoops} setItem={setItem} />
          <PlannedClosures
            plannedClosures={plannedClosures}
            setItem={setItem}
            item={item}
          />
          <Unplanned unplannedClosure={unplannedClosure} setItem={setItem} />
        </SpaceBetween>
      </Form>
    </ContentLayout>
  );
}
