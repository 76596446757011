import React from "react";
import { CollectionPreferences } from "@cloudscape-design/components";

const rawColumns = [
  {
    id: "queueName",
    header: "Queue",
    cell: (item) => item.queueName || "",
    width: 100,
    isRowHeader: true,
    sortingField: "queueName",
  },
  {
    id: "AGENTS_AFTER_CONTACT_WORK",
    header: "ACW",
    cell: (item) => {
      const metricItem = item.data.find(
        (metric) => metric.Metric.Name === "AGENTS_AFTER_CONTACT_WORK",
      );
      return metricItem ? metricItem.Value : "";
    },
    width: 100,
    isRowHeader: true,
    sortingField: "no_of_contactIds",
  },
  {
    id: "AGENTS_AVAILABLE",
    header: "Available",
    cell: (item) => {
      const metricItem = item.data.find(
        (metric) => metric.Metric.Name === "AGENTS_AVAILABLE",
      );
      return metricItem ? metricItem.Value : "";
    },
    width: 100,
    isRowHeader: true,
    sortingField: "no_of_contactIds",
  },
  {
    id: "AGENTS_ERROR",
    header: "Error",
    cell: (item) => {
      const metricItem = item.data.find(
        (metric) => metric.Metric.Name === "AGENTS_ERROR",
      );
      return metricItem ? metricItem.Value : "";
    },
    width: 100,
    isRowHeader: true,
    sortingField: "no_of_contactIds",
  },
  {
    id: "AGENTS_NON_PRODUCTIVE",
    header: "Non-Productive",
    cell: (item) => {
      const metricItem = item.data.find(
        (metric) => metric.Metric.Name === "AGENTS_NON_PRODUCTIVE",
      );
      return metricItem ? metricItem.Value : "";
    },
    width: 100,
    isRowHeader: true,
    sortingField: "no_of_contactIds",
  },
  {
    id: "AGENTS_ON_CALL",
    header: "On Call",
    cell: (item) => {
      const metricItem = item.data.find(
        (metric) => metric.Metric.Name === "AGENTS_ON_CALL",
      );
      return metricItem ? metricItem.Value : "";
    },
    width: 100,
    isRowHeader: true,
    sortingField: "no_of_contactIds",
  },
  {
    id: "AGENTS_ON_CONTACT",
    header: "On Contact",
    cell: (item) => {
      const metricItem = item.data.find(
        (metric) => metric.Metric.Name === "AGENTS_ON_CONTACT",
      );
      return metricItem ? metricItem.Value : "";
    },
    width: 100,
    isRowHeader: true,
    sortingField: "no_of_contactIds",
  },
  {
    id: "AGENTS_ONLINE",
    header: "Online",
    cell: (item) => {
      const metricItem = item.data.find(
        (metric) => metric.Metric.Name === "AGENTS_ONLINE",
      );
      return metricItem ? metricItem.Value : "";
    },
    width: 100,
    isRowHeader: true,
    sortingField: "no_of_contactIds",
  },
  {
    id: "AGENTS_STAFFED",
    header: "Staffed",
    cell: (item) => {
      const metricItem = item.data.find(
        (metric) => metric.Metric.Name === "AGENTS_STAFFED",
      );
      return metricItem ? metricItem.Value : "";
    },
    width: 100,
    isRowHeader: true,
    sortingField: "no_of_contactIds",
  },
  {
    id: "CONTACTS_IN_QUEUE",
    header: "In Queue",
    cell: (item) => {
      const metricItem = item.data.find(
        (metric) => metric.Metric.Name === "CONTACTS_IN_QUEUE",
      );
      return metricItem ? metricItem.Value : "";
    },
    width: 100,
    isRowHeader: true,
    sortingField: "no_of_contactIds",
  },
  {
    id: "CONTACTS_SCHEDULED",
    header: "Scheduled",
    cell: (item) => {
      const metricItem = item.data.find(
        (metric) => metric.Metric.Name === "CONTACTS_SCHEDULED",
      );
      return metricItem ? metricItem.Value : "";
    },
    width: 100,
    isRowHeader: true,
    sortingField: "no_of_contactIds",
  },
  {
    id: "OLDEST_CONTACT_AGE",
    header: "Oldest Age",
    cell: (item) => {
      const metricItem = item.data.find(
        (metric) => metric.Metric.Name === "OLDEST_CONTACT_AGE",
      );
      return metricItem ? metricItem.Value : "";
    },
    width: 100,
    isRowHeader: true,
    sortingField: "no_of_contactIds",
  },
  {
    id: "SLOTS_ACTIVE",
    header: "Active Slots",
    cell: (item) => {
      const metricItem = item.data.find(
        (metric) => metric.Metric.Name === "SLOTS_ACTIVE",
      );
      return metricItem ? metricItem.Value : "";
    },
    width: 100,
    isRowHeader: true,
    sortingField: "no_of_contactIds",
  },
  {
    id: "SLOTS_AVAILABLE",
    header: "Available Slots",
    cell: (item) => {
      const metricItem = item.data.find(
        (metric) => metric.Metric.Name === "SLOTS_AVAILABLE",
      );
      return metricItem ? metricItem.Value : "";
    },
    width: 100,
    isRowHeader: true,
    sortingField: "no_of_contactIds",
  },
];

export const COLUMN_DEFINITIONS = rawColumns.map((column) => ({ ...column }));

const editableColumns = {};

export const EDITABLE_COLUMN_DEFINITIONS = COLUMN_DEFINITIONS.map((column) => {
  if (editableColumns[column.id]) {
    return {
      ...column,
      minWidth: Math.max(column.minWidth || 0, 176),
      ...editableColumns[column.id],
    };
  }
  return column;
});

const CONTENT_DISPLAY_OPTIONS = [
  { id: "queueName", label: "Queue", alwaysVisible: true },
  { id: "AGENTS_AFTER_CONTACT_WORK", label: "ACW", alwaysVisible: false },
  { id: "AGENTS_AVAILABLE", label: "Available", alwaysVisible: false },
  { id: "AGENTS_ERROR", label: "Error", alwaysVisible: false },
  {
    id: "AGENTS_NON_PRODUCTIVE",
    label: "Non-Productive",
    alwaysVisible: false,
  },
  { id: "AGENTS_ON_CALL", label: "On Call", alwaysVisible: false },
  { id: "AGENTS_ON_CONTACT", label: "On Contact", alwaysVisible: false },
  { id: "AGENTS_ONLINE", label: "Online", alwaysVisible: false },
  { id: "AGENTS_STAFFED", label: "Staffed", alwaysVisible: false },
  { id: "CONTACTS_IN_QUEUE", label: "In Queue", alwaysVisible: false },
  { id: "CONTACTS_SCHEDULED", label: "Scheduled", alwaysVisible: false },
  { id: "OLDEST_CONTACT_AGE", label: "Oldest Age", alwaysVisible: false },
  { id: "SLOTS_ACTIVE", label: "Active Slots", alwaysVisible: false },
  { id: "SLOTS_AVAILABLE", label: "Available Slots", alwaysVisible: false },
];
export const PAGE_SIZE_OPTIONS = [
  { value: 10, label: "10 rows" },
  { value: 30, label: "30 rows" },
  { value: 50, label: "50 rows" },
];

export const DEFAULT_PREFERENCES = {
  pageSize: 10,
  contentDisplay: [
    { id: "queueName", visible: true },
    { id: "CONTACTS_IN_QUEUE", visible: true },
    { id: "OLDEST_CONTACT_AGE", visible: true },
    { id: "CONTACTS_SCHEDULED", visible: true },
    { id: "AGENTS_AFTER_CONTACT_WORK", visible: true },
    { id: "AGENTS_AVAILABLE", visible: true },
    { id: "AGENTS_ON_CALL", visible: true },
    { id: "AGENTS_ON_CONTACT", visible: true },
    { id: "AGENTS_ONLINE", visible: true },
    { id: "SLOTS_ACTIVE", visible: true },
    { id: "SLOTS_AVAILABLE", visible: true },
    { id: "AGENTS_STAFFED", visible: true },
    { id: "AGENTS_ERROR", visible: true },
    { id: "AGENTS_NON_PRODUCTIVE", visible: true },
  ],
  wrapLines: false,
  stripedRows: false,
  contentDensity: "compact",
  stickyColumns: { first: 1, last: 0 },
};

export const Preferences = ({
  preferences,
  setPreferences,
  disabled = false,
  pageSizeOptions = PAGE_SIZE_OPTIONS,
  contentDisplayOptions = CONTENT_DISPLAY_OPTIONS,
}) => (
  <CollectionPreferences
    title={"Preferences"}
    cancelLabel={"Cancel"}
    confirmLabel={"Confirm"}
    disabled={disabled}
    preferences={preferences}
    onConfirm={({ detail }) => setPreferences(detail)}
    pageSizePreference={{ title: "Page size", options: pageSizeOptions }}
    wrapLinesPreference={{
      label: "Wrap lines",
      description: "Select to see all the text and wrap the lines",
    }}
    stripedRowsPreference={{
      label: "Striped rows",
      description: "Select to add alternating shaded rows",
    }}
    contentDensityPreference={{
      label: "Compact mode",
      description: "Select to display content in a denser, more compact mode",
    }}
    contentDisplayPreference={{
      title: "Select visible content / order",
      options: contentDisplayOptions,
    }}
    stickyColumnsPreference={{
      firstColumns: {
        title: "Stick first column(s)",
        description:
          "Keep the first column(s) visible while horizontally scrolling the table content.",
        options: [
          { label: "None", value: 0 },
          { label: "First column", value: 1 },
          { label: "First two columns", value: 2 },
        ],
      },
      lastColumns: {
        title: "Stick last column",
        description:
          "Keep the last column visible while horizontally scrolling the table content.",
        options: [
          { label: "None", value: 0 },
          { label: "Last column", value: 1 },
        ],
      },
    }}
  />
);
