import React from 'react';
import {Link} from "react-router-dom";
import {
    CollectionPreferences,
} from '@cloudscape-design/components';


const rawColumns = [
    {
        id: "feature",
        header: "Resource Name",
        cell: item => <Link to={`/dial-plan/edit/${item?.feature}`}>{item?.feature}</Link> || "-",
        sortingField: "feature"
    },
    {
        id: "description",
        header: "Description",
        cell: item => item?.description || "-",
        sortingField: "description",
    },
    {
        id: "type",
        header: "Type",
        cell: item => item?.type || "-",
        sortingField: "type",
    }
];

export const COLUMN_DEFINITIONS = rawColumns.map(column => ({...column}));


const editableColumns = {};

export const EDITABLE_COLUMN_DEFINITIONS = COLUMN_DEFINITIONS.map(column => {
    if (editableColumns[column.id]) {
        return {
            ...column,
            minWidth: Math.max(column.minWidth || 0, 176),
            ...editableColumns[column.id],
        };
    }
    return column;
});

const CONTENT_DISPLAY_OPTIONS = [
    {id: 'feature', label: 'Resource Name', alwaysVisible: true},
    {id: 'description', label: 'Description', alwaysVisible: false},
    {id: 'type', label: 'Type', alwaysVisible: false},
];

export const PAGE_SIZE_OPTIONS = [
    {value: 10, label: '10 dial plans'},
    {value: 30, label: '30 dial plans'},
    {value: 50, label: '50 dial plans'},
];

export const DEFAULT_PREFERENCES = {
    pageSize: 10,
    contentDisplay: [
        {id: 'feature', visible: true},
        {id: 'description', visible: true},
        {id: 'type', visible: true},
    ],
    wrapLines: false,
    stripedRows: false,
    contentDensity: "comfortable",
    stickyColumns: {first: 1, last: 0},
};

export const Preferences = ({
                                preferences,
                                setPreferences,
                                disabled = false,
                                pageSizeOptions = PAGE_SIZE_OPTIONS,
                                contentDisplayOptions = CONTENT_DISPLAY_OPTIONS,
                            }) => (
    <CollectionPreferences
        title={"Preferences"}
        cancelLabel={"Cancel"}
        confirmLabel={"Confirm"}
        disabled={disabled}
        preferences={preferences}
        onConfirm={({detail}) => setPreferences(detail)}
        pageSizePreference={{title: "Page size", options: pageSizeOptions}}
        wrapLinesPreference={{label: "Wrap lines", description: "Select to see all the text and wrap the lines"}}
        stripedRowsPreference={{label: "Striped rows", description: "Select to add alternating shaded rows"}}
        contentDensityPreference={{
            label: "Compact mode",
            description: "Select to display content in a denser, more compact mode"
        }}
        contentDisplayPreference={{title: "Select visible content / order", options: contentDisplayOptions}}
        stickyColumnsPreference={{
            firstColumns: {
                title: 'Stick first column(s)',
                description: 'Keep the first column(s) visible while horizontally scrolling the table content.',
                options: [
                    {label: 'None', value: 0},
                    {label: 'First column', value: 1},
                    {label: 'First two columns', value: 2},
                ],
            },
            lastColumns: {
                title: 'Stick last column',
                description: 'Keep the last column visible while horizontally scrolling the table content.',
                options: [
                    {label: 'None', value: 0},
                    {label: 'Last column', value: 1},
                ],
            },
        }}
    />
);