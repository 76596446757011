import { Auth } from 'aws-amplify';

export type UserProfile = {
    given_name: string;
    family_name: string;
    email: string;
};

export async function fetchUserProfile(): Promise<UserProfile | null> {
    try {
        const user = await Auth.currentAuthenticatedUser();
        // Assuming user.attributes is of type UserProfile
        return user.attributes as UserProfile;
    } catch (error) {
        console.error("Failed to fetch user profile", error);
        return null;
    }
}
