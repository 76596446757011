/*
  This context provider will all the tenant config to be access from within the app.

  To access the data:

  const tenantData = useTenant();
  const supportTier = tenantData?.supportTier;
  const memberType = tenantData?.memberType;

  This is used to set views and allows access to areas of the application.

 */

import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import useSWR from 'swr';
import { Auth } from 'aws-amplify';
import fetcher from '../fetcher';

interface TenantContextProps {
  children: ReactNode;
}

interface UserProfile {
  given_name: string;
  family_name: string;
  email: string;
  'custom:securityProfile': string;
  'custom:tenantId': string;
  sub: string;
}

interface AppSessionContextValue {
  supportTier: string | null;
  memberType: string | null;
  userProfile: UserProfile | null;
  fullScreen: boolean;
  setFullScreen: (state: boolean) => void;
}

const SessionContext = createContext<AppSessionContextValue | null>(null);

export const useAppSession = () => {
  return useContext(SessionContext);
};

export const AppSessionProvider = ({ children }: TenantContextProps) => {
  const [supportTier, setSupportTier] = useState<string | null>(null);
  const [memberType, setMemberType] = useState<string | null>(null);
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const [fullScreen, setFullScreen] = useState<boolean>(false);

  const { data, error } = useSWR('/tenantProfile', fetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshInterval: 0,
  });

  useEffect(() => {
    if (data) {
      const { supportTier, memberType } = data?.data;
      setSupportTier(supportTier);
      setMemberType(memberType);
    }
    if (error) {
      console.error('Error fetching tenant data:', error);
    }
  }, [data, error]);

  useEffect(() => {
    const getUserProfile = async () => {
      const profile = await Auth.currentAuthenticatedUser();
      setUserProfile(profile.attributes);
    };

    getUserProfile();
  }, []);

  return (
    <SessionContext.Provider
      value={{
        supportTier,
        memberType,
        userProfile,
        fullScreen,
        setFullScreen,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};
