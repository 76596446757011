import React from 'react';
import { Header, LineChart } from '@cloudscape-design/components';
import { commonChartProps, dateTimeFormatter } from '../chart-commons';
import { WidgetConfig } from '../interfaces';
import GetWidgetData from '../../../../../../../utils/getWidgetData';
import { useItemContext } from '../../../../dashboardContext';

export const getModelLatency: WidgetConfig = {
  definition: { defaultRowSpan: 4, defaultColumnSpan: 2, minRowSpan: 3 },
  data: {
    icon: 'lineChart',
    title: 'AI Model Latency',
    description: 'The average time for the genAI model to respond ',
    header: GetConversationsHeader,
    content: GetUserFeedback,
    staticMinHeight: 200,
    multipleAllowed: false,
  },
};

function GetConversationsHeader() {
  return (
    <Header variant="h2" description="Average">
      AI Model Latency
    </Header>
  );
}

export default function GetUserFeedback({ widgetId }: { widgetId: string }) {
  const { item } = useItemContext();
  const widget = item.data.widgets.find((widget: { id: string }) => widget.id === widgetId);
  const period = item.data.period;

  const { data, isLoading, error } = GetWidgetData({ widget, period });

  let transformedData;

  if (data && data.data) {
    transformedData = data.data.map((series: { data: any[] }) => ({
      ...series,
      data: series.data.map((point) => ({
        ...point,
        x: new Date(point.x),
      })),
    }));
  }

  return (
    <>
      <LineChart
        {...commonChartProps}
        hideFilter={true}
        fitHeight={true}
        statusType={isLoading ? 'loading' : error ? 'error' : 'finished'}
        height={150}
        series={transformedData}
        xScaleType="time"
        xTitle="Time (UTC)"
        xDomain={data?.xDomain?.map((timestamp: string | number | Date) => new Date(timestamp))}
        yTitle="Latency"
        ariaLabel="Latency"
        ariaDescription={`Line chart showing count of user feedback scores`}
        i18nStrings={{
          ...commonChartProps.i18nStrings,
          filterLabel: 'Filter displayed data',
          filterPlaceholder: 'Filter data',
          xTickFormatter: dateTimeFormatter,
        }}
      />
    </>
  );
}
