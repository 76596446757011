// sortEvents.js
import _ from "lodash";
import { isDateExpired } from "./isDateExpired";

/**
 * Sorts an array of events by date, putting future and current dates first,
 * followed by past dates, all in descending order.
 *
 * @param {Array} events - Array of event objects to sort.
 * @return {Array} - Sorted array of events.
 */
const sortEvents = (events) => {
  const sortedEvents = _.orderBy(
    events,
    [
      (event) => (isDateExpired(event) ? 0 : 1),
      (event) => new Date(event.date),
    ],
    ["desc", "asc"],
  );

  return sortedEvents;
};

export default sortEvents;
