import React from "react";
import {
  Table,
  Select,
  TimeInput,
  Header,
  SpaceBetween,
  Button,
} from "@cloudscape-design/components";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useInlineHoopEdit } from "../../lib/useInlineHoopEdit";
import { Hoops } from "../../types";
import ClosureMessages from "./closureMessages";

interface Props {
  hoops: Hoops;
  setItem: Function;
}

const OpeningHours: React.FC<Props> = ({ hoops, setItem }) => {
  const { handleHoopEdit, addHoopRow, removeHoopRow } =
    useInlineHoopEdit(setItem);

  return (
    <Table
      header={
        <Header
          variant="h2"
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                variant="primary"
                onClick={() => {
                  addHoopRow("addHoop");
                }}
              >
                Add
              </Button>
            </SpaceBetween>
          }
        >
          Opening Hours
        </Header>
      }
      footer={
        <ClosureMessages
          messages={hoops?.message}
          setItem={setItem}
          messageKey={"hoops"}
        />
      }
      columnDefinitions={[
        {
          id: "day",
          header: "Day",
          cell: (item) => item.day || "-",
          width: 200,
          minWidth: 200,
          isRowHeader: true,
          editConfig: {
            ariaLabel: "day",
            editIconAriaLabel: "editable",
            errorIconAriaLabel: "Key Error",
            editingCell: (item, cellContext) => {
              return (
                <Select
                  selectedOption={
                    cellContext.currentValue ?? {
                      label: item.day,
                      value: item.day,
                    }
                  }
                  onChange={({ detail }) =>
                    cellContext.setValue(detail.selectedOption)
                  }
                  expandToViewport
                  options={[
                    { label: "Monday", value: "Monday" },
                    { label: "Tuesday", value: "Tuesday" },
                    { label: "Wednesday", value: "Wednesday" },
                    { label: "Thursday", value: "Thursday" },
                    { label: "Friday", value: "Friday" },
                    { label: "Saturday", value: "Saturday" },
                    { label: "Sunday", value: "Sunday" },
                  ]}
                />
              );
            },
          },
        },
        {
          id: "openTime",
          header: "Open Time",
          cell: (item) => item.openTime || "-",
          width: 200,
          minWidth: 165,
          isRowHeader: true,
          editConfig: {
            ariaLabel: "openTime",
            editIconAriaLabel: "editable",
            errorIconAriaLabel: "Key Error",
            editingCell: (item, cellContext) => {
              return (
                <TimeInput
                  autoFocus={true}
                  value={cellContext.currentValue ?? item.openTime}
                  onChange={(event) => {
                    cellContext.setValue(event.detail.value);
                  }}
                  format="hh:mm"
                  placeholder="hh:mm"
                />
              );
            },
          },
        },
        {
          id: "closeTime",
          header: "Closing Time",
          cell: (item) => item.closeTime || "-",
          width: 200,
          minWidth: 165,
          isRowHeader: true,
          editConfig: {
            ariaLabel: "closeTime",
            editIconAriaLabel: "editable",
            errorIconAriaLabel: "Key Error",
            editingCell: (item, cellContext) => {
              return (
                <TimeInput
                  autoFocus={true}
                  value={cellContext.currentValue ?? item.closeTime}
                  onChange={(event) => {
                    cellContext.setValue(event.detail.value);
                  }}
                  format="hh:mm"
                  placeholder="hh:mm"
                />
              );
            },
          },
        },
        {
          id: "remove",
          header: "Remove",
          cell: (item) => (
            <FontAwesomeIcon
              icon={faTrash}
              style={{ color: "#ff0000", cursor: "pointer" }}
              onClick={(event) => removeHoopRow(event, item.key)}
            />
          ),
          width: 30,
          minWidth: 30,
        },
      ]}
      submitEdit={(item, column, newValue) => {
        handleHoopEdit(column.id as string, item, column, newValue);
      }}
      items={hoops.days}
      loadingText="Loading opening hours..."
    />
  );
};

export default OpeningHours;
