import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import '../../../../../components/conversationBubbles/messages.css';
import customer from '../../../../../components/conversationBubbles/customer.jpeg';
import agent from '../../../../../components/conversationBubbles/cato.png';
import { Badge } from '@cloudscape-design/components';
import Icon from '@cloudscape-design/components/icon';

function AudioTranscriptDisplay({ transcript, audioPosition }) {
  const activeTranscriptRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    // Scroll the active transcript into view
    if (activeTranscriptRef.current && containerRef.current) {
      const containerTop = containerRef.current.getBoundingClientRect().top;
      const activeTop = activeTranscriptRef.current.getBoundingClientRect().top;
      const offset = activeTop - containerTop;

      if (offset < 0 || offset > containerRef.current.clientHeight) {
        containerRef.current.scrollTo({
          top: containerRef.current.scrollTop + offset - 100, // Adjust the scroll offset as needed
          behavior: 'smooth',
        });
      }
    }
  }, [audioPosition]);

  return (
    <div>
      <div className={`relative space-y-1 overflow-y-auto h-auto`} ref={containerRef}>
        {transcript.map((message) => {
          const isActive = audioPosition >= message.BeginOffsetMillis && audioPosition <= message.EndOffsetMillis;

          const isAgent = message.ParticipantId === 'AGENT';

          return (
            <div ref={isActive ? activeTranscriptRef : null} key={message.Id}>
              {isAgent && (
                <div className={`chat-start chat`}>
                  <div className="chat-image">
                    <div className="avatar-img">
                      <img alt={'Agent'} src={agent} />
                    </div>
                  </div>
                  <div className="chat-content">
                    <div className={`chat-header-start`}>Agent</div>
                    <div className={`chat-bubble chat-bubble-info`}>{message.Content}</div>
                  </div>
                </div>
              )}

              {!isAgent && (
                <div className={`chat-end chat`}>
                  <div className="chat-image">
                    <div className="avatar-img">
                      <img alt={'Customer'} src={customer} />
                    </div>
                    <div className="sentiment-img">
                      {message.Sentiment === 'POSITIVE' ? (
                        <Icon name="thumbs-up-filled" variant="success" />
                      ) : message.Sentiment === 'NEGATIVE' ? (
                        <Icon name="thumbs-down-filled" variant="error" />
                      ) : (
                        <Icon name="thumbs-up-filled" variant="success" />
                      )}
                    </div>
                  </div>
                  <div className="chat-content">
                    <div className={`chat-header-end`}>Customer</div>
                    <div className={`chat-bubble chat-bubble-success`}>{message.Content}</div>
                    {message?.IssuesDetected && (
                      <div className={'feedback-container'}>
                        <Badge color="red">Issue</Badge>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

AudioTranscriptDisplay.propTypes = {
  transcript: PropTypes.arrayOf(
    PropTypes.shape({
      Id: PropTypes.string.isRequired,
      Content: PropTypes.string.isRequired,
      BeginOffsetMillis: PropTypes.number.isRequired,
      EndOffsetMillis: PropTypes.number.isRequired,
      ParticipantId: PropTypes.string.isRequired,
    })
  ).isRequired,
  audioPosition: PropTypes.number.isRequired,
};

export default AudioTranscriptDisplay;
