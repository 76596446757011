import React, { useState } from 'react';
import SpaceBetween from '@cloudscape-design/components/space-between';
import Button from '@cloudscape-design/components/button';
import Header from '@cloudscape-design/components/header';
import ButtonDropdown, { ButtonDropdownProps } from '@cloudscape-design/components/button-dropdown';
import { Pagination, Spinner, Table } from '@cloudscape-design/components';
import PropertyFilter from '@cloudscape-design/components/property-filter';
import Alert from '@cloudscape-design/components/alert';
import { getTextFilterCounterText } from '../../../utils/text-filter';
import { useCollection } from '@cloudscape-design/collection-hooks';
import { useLocalStorage } from '../../../components/use-local-storage';
import { useColumnWidths } from '../../../components/table/use-column-width';
import { FILTERING_PROPERTIES as filteringProperties } from './filtering_poperties';
import { mutate } from 'swr';

import { DEFAULT_PREFERENCES, EDITABLE_COLUMN_DEFINITIONS, Preferences } from './table.config';

import fetcher from '../../../utils/fetcher';

import { TableEmptyState, TableNoMatchState } from '../../../components/table/commons';
import { User, UserTableProps } from '../types';
import { useNavigate } from 'react-router-dom';
import { useApiWithFlash } from '../../../utils/hooks/useApiWithFlash';

export default function UserTable({ isLoading, users = [], currentUser, error }: UserTableProps) {
  // State
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState<User[]>([]);
  const [actionLoading, setActionLoading] = useState(false);
  const [columnDefinitions, saveWidths] = useColumnWidths(
    'Cato-Users-TableEditable-Widths',
    EDITABLE_COLUMN_DEFINITIONS
  );
  const [preferences, setPreferences] = useLocalStorage('Cato-Users-TableEditable-Preferences', DEFAULT_PREFERENCES);

  // Hooks
  const navigate = useNavigate();
  const { handleApiWithFlash } = useApiWithFlash();

  const { items, actions, filteredItemsCount, collectionProps, propertyFilterProps, paginationProps } = useCollection(
    users,
    {
      propertyFiltering: {
        filteringProperties,
        empty: <TableEmptyState resourceName="User" urlPath={'user-admin'} />,
        noMatch: (
          <TableNoMatchState
            onClearFilter={() => {
              actions.setPropertyFiltering({ tokens: [], operation: 'and' });
            }}
          />
        ),
      },
      filtering: {
        empty: <TableEmptyState resourceName="Users" urlPath={'user-admin'} />,
        noMatch: (
          <TableNoMatchState
            onClearFilter={() => {
              actions.setPropertyFiltering({ tokens: [], operation: 'and' });
            }}
          />
        ),
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: {},
      selection: {},
    }
  );

  // This is used to handle button presses
  const handleUtilityItemClick = async (event: CustomEvent<ButtonDropdownProps.ItemClickDetails>) => {
    const details = event.detail;
    if (details.id === 'remove') {
      const username = selectedItems[0].Username;

      await handleApiWithFlash(`/users/${username}`, 'DELETE', {
        successMessage: `Successfully deleted user: ${username}`,
        errorMessage: `Error deleting user: ${username}`,
        mutateKey: '/users',
        deleteMessage: `Are you sure you want to delete user: ${username}`,
      });
      // Clear down the selected user to clear the button
      setSelectedItems([]);
    }
    setActionLoading(true);
    if (details.id === 'toggle-active') {
      await fetcher(`/users/${selectedItems[0].Username}/status`, 'PATCH');
      await mutate('/users');
    }
    setActionLoading(false);
  };

  const onRefresh = async () => {
    setRefreshLoading(true);
    await mutate('/users');
    setRefreshLoading(false);
  };

  const refreshButtonProps = { onClick: onRefresh };

  // This is used to handle inline edits within with table
  const handleSubmit = async (currentItem: User, column: any, value: any) => {
    if (column.id === 'securityProfile') {
      const data = {
        securityProfile: value,
      };
      try {
        await fetcher(`/users/${currentItem.Username}/profile`, 'PATCH', data);
        await mutate('/users');
      } catch (error) {
        console.error('Failed to update security profile:', error);
        throw error; // This will cause the promise to reject and the UI to show an error
      }
    }
  };

  return (
    <>
      <Table
        {...collectionProps}
        stickyHeader={true}
        resizableColumns={true}
        onColumnWidthsChange={saveWidths}
        onSelectionChange={({
          detail: { selectedItems },
        }: {
          detail: {
            selectedItems: User[];
          };
        }) => {
          if (selectedItems && selectedItems.length > 0) {
            const selectedItem: User = selectedItems[0];
            setSelectedItems(() => [selectedItem]);
          }
        }}
        selectedItems={selectedItems as any}
        columnDefinitions={columnDefinitions}
        items={items}
        submitEdit={handleSubmit}
        trackBy="Username"
        loadingText="Loading users"
        isItemDisabled={(item) => item.Username === currentUser}
        columnDisplay={preferences.contentDisplay}
        wrapLines={preferences.wrapLines}
        stripedRows={preferences.stripedRows}
        stickyColumns={preferences.stickyColumns}
        contentDensity={preferences.contentDensity as 'compact' | 'comfortable'}
        selectionType={'single'}
        loading={isLoading || !users || refreshLoading}
        header={
          <Header
            variant="awsui-h1-sticky"
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button iconName="refresh" ariaLabel="Refresh" {...refreshButtonProps} />
                <ButtonDropdown
                  disabled={!selectedItems || selectedItems.length === 0}
                  onItemClick={handleUtilityItemClick}
                  loading={actionLoading}
                  loadingText={'loading'}
                  items={[
                    {
                      text: 'Toggle active',
                      id: 'toggle-active',
                      disabled: false,
                    },
                    {
                      text: 'Remove',
                      id: 'remove',
                      disabled: false,
                    },
                  ]}
                >
                  Actions
                </ButtonDropdown>
                <Button variant="primary" onClick={() => navigate('/user-admin/create')}>
                  Create user
                </Button>
              </SpaceBetween>
            }
          >
            Application users {users && !isLoading ? `(${users.length})` : <Spinner />}
          </Header>
        }
        filter={
          <PropertyFilter
            {...propertyFilterProps}
            countText={getTextFilterCounterText(filteredItemsCount)}
            expandToViewport={true}
            filteringAriaLabel={'Filter users'}
            filteringPlaceholder={'Filter users'}
          />
        }
        pagination={<Pagination {...paginationProps} />}
        preferences={<Preferences preferences={preferences} setPreferences={setPreferences} />}
      />
      {error && (
        <Alert statusIconAriaLabel="Error" type="error" header="Error getting data">
          Refresh the page to try again.
        </Alert>
      )}
    </>
  );
}
