import React, { createContext, useContext, useState } from "react";
import { Header, Spinner } from "@cloudscape-design/components";
import { WidgetConfig } from "../interfaces";
import useSWR from "swr";
import fetcher from "../../../../../../../utils/fetcher";
import { allContent, Content, WidgetPreferences } from "./preferences";
import { EmptyState } from "../../empty-state";
import Button from "@cloudscape-design/components/button";
import WidgetTable from "./table";

export const getInstanceQueueMetrics: WidgetConfig = {
  definition: { defaultRowSpan: 1, defaultColumnSpan: 2, minRowSpan: 1 },
  data: {
    icon: "table",
    title: "Queue metrics",
    description: "Realtime queue data. Table for all queues",
    header: ChartHeader,
    content: InstanceQueueMetrics,
    provider: InstanceQueueMetricsProvider,
    staticMinHeight: 560,
    multipleAllowed: true,
  },
};

interface InstanceQueueMetricsWidgetContextType {
  visibleContent: ReadonlyArray<Content>;
  openPreferences: () => void;
  widgetId: any;
}

const InstanceQueueMetricsWidgetContext =
  createContext<InstanceQueueMetricsWidgetContextType>({
    visibleContent: [],
    openPreferences: () => {},
    widgetId: {},
  });

function InstanceQueueMetricsProvider({
  children,
  widgetId,
}: {
  children: React.ReactElement;
  widgetId: string;
}) {
  const [preferencesVisible, setPreferencesVisible] = useState(false);
  const [visibleContent, setVisibleContent] =
    useState<ReadonlyArray<Content>>(allContent);
  return (
    <InstanceQueueMetricsWidgetContext.Provider
      value={{
        widgetId,
        visibleContent,
        openPreferences: () => setPreferencesVisible(true),
      }}
    >
      {React.cloneElement(React.Children.only(children), {
        removeConfirmationText: "Instance Queue Metrics",
      })}
      {preferencesVisible && (
        <WidgetPreferences
          widgetId={widgetId}
          preferences={visibleContent}
          onConfirm={(visibleContent) => {
            setVisibleContent(visibleContent);
            setPreferencesVisible(false);
          }}
          onDismiss={() => setPreferencesVisible(false)}
        />
      )}
    </InstanceQueueMetricsWidgetContext.Provider>
  );
}

function ChartHeader() {
  return (
    <Header variant="h2" description="Realtime queue data">
      Realtime queues
    </Header>
  );
}

export default function InstanceQueueMetrics({
  widgetId,
}: {
  widgetId: string;
}) {
  const { visibleContent, openPreferences } = useContext(
    InstanceQueueMetricsWidgetContext,
  );

  // Conditionally set the endpoint to null if queueId doesn't exist
  const endpoint = `/instanceQueueMetrics`;

  const { data, isLoading, error } = useSWR(endpoint, fetcher, {
    shouldRetryOnError: true,
    revalidateIfStale: true,
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
    refreshInterval: 5000,
  });

  if (visibleContent.length <= 0 || !endpoint) {
    return (
      <EmptyState
        title="No data to display"
        description="Open widget preferences to choose a queue and some data to be displayed."
        verticalCenter={true}
        action={<Button onClick={openPreferences}>Open preferences</Button>}
      />
    );
  }

  if (error) {
    return (
      <EmptyState
        title="Error retrieving data"
        description="This could be due to no data being received for this queue. Please select a different queue"
        verticalCenter={true}
        action={<Button onClick={openPreferences}>Open preferences</Button>}
      />
    );
  }

  if (isLoading) {
    return <Spinner />;
  }

  return <WidgetTable isLoading={isLoading} data={data?.data} error={error} />;
}
