const pollyLanguages = [
    {text: 'Arabic (Gulf)', id: 'ar-AE'},
    {text: 'Catalan', id: 'ca-ES'},
    {text: 'Danish', id: 'da-DK'},
    {text: 'Dutch', id: 'nl-NL'},
    {text: 'English (Australian)', id: 'en-AU'},
    {text: 'English (British)', id: 'en-GB'},
    {text: 'English (Indian)', id: 'en-IN'},
    {text: 'English (New Zealand)', id: 'en-NZ'},
    {text: 'English (South African)', id: 'en-ZA'},
    {text: 'English (US)', id: 'en-US'},
    {text: 'Finnish', id: 'fi-FI'},
    {text: 'French', id: 'fr-FR'},
    {text: 'French (Canadian)', id: 'fr-CA'},
    {text: 'German', id: 'de-DE'},
    {text: 'German (Austrian)', id: 'de-AT'},
    {text: 'Hindi', id: 'hi-IN'},
    {text: 'Icelandic', id: 'is-IS'},
    {text: 'Italian', id: 'it-IT'},
    {text: 'Japanese', id: 'ja-JP'},
    {text: 'Korean', id: 'ko-KR'},
    {text: 'Polish', id: 'pl-PL'},
    {text: 'Portuguese (Brazilian)', id: 'pt-BR'},
    {text: 'Portuguese (European)', id: 'pt-PT'},
    {text: 'Romanian', id: 'ro-RO'},
    {text: 'Russian', id: 'ru-RU'},
    {text: 'Spanish (European)', id: 'es-ES'},
    {text: 'Spanish (Mexican)', id: 'es-MX'},
    {text: 'Spanish (US)', id: 'es-US'},
    {text: 'Swedish', id: 'sv-SE'},
    {text: 'Turkish', id: 'tr-TR'},
    {text: 'Welsh', id: 'cy-GB'},
];

export default pollyLanguages;
