import React, { createContext, useState, useEffect } from "react";
import {
  Box,
  Header,
  Popover,
  SpaceBetween,
  Table,
  TableProps,
} from "@cloudscape-design/components";
import { WidgetConfig } from "../interfaces";
import GetWidgetData from "../../../../../../../utils/getWidgetData";
import { useItemContext } from "../../../../dashboardContext";
import { allContent, Content, WidgetPreferences } from "../preferences";
import Link from "@cloudscape-design/components/link";
import Alert from "@cloudscape-design/components/alert";

export const getContactLensIssues: WidgetConfig = {
  definition: { defaultRowSpan: 4, defaultColumnSpan: 2, minRowSpan: 3 },
  data: {
    icon: "list",
    title: "Contact Lens Categories",
    description:
      "Shows a list of contacts that have matched the contact lens categories.",
    header: ChartHeader,
    content: ContactLensIssuesContent,
    provider: ContactLensIssuesProvider,
    staticMinHeight: 560,
    multipleAllowed: true,
  },
};

interface Item {
  contactId: string;
  connectedToSystemTimestamp: string;
  categories: {
    MatchedDetails: {
      [key: string]: string;
    };
    MatchedCategories: string[];
  };
  conversationCharacteristics: {
    Sentiment: {
      OverallSentiment: {
        AGENT: number;
        CUSTOMER: number;
      };
    };
    TotalConversationDurationMillis: number;
  };
}

interface ContactLensIssuesWidgetContextType {
  visibleContent: ReadonlyArray<Content>;
  openPreferences: () => void;
}

const ContactLensIssuesWidgetContext =
  createContext<ContactLensIssuesWidgetContextType>({
    visibleContent: [],
    openPreferences: () => {},
  });

function ContactLensIssuesProvider({
  children,
  widgetId,
}: {
  children: React.ReactElement;
  widgetId: string;
}) {
  const [preferencesVisible, setPreferencesVisible] = useState(false);
  const [visibleContent, setVisibleContent] =
    useState<ReadonlyArray<Content>>(allContent);
  return (
    <ContactLensIssuesWidgetContext.Provider
      value={{
        visibleContent,
        openPreferences: () => setPreferencesVisible(true),
      }}
    >
      {React.cloneElement(React.Children.only(children), {
        removeConfirmationText: "Operational metrics",
        actions: [
          { text: "Preferences", onClick: () => setPreferencesVisible(true) },
        ],
      })}
      {preferencesVisible && (
        <WidgetPreferences
          widgetId={widgetId}
          preferences={visibleContent}
          onConfirm={(visibleContent) => {
            setVisibleContent(visibleContent);
            setPreferencesVisible(false);
          }}
          onDismiss={() => setPreferencesVisible(false)}
        />
      )}
    </ContactLensIssuesWidgetContext.Provider>
  );
}

function ChartHeader() {
  return (
    <Header
      variant="h2"
      description="Shows a list of contacts that have matched the contact lens categories."
    >
      Contact Lens Categories
    </Header>
  );
}

export default function ContactLensIssuesContent({
  widgetId,
}: {
  widgetId: string;
}) {
  const { item } = useItemContext();
  const widget = item.data.widgets.find(
    (widget: { id: string }) => widget.id === widgetId,
  );
  const period = item.data.period;
  const [maxRecords, setMaxRecords] = useState(true);
  const { data, isLoading, error } = GetWidgetData({ widget, period });

  useEffect(() => {
    if (data && data.data.length > 499) {
      setMaxRecords(true);
    } else {
      setMaxRecords(false);
    }
  }, [data]);

  if (error) {
    return (
      <Alert
        statusIconAriaLabel="Error"
        type="error"
        header="Unable to load widget"
      >
        {error.status}
      </Alert>
    );
  }

  const tableDefinition: TableProps<Item>["columnDefinitions"] = [
    {
      id: "connectedToSystemTimestamp",
      header: "connectedToSystemTimestamp",
      cell: (item) => (
        <Link
          target="_blank"
          rel="noopener noreferrer"
          href={`/contact-search/view/${item.contactId}`}
        >
          {item.connectedToSystemTimestamp}
        </Link>
      ),
      width: 200,
      isRowHeader: true,
    },
    {
      id: "categories",
      header: "Customer Utterance",
      cell: (item) =>
        item.categories.MatchedDetails[item.categories.MatchedCategories[0]] ||
        "",
    },
    {
      id: "answer",
      header: "Answer",
      cell: (item) => item.categories.MatchedCategories[0] || "",
    },
  ];

  return (
    <>
      {maxRecords && (
        <Box color="text-status-info" display="inline">
          <Popover
            header="Too many results"
            size="medium"
            triggerType="text"
            content={<>Results are limited to the first 500.</>}
            renderWithPortal={true}
          >
            <Box
              color="text-status-warning"
              fontSize="body-s"
              fontWeight="bold"
            >
              Max results
            </Box>
          </Popover>
        </Box>
      )}
      <Table
        variant="borderless"
        items={data?.data}
        columnDefinitions={tableDefinition}
        loading={isLoading}
        empty={
          <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
            <SpaceBetween size="m">
              <b>No results</b>
            </SpaceBetween>
          </Box>
        }
      />
    </>
  );
}
