import React, { useState, useEffect } from "react";
import fetcher from "../../../utils/fetcher";
import {
  ColumnLayout,
  Container,
  Header,
  ContentLayout,
  SpaceBetween,
  Textarea,
  Form,
  Input,
  Button,
  FormField,
  Spinner,
} from "@cloudscape-design/components";
import { useNavigate, useParams } from "react-router-dom";
import useChangeDetector from "../../../utils/useChangeDetector";
import useSWR from "swr";
import _ from "lodash";
import { useApiWithFlash } from "../../../utils/hooks/useApiWithFlash";
import Alert from "@cloudscape-design/components/alert";

export default function DialPlanEdit() {
  // state to control changes made
  const { item, setItem, setItemShadow, changesDetected } =
    useChangeDetector<any>({});

  // state
  const [formSubmited, setFormSubmited] = useState<boolean>(false);

  // Hooks
  const navigate = useNavigate();
  const { featureId } = useParams();
  const { handleApiWithFlash } = useApiWithFlash();

  const { data, isLoading, error } = useSWR(
    `/dialPlans/${featureId}`,
    fetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshInterval: 0,
    },
  );

  // Saves the results form the API into local state
  useEffect(() => {
    if (data?.data) {
      setItem(data.data);
      setItemShadow(data.data);
    }
  }, [data, setItem, setItemShadow]);

  if (error) {
    return (
      <Alert
        statusIconAriaLabel="Error"
        type="error"
        header="Unable to load component"
      >
        {error.status}
      </Alert>
    );
  }

  function handleChange(value: string, path: string) {
    setItem((prevState: any) => {
      const newState = _.cloneDeep(prevState);
      _.set(newState, path, value);
      return newState;
    });
  }

  // Handle form submission
  async function handleSubmitForm() {
    setFormSubmited(true);

    let payload = {
      feature: data?.data?.feature,
      description: item.description || "",
      data: {
        prefix: item?.data?.prefix,
        type: data?.data?.data?.type,
      },
    };

    const response = await handleApiWithFlash(
      `/dialPlans/${data?.data?.feature}`,
      "PUT",
      {
        successMessage: `Successfully updated dial plan ${payload.feature}`,
        errorMessage: `Error updating dial plan ${payload.feature}`,
        data: payload,
        mutateKey: `/dialPlans/${data?.data?.feature}`,
      },
    );

    if (response?.success) {
      setFormSubmited(false);
    }
  }

  return (
    <ContentLayout header={<Header variant="h1"></Header>}>
      <Container
        header={
          <Header variant="h2" description="Edit resource">
            Type: {isLoading ? <Spinner /> : item?.data?.type}
          </Header>
        }
      >
        <form
          onSubmit={(e) => {
            console.log(e);
            e.preventDefault();
            // const value = e.target.elements.prompt.value; // Accessing value of the input/textarea with name="prompt"
            handleSubmitForm();
          }}
        >
          <Form
            variant="embedded"
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  formAction="none"
                  variant="link"
                  onClick={() => navigate("/dial-plan")}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  loading={formSubmited}
                  disabled={!changesDetected}
                >
                  Submit
                </Button>
              </SpaceBetween>
            }
          >
            <SpaceBetween direction="vertical" size="xl">
              <ColumnLayout columns={2} variant="text-grid">
                <FormField
                  label={
                    item?.type === "resource"
                      ? "Resource group name"
                      : "e164 number"
                  }
                >
                  <Input value={item?.feature} disabled />
                </FormField>
                <FormField label="Description">
                  <Textarea
                    onChange={({ detail }) =>
                      handleChange(detail.value, "description")
                    }
                    value={item?.description}
                  />
                </FormField>
              </ColumnLayout>
              {item?.data?.type === "RESOURCE" && (
                <ColumnLayout columns={1} variant="text-grid">
                  <FormField
                    constraintText="Provide a comma seperated list of prefixes using e164"
                    label="Prefix"
                  >
                    <Input
                      onChange={({ detail }) =>
                        handleChange(detail.value, "data.prefix")
                      }
                      value={item?.data?.prefix}
                      placeholder={"+449, +44871, +44872"}
                    />
                  </FormField>
                </ColumnLayout>
              )}
            </SpaceBetween>
          </Form>
        </form>
      </Container>
    </ContentLayout>
  );
}
