// CxBeta.tsx
import { Box, Popover, PopoverProps } from "@cloudscape-design/components";

type Props = {
  type?: "beta" | "preview";
} & PopoverProps;

const Beta = (props: Props) => {
  const {
    type = "beta",
    // Use a conditional expression to set the content based on the type prop
    content = type === "beta"
      ? "This feature is in active development and subject to change"
      : "This feature is in preview and may have limited functionality",
    ...popoverProps
  } = props;

  return (
    <Box color="text-status-info" display="inline">
      <Popover
        header={props.header}
        size="medium"
        triggerType="text"
        content={<>{content}</>}
        renderWithPortal={true}
        {...popoverProps}
      >
        <Box color="text-status-info" fontSize="body-s" fontWeight="bold">
          {type}
        </Box>
      </Popover>
    </Box>
  );
};

export default Beta;
