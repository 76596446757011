import React from "react";
import {
  Box,
  Button,
  ColumnLayout,
  Container,
  Header,
  Popover,
  SpaceBetween,
  Spinner,
  StatusIndicator,
} from "@cloudscape-design/components";
import dayjs from "dayjs";

import { TenantDataProps } from "../types";

// @ts-ignore
const ValueWithLabel = ({ label, children }) => (
  <div>
    <Box variant="awsui-key-label">{label}</Box>
    <div>{children}</div>
  </div>
);

export default function TenantData({
  data,
  isLoading,
  error,
}: TenantDataProps) {
  return (
    <Container
      header={
        <Header headingTagOverride="h3">
          Configuration Details {isLoading && <Spinner />}
        </Header>
      }
    >
      <ColumnLayout columns={3} variant="text-grid">
        <SpaceBetween size="l">
          <ValueWithLabel label="Account Id">{data?.accountId}</ValueWithLabel>
          <ValueWithLabel label="Member Since">
            {data?.createdAt && (
              <StatusIndicator>
                {dayjs(data?.createdAt).format("DD MMM YYYY")}
              </StatusIndicator>
            )}
          </ValueWithLabel>
          <ValueWithLabel label="Member Type">
            {data?.memberType}
          </ValueWithLabel>
        </SpaceBetween>
        <SpaceBetween size="l">
          <ValueWithLabel label="Cross Account Role">
            {data?.crossAccountRoleName}
          </ValueWithLabel>
          <ValueWithLabel label="Connect Instance Id">
            {data?.instanceId}
          </ValueWithLabel>
        </SpaceBetween>
        <SpaceBetween size="l">
          <ValueWithLabel label="Lambda ARN">
            <div className="custom-wrapping">
              <Box margin={{ right: "xxs" }} display="inline-block">
                <Popover
                  size="small"
                  position="top"
                  dismissButton={false}
                  triggerType="custom"
                  content={
                    <StatusIndicator type="success">ARN copied</StatusIndicator>
                  }
                >
                  <Button
                    variant="inline-icon"
                    iconName="copy"
                    ariaLabel="Copy ARN"
                    onClick={() => {
                      navigator.clipboard.writeText(data?.lambdaArn);
                    }}
                  />
                </Popover>
              </Box>
              {data?.lambdaArn}
            </div>
          </ValueWithLabel>
          <ValueWithLabel label="S3 Alias">
            <div className="custom-wrapping">
              <Box margin={{ right: "xxs" }} display="inline-block">
                <Popover
                  size="small"
                  position="top"
                  dismissButton={false}
                  triggerType="custom"
                  content={
                    <StatusIndicator type="success">ARN copied</StatusIndicator>
                  }
                >
                  <Button
                    variant="inline-icon"
                    iconName="copy"
                    ariaLabel="Copy ARN"
                    onClick={() => {
                      navigator.clipboard.writeText(data?.s3AccessPointAlias);
                    }}
                  />
                </Popover>
              </Box>
              {data?.s3AccessPointAlias}
            </div>
          </ValueWithLabel>
        </SpaceBetween>
      </ColumnLayout>
    </Container>
  );
}
