import * as React from 'react';
import LineChart from '@cloudscape-design/components/line-chart';

import * as awsui from '@cloudscape-design/design-tokens/index';

/* eslint-disable react/prop-types */
export default function SentimentLineChart({ SentimentByPeriod }) {
  function yValueFormatter(e) {
    if (e >= 1) {
      return `${e} Positive`;
    }
    if (e <= -1) {
      return `${Math.abs(e)} Negative`;
    }
    return 'Neutral';
  }

  const agentData = SentimentByPeriod?.QUARTER?.AGENT?.map((point, index) => ({
    x: index + 1, // 1-based index for quarters
    y: point.Score,
  }));

  const customerData = SentimentByPeriod?.QUARTER?.CUSTOMER?.map((point, index) => ({
    x: index + 1, // 1-based index for quarters
    y: point.Score,
  }));

  const series = [
    {
      title: 'AGENT',
      type: 'line',
      color: awsui.colorChartsPurple300,
      data: agentData,
      valueFormatter: yValueFormatter,
    },
    {
      title: 'CUSTOMER',
      type: 'line',
      color: awsui.colorChartsGreen300,
      data: customerData,
      valueFormatter: yValueFormatter,
    },
  ];

  return (
    <LineChart
      series={series}
      xDomain={[1, 4]} // Updated for quarters
      yDomain={[-5, 5]} // Updated based on score range
      ariaLabel="Single data series line chart"
      height={150}
      hideFilter
      xScaleType="linear"
      xTitle="Quarter"
      yTitle="Sentiment Score"
    />
  );
}
