import React, { useId } from "react";
import Box from "@cloudscape-design/components/box";
import ColumnLayout from "@cloudscape-design/components/column-layout";
import Modal from "@cloudscape-design/components/modal";
import styles from "./preferences.module.scss";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import Toggle from "@cloudscape-design/components/toggle";
import { useItemContext } from "../../../../dashboardContext";
import QueuePickerSingle from "../../../../../../../components/amzn-connect-components/queue-picker-single";
import FormField from "@cloudscape-design/components/form-field";

export const allContent = [
  "AGENTS_AFTER_CONTACT_WORK",
  "AGENTS_AVAILABLE",
  "AGENTS_ERROR",
  "AGENTS_NON_PRODUCTIVE",
  "AGENTS_ON_CALL",
  "AGENTS_ON_CONTACT",
  "AGENTS_ONLINE",
  "AGENTS_STAFFED",
  "CONTACTS_IN_QUEUE",
  "CONTACTS_SCHEDULED",
  "OLDEST_CONTACT_AGE",
  "SLOTS_ACTIVE",
  "SLOTS_AVAILABLE",
] as const;

export type Content = (typeof allContent)[number];

interface WidgetPreferencesProps {
  preferences: ReadonlyArray<Content>;
  onDismiss: () => void;
  onConfirm: (visibleContent: ReadonlyArray<Content>) => void;
  widgetId: string;
}

export function WidgetPreferences({
  onDismiss,
  widgetId,
  preferences,
  onConfirm,
}: WidgetPreferencesProps) {
  const { item, setItem } = useItemContext();
  const widget = item?.data?.widgets?.find(
    (w: { id: string }) => w.id === widgetId,
  );

  const initialPreferences = widget?.preferences || [];

  function toggle(content: Content, checked: boolean) {
    setItem(
      (prevItem: {
        data: {
          widgets: any[];
        };
      }) => {
        const updatedWidgets = prevItem.data.widgets.map((widget) => {
          if (widget.id === widgetId) {
            let newPreferences = [...(widget.preferences || [])];
            if (checked) {
              newPreferences.push(content);
            } else {
              newPreferences = newPreferences.filter(
                (item) => item !== content,
              );
            }
            return { ...widget, preferences: newPreferences };
          }
          return widget;
        });
        return {
          ...prevItem,
          data: { ...prevItem.data, widgets: updatedWidgets },
        };
      },
    );
  }

  return (
    <Modal
      visible={true}
      header="Preferences"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" formAction="none" onClick={onDismiss}>
              Close
            </Button>
          </SpaceBetween>
        </Box>
      }
      onDismiss={onDismiss}
    >
      <ColumnLayout columns={1} borders="horizontal">
        <FormField
          description="Select a queue from the dropdown below to view its realtime data"
          label="Queue"
        >
          <QueuePickerSingle widgetId={widgetId} />
        </FormField>
        {allContent.map((content, index) => {
          const label = content
            .toLowerCase()
            .split("_")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");

          return (
            <PreferencesControl
              key={index}
              label={label}
              toggle={(id) => (
                <Toggle
                  controlId={id}
                  checked={initialPreferences.includes(content)}
                  onChange={(event) => toggle(content, event.detail.checked)}
                />
              )}
            />
          );
        })}
      </ColumnLayout>
    </Modal>
  );
}

interface PreferencesControlProps {
  label: string;
  isGroup?: boolean;
  toggle?: (id: string) => React.ReactNode;
}

function PreferencesControl({
  label,
  toggle,
  isGroup,
}: PreferencesControlProps) {
  const id = useId();
  return (
    <div className={styles.displayPreference}>
      <label
        htmlFor={id}
        className={`${styles.displayPreferenceLabel} ${
          isGroup ? styles.displayPreferenceGroup : ""
        }`}
      >
        {label}
      </label>
      {toggle?.(id)}
    </div>
  );
}
