import React from "react";
import { Link } from "react-router-dom";
import { Badge, CollectionPreferences } from "@cloudscape-design/components";

const rawColumns = [
  {
    id: "feature",
    header: "Closure Group",
    cell: (item) =>
      <Link to={`/closures/edit/${item?.feature}`}>{item?.feature}</Link> ||
      "-",
    sortingField: "feature",
    width: 200,
  },
  {
    id: "description",
    header: "Description",
    cell: (item) => item?.description || "-",
    sortingField: "description",
    width: 200,
  },
  {
    id: "timezone",
    header: "Timezone",
    cell: (item) => item?.timezone || "-",
    sortingField: "timezone",
    width: 150,
  },
  {
    id: "status",
    header: "Status",
    cell: (item) => {
      const status = item?.status || "-";
      if (status === "unplannedClosure") {
        return <Badge color="red">Unplanned Closure</Badge>;
      } else if (status === "closed") {
        return <Badge color="grey">Closed</Badge>;
      } else if (status === "open") {
        return <Badge color="green">Open</Badge>;
      } else if (status === "plannedClosure") {
        return <Badge color="blue">Planned Closure</Badge>;
      } else if (status === "recurringClosure") {
        return <Badge color="blue">Recurring Closure</Badge>;
      } else {
        return "-";
      }
    },
    sortingField: "status",
    width: 200,
  },
  {
    id: "message",
    header: "Current Message",
    cell: (item) => item?.message || "-",
    width: 1000,
  },
];

export const COLUMN_DEFINITIONS = rawColumns.map((column) => ({ ...column }));

const editableColumns = {};

export const EDITABLE_COLUMN_DEFINITIONS = COLUMN_DEFINITIONS.map((column) => {
  if (editableColumns[column.id]) {
    return {
      ...column,
      minWidth: Math.max(column.minWidth || 0, 176),
      ...editableColumns[column.id],
    };
  }
  return column;
});

const CONTENT_DISPLAY_OPTIONS = [
  { id: "feature", label: "Closure Group", alwaysVisible: true },
  { id: "description", label: "Description", alwaysVisible: false },
  { id: "timezone", label: "Timezone", alwaysVisible: false },
  { id: "status", label: "Status", alwaysVisible: false },
  { id: "message", label: "Message", alwaysVisible: false },
];

export const PAGE_SIZE_OPTIONS = [
  { value: 10, label: "10 closure groups" },
  { value: 30, label: "30 closure groups" },
  { value: 50, label: "50 closure groups" },
];

export const DEFAULT_PREFERENCES = {
  pageSize: 10,
  contentDisplay: [
    { id: "feature", visible: true },
    { id: "description", visible: true },
    { id: "timezone", visible: true },
    { id: "status", visible: true },
    { id: "message", visible: true },
  ],
  wrapLines: false,
  stripedRows: false,
  contentDensity: "comfortable",
  stickyColumns: { first: 1, last: 0 },
};

export const Preferences = ({
  preferences,
  setPreferences,
  disabled = false,
  pageSizeOptions = PAGE_SIZE_OPTIONS,
  contentDisplayOptions = CONTENT_DISPLAY_OPTIONS,
}) => (
  <CollectionPreferences
    title={"Preferences"}
    cancelLabel={"Cancel"}
    confirmLabel={"Confirm"}
    disabled={disabled}
    preferences={preferences}
    onConfirm={({ detail }) => setPreferences(detail)}
    pageSizePreference={{ title: "Page size", options: pageSizeOptions }}
    wrapLinesPreference={{
      label: "Wrap lines",
      description: "Select to see all the text and wrap the lines",
    }}
    stripedRowsPreference={{
      label: "Striped rows",
      description: "Select to add alternating shaded rows",
    }}
    contentDensityPreference={{
      label: "Compact mode",
      description: "Select to display content in a denser, more compact mode",
    }}
    contentDisplayPreference={{
      title: "Select visible content / order",
      options: contentDisplayOptions,
    }}
    stickyColumnsPreference={{
      firstColumns: {
        title: "Stick first column(s)",
        description:
          "Keep the first column(s) visible while horizontally scrolling the table content.",
        options: [
          { label: "None", value: 0 },
          { label: "First column", value: 1 },
          { label: "First two columns", value: 2 },
        ],
      },
      lastColumns: {
        title: "Stick last column",
        description:
          "Keep the last column visible while horizontally scrolling the table content.",
        options: [
          { label: "None", value: 0 },
          { label: "Last column", value: 1 },
        ],
      },
    }}
  />
);
