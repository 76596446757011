import React, { createContext, useContext, useState } from "react";
import {
  Header,
  Spinner,
  Table,
  TableProps,
  Badge,
} from "@cloudscape-design/components";
import { WidgetConfig } from "../interfaces";
import useSWR from "swr";
import fetcher from "../../../../../../../utils/fetcher";
import { allContent, Content, WidgetPreferences } from "./preferences";
import { EmptyState } from "../../empty-state";
import Button from "@cloudscape-design/components/button";
import _ from "lodash";
import Link from "@cloudscape-design/components/link";

export const getClosures: WidgetConfig = {
  definition: { defaultRowSpan: 1, defaultColumnSpan: 1, minRowSpan: 1 },
  data: {
    icon: "list",
    title: "Unplanned Closures",
    description: `Shows only queues that are currently in the status of "unplanned closure"`,
    header: ChartHeader,
    content: Closures,
    provider: ClosuresProvider,
    staticMinHeight: 560,
    multipleAllowed: true,
  },
};

interface ClosuresWidgetContextType {
  visibleContent: ReadonlyArray<Content>;
  openPreferences: () => void;
  widgetId: any;
}

interface Item {
  feature: string;
  description: string;
  timezone: string;
  status: string;
}

const ClosuresWidgetContext = createContext<ClosuresWidgetContextType>({
  visibleContent: [],
  openPreferences: () => {},
  widgetId: {},
});

function ClosuresProvider({
  children,
  widgetId,
}: {
  children: React.ReactElement;
  widgetId: string;
}) {
  const [preferencesVisible, setPreferencesVisible] = useState(false);
  const [visibleContent, setVisibleContent] =
    useState<ReadonlyArray<Content>>(allContent);
  return (
    <ClosuresWidgetContext.Provider
      value={{
        widgetId,
        visibleContent,
        openPreferences: () => setPreferencesVisible(true),
      }}
    >
      {React.cloneElement(React.Children.only(children), {
        removeConfirmationText: "Instance Queue Metrics",
        // actions: [{text: 'Preferences', onClick: () => setPreferencesVisible(true)}],
      })}
      {preferencesVisible && (
        <WidgetPreferences
          widgetId={widgetId}
          preferences={visibleContent}
          onConfirm={(visibleContent) => {
            setVisibleContent(visibleContent);
            setPreferencesVisible(false);
          }}
          onDismiss={() => setPreferencesVisible(false)}
        />
      )}
    </ClosuresWidgetContext.Provider>
  );
}

function ChartHeader() {
  return <Header variant="h2">Unplanned Closures</Header>;
}

export default function Closures() {
  const { visibleContent, openPreferences } = useContext(ClosuresWidgetContext);

  // Conditionally set the endpoint to null if queueId doesn't exist
  const endpoint = `/closures`;

  const { data, isLoading, error } = useSWR(endpoint, fetcher, {
    shouldRetryOnError: true,
    revalidateIfStale: true,
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
    refreshInterval: 5000,
  });

  if (visibleContent.length <= 0 || !endpoint) {
    return (
      <EmptyState
        title="No data to display"
        description="No queues are currently in the unplanned closure status"
        verticalCenter={true}
      />
    );
  }

  if (error) {
    return (
      <EmptyState
        title="Error retrieving data"
        description="Plese check your connection and try again"
        verticalCenter={true}
        action={<Button onClick={openPreferences}>Open preferences</Button>}
      />
    );
  }

  if (isLoading) {
    return <Spinner />;
  }

  const tableDefinition: TableProps<Item>["columnDefinitions"] = [
    {
      id: "feature",
      header: "Group",
      cell: (item) => (
        <Link
          target="_blank"
          rel="noopener noreferrer"
          href={`/closures/edit/${item.feature}`}
        >
          {item.feature}
        </Link>
      ),
      width: 75,
      isRowHeader: true,
    },
    {
      id: "status",
      header: "Status",
      cell: (item) => {
        if (item?.status === "unplannedClosure") {
          return <Badge color="red">Unplanned Closure</Badge>;
        } else if (item?.status === "closed") {
          return <Badge color="grey">Closed</Badge>;
        } else if (item?.status === "open") {
          return <Badge color="green">Open</Badge>;
        } else if (item?.status === "plannedClosure") {
          return <Badge color="blue">Planned Closure</Badge>;
        } else {
          return "-";
        }
      },
    },
  ];

  const filteredData = _.filter(data.data, { status: "unplannedClosure" });

  return (
    <Table
      variant="borderless"
      items={filteredData}
      columnDefinitions={tableDefinition}
      loading={isLoading}
    />
  );
}
