import React, {forwardRef} from 'react';
import {AppLayout, AppLayoutProps, Box, Button, Link, SpaceBetween} from '@cloudscape-design/components';
import {I18nProvider} from '@cloudscape-design/components/i18n';
import enMessages from '@cloudscape-design/components/i18n/messages/all.en.json';
import {useNavigate} from "react-router-dom";

export const TableNoMatchState = ({onClearFilter}: { onClearFilter: () => void }) => (
    <Box margin={{vertical: 'xs'}} textAlign="center" color="inherit">
        <SpaceBetween size="xxs">
            <div>
                <b>No matches</b>
                <Box variant="p" color="inherit">
                    We can't find a match.
                </Box>
            </div>
            <Button onClick={onClearFilter}>Clear filter</Button>
        </SpaceBetween>
    </Box>
);

interface TableEmptyStateProps {
    resourceName: string;
    urlPath: string;
}


export const TableEmptyState: React.FC<TableEmptyStateProps> = ({resourceName, urlPath}) => {
    const navigate = useNavigate();

    return (
        <Box margin={{vertical: 'xs'}} textAlign="center" color="inherit">
            <SpaceBetween size="xxs">
                <div>
                    <b>No {resourceName.toLowerCase()}s</b>
                    <Box variant="p" color="inherit">
                        No {resourceName.toLowerCase()}s associated with this tenant.
                    </Box>
                </div>
                <Button onClick={() => {
                    // Here we're using the navigate function to redirect to the desired path
                    navigate(`/${urlPath}/create`);
                }}>
                    Create {resourceName.toLowerCase()}
                </Button>
            </SpaceBetween>
        </Box>
    );
};


export const CustomAppLayout = forwardRef<AppLayoutProps.Ref, AppLayoutProps>((props, ref) => {
    return (
        <I18nProvider locale="en" messages={[enMessages]}>
            <AppLayout ref={ref} {...props} />
        </I18nProvider>
    );
});

export const CounterLink = ({children}: { children: React.ReactNode }) => {
    return (
        <Link variant="awsui-value-large" href="#">
            {children}
        </Link>
    );
};