import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { I18nProvider } from '@cloudscape-design/components/i18n';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Theme, applyTheme } from '@cloudscape-design/components/theming';
import customTheme from './themes/customTheme';

// Import all locales
import messages from '@cloudscape-design/components/i18n/messages/all.all';

// Auth
import { Amplify } from 'aws-amplify';
import { Authenticator, Heading, Text, useTheme, View, useAuthenticator, Button, Image } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

// Import Routes here
import Root from './routes/root';
import Home from './routes/home';
import Visualizations from './routes/visualizations';
import PromptEngineering from './routes/prompt-engineering';
import UserAdmin from './routes/user-admin';
import ErrorPage from './error-page';
import Documents from './routes/documents';
import ChatbotTesting from './routes/chatbot-testing';
import AuditLog from './routes/audit-log';
import PromptEngineeringEdit from './routes/prompt-engineering/edit';
import PromptEngineeringCreate from './routes/prompt-engineering/create';
import ContactSearch from './routes/contact-search';
import ContactSearchView from './routes/contact-search/view';
import Settings from './routes/settings';
import Utilities from './routes/utilities';
import DialPlan from './routes/dial-plan';
import DialPlanCreate from './routes/dial-plan/create';
import DialPlanEdit from './routes/dial-plan/edit';
import Messages from './routes/messages';
import MessagesCreate from './routes/messages/create';
import MessagesEdit from './routes/messages/edit';
import UserAdminCreate from './routes/user-admin/create';
import VisualizationsView from './routes/visualizations/view';
import DocumentsCreate from './routes/documents/create';
import Closures from './routes/closures';
import ClosuresCreate from './routes/closures/create';
import ClosuresEdit from './routes/closures/edit';
import VisualizationsCreate from './routes/visualizations/create';

function getCatoUrl(region: string): string {
  // Split the region string into parts
  const regionParts = region.split('-');
  // Check if the region string format is valid
  if (regionParts.length !== 3) {
    throw new Error(`Invalid AWS region format: ${region}`);
  }
  // Extract the relevant parts of the region
  const abbreviation = regionParts[0] + regionParts[1].substring(0, 1) + regionParts[2];
  return `cato-${abbreviation}.cx.studio`;
}

Amplify.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: process.env.REACT_APP_AWS_REGION,
    mandatorySignIn: true,
    identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: process.env.REACT_APP_COGNITO_WEB_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_COGNITO_DOMAIN,
      scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      responseType: 'code',
    },
  },
});
const components = {
  Header() {
    return (
      <View textAlign="center">
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Image alt="logo" src={'/dark.svg'} width={250} height={75} />
        </div>
      </View>
    );
  },

  Footer() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Text color={tokens.colors.neutral[80]}>&copy; All rights reserved CX Studios Ltd</Text>
      </View>
    );
  },

  SignIn: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
          Cato
        </Heading>
      );
    },
    Footer() {
      const { toResetPassword } = useAuthenticator();

      return (
        <View textAlign="center">
          <Button fontWeight="normal" onClick={toResetPassword} size="small" variation="link">
            Reset Password
          </Button>
        </View>
      );
    },
  },
  SetupTOTP: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading padding={`${tokens.space.large} 0 0 ${tokens.space.large}`} level={3}>
          Register OTP
        </Heading>
      );
    },
    Footer() {
      return <Text>TIP: Scan QR using Google / Microsoft Authenticator mobile app</Text>;
    },
  },
  ConfirmSignIn: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading padding={`${tokens.space.large} 0 0 ${tokens.space.large}`} level={3}>
          One time passcode:
        </Heading>
      );
    },
    Footer() {
      return <Text>TIP: Google / Microsoft Authenticator</Text>;
    },
  },
  ResetPassword: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading padding={`${tokens.space.large} 0 0 ${tokens.space.large}`} level={3}>
          Password reset:
        </Heading>
      );
    },
    Footer() {
      return <Text />;
    },
  },
  ConfirmResetPassword: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading padding={`${tokens.space.large} 0 0 ${tokens.space.large}`} level={3}>
          Enter Information:
        </Heading>
      );
    },
    Footer() {
      return <Text></Text>;
    },
  },
};
const formFields = {
  signIn: {
    username: {
      placeholder: 'Enter your email:',
      label: '',
    },
    password: {
      label: '',
      placeholder: 'Enter your Password:',
    },
  },

  forceNewPassword: {
    password: {
      placeholder: 'Enter your Password:',
    },
  },
  resetPassword: {
    username: {
      placeholder: 'Enter your email:',
      label: '',
    },
  },
  confirmResetPassword: {
    confirmation_code: {
      placeholder: 'Enter your email Code:',
      // label: 'New Label',
      isRequired: true,
    },
    confirm_password: {
      placeholder: 'Enter your Password Please:',
    },
  },
  setupTOTP: {
    QR: {
      totpIssuer:
        process.env.REACT_APP_ENV === 'prod'
          ? getCatoUrl(process.env.REACT_APP_AWS_REGION as string)
          : `Cato-${process.env.REACT_APP_ENV}`,
    },
    confirmation_code: {
      label: '',
      placeholder: 'Enter your code:',
      isRequired: true,
    },
  },
  confirmSignIn: {
    confirmation_code: {
      label: '',
      placeholder: 'Enter your authenticator Code:',
      isRequired: true,
    },
  },
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'home',
        element: <Home />,
      },
      {
        path: 'dial-plan',
        element: <DialPlan />,
      },
      {
        path: 'dial-plan/create',
        element: <DialPlanCreate />,
      },
      {
        path: 'dial-plan/edit/:featureId',
        element: <DialPlanEdit />,
      },
      {
        path: 'messages',
        element: <Messages />,
      },
      {
        path: 'messages/create',
        element: <MessagesCreate />,
      },
      {
        path: 'messages/edit/:featureId',
        element: <MessagesEdit />,
      },
      {
        path: 'closures',
        element: <Closures />,
      },
      {
        path: 'closures/create',
        element: <ClosuresCreate />,
      },
      {
        path: 'closures/edit/:featureId',
        element: <ClosuresEdit />,
      },
      {
        path: 'visualizations',
        element: <Visualizations />,
      },
      {
        path: 'visualizations/create',
        element: <VisualizationsCreate />,
      },
      {
        path: 'visualizations/view/:featureId',
        element: <VisualizationsView />,
      },
      {
        path: 'documents',
        element: <Documents />,
      },
      {
        path: 'documents/create',
        element: <DocumentsCreate />,
      },
      {
        path: 'prompt-engineering',
        element: <PromptEngineering />,
      },
      {
        path: 'prompt-engineering/edit/:featureId',
        element: <PromptEngineeringEdit />,
      },
      {
        path: 'prompt-engineering/create',
        element: <PromptEngineeringCreate />,
      },
      {
        path: 'chatbot-testing',
        element: <ChatbotTesting />,
      },
      {
        path: 'user-admin',
        element: <UserAdmin />,
      },
      {
        path: 'user-admin/create',
        element: <UserAdminCreate />,
      },
      {
        path: 'audit-log',
        element: <AuditLog />,
      },
      {
        path: 'contact-search',
        element: <ContactSearch />,
      },
      {
        path: 'contact-search/view/:contactId',
        element: <ContactSearchView />,
      },
      {
        path: 'settings',
        element: <Settings />,
      },
      {
        path: 'utilities',
        element: <Utilities />,
      },
      {
        path: '*',
        element: <ErrorPage />,
      },
    ],
  },
]);

const theme: Theme = customTheme;
// Apply custom theme
applyTheme({ theme });

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <I18nProvider locale="en" messages={[messages]}>
      <Authenticator hideSignUp formFields={formFields} components={components}>
        <RouterProvider router={router} />
      </Authenticator>
    </I18nProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
