import _ from 'lodash';

export const setNestedState = (obj: any, path: string[], value: any) => {
    if (path.length === 1) {
        obj[path[0]] = value;
    } else {
        if (!obj[path[0]]) obj[path[0]] = {};
        setNestedState(obj[path[0]], path.slice(1), value);
    }
};

export const handleFieldChange = (setItem: (prevItem: any) => any, item: any) => {
    return (fieldPath: string | string[], value: any) => {
        setItem((prev: any) => {
            const newObj = _.cloneDeep(prev);
            const path = Array.isArray(fieldPath) ? fieldPath.join('.') : fieldPath;
            _.set(newObj, path, value);
            return newObj;
        });
    };
};


