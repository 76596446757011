import React, { createContext, useContext, useState } from "react";
import {
  Header,
  Box,
  ColumnLayout,
  Spinner,
} from "@cloudscape-design/components";
import { WidgetConfig } from "../interfaces";
import { useItemContext } from "../../../../dashboardContext";
import useSWR from "swr";
import fetcher from "../../../../../../../utils/fetcher";
import { allContent, Content, WidgetPreferences } from "./preferences";
import { EmptyState } from "../../empty-state";
import Button from "@cloudscape-design/components/button";

export const getInstanceQueueMetric: WidgetConfig = {
  definition: { defaultRowSpan: 1, defaultColumnSpan: 2, minRowSpan: 1 },
  data: {
    icon: "list",
    title: "Queue metric",
    description: "Realtime queue data for 1 queue as a wallboard",
    header: ChartHeader,
    content: InstanceQueueMetric,
    provider: InstanceQueueMetricProvider,
    staticMinHeight: 560,
    multipleAllowed: true,
  },
};

interface InstanceQueueMetricWidgetContextType {
  visibleContent: ReadonlyArray<Content>;
  openPreferences: () => void;
  widgetId: any;
}

const InstanceQueueMetricWidgetContext =
  createContext<InstanceQueueMetricWidgetContextType>({
    visibleContent: [],
    openPreferences: () => {},
    widgetId: {},
  });

function InstanceQueueMetricProvider({
  children,
  widgetId,
}: {
  children: React.ReactElement;
  widgetId: string;
}) {
  const [preferencesVisible, setPreferencesVisible] = useState(false);
  const [visibleContent, setVisibleContent] =
    useState<ReadonlyArray<Content>>(allContent);
  return (
    <InstanceQueueMetricWidgetContext.Provider
      value={{
        widgetId,
        visibleContent,
        openPreferences: () => setPreferencesVisible(true),
      }}
    >
      {React.cloneElement(React.Children.only(children), {
        removeConfirmationText: "Instance Queue Metrics",
        actions: [
          { text: "Preferences", onClick: () => setPreferencesVisible(true) },
        ],
      })}
      {preferencesVisible && (
        <WidgetPreferences
          widgetId={widgetId}
          preferences={visibleContent}
          onConfirm={(visibleContent) => {
            setVisibleContent(visibleContent);
            setPreferencesVisible(false);
          }}
          onDismiss={() => setPreferencesVisible(false)}
        />
      )}
    </InstanceQueueMetricWidgetContext.Provider>
  );
}

function ChartHeader() {
  const { widgetId } = useContext(InstanceQueueMetricWidgetContext);
  const { item } = useItemContext();
  const widget = item.data.widgets.find(
    (w: { id: string }) => w.id === widgetId,
  );
  const title = widget.data.title;
  return (
    <Header variant="h2" description="Realtime queue data">
      {title}
    </Header>
  );
}

export default function InstanceQueueMetric({
  widgetId,
}: {
  widgetId: string;
}) {
  const { visibleContent, openPreferences } = useContext(
    InstanceQueueMetricWidgetContext,
  );
  const { item } = useItemContext();
  const widget = item.data.widgets.find(
    (w: { id: string }) => w.id === widgetId,
  );
  const queueArn = widget.filters.queues[0];

  // Conditionally set the endpoint to null if queueId doesn't exist
  const endpoint =
    queueArn && queueArn.startsWith("arn")
      ? `/instanceQueueMetrics?queueArn=${queueArn}`
      : null;

  const { data, isLoading, error } = useSWR(endpoint, fetcher, {
    shouldRetryOnError: true,
    revalidateIfStale: true,
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
    refreshInterval: 5000,
  });

  if (visibleContent.length <= 0 || !endpoint) {
    return (
      <EmptyState
        title="No data to display"
        description="Open widget preferences to choose a queue and some data to be displayed."
        verticalCenter={true}
        action={<Button onClick={openPreferences}>Open preferences</Button>}
      />
    );
  }

  if (error) {
    return (
      <EmptyState
        title="Error retrieving data"
        description="This could be due to no data being received for this queue. Please select a different queue"
        verticalCenter={true}
        action={<Button onClick={openPreferences}>Open preferences</Button>}
      />
    );
  }

  if (isLoading) {
    return <Spinner />;
  }

  const getMetricValue = (
    data: {
      data: {
        data: any[];
      };
    },
    metricName: string,
  ) => {
    const item = data.data.data.find(
      (element) => element.Metric.Name === metricName,
    );
    return item ? item.Value : 0;
  };

  return (
    <ColumnLayout columns={4} variant="text-grid" minColumnWidth={170}>
      {allContent.map((content, index) => {
        if (widget.preferences.includes(content)) {
          const label = content
            .replace("_", " ")
            .split(" ")
            .map((word) => word.charAt(0) + word.slice(1).toLowerCase())
            .join(" ");

          return (
            <div key={index}>
              <Box variant="awsui-key-label">{label}</Box>
              <Box fontSize="display-l" fontWeight="bold">
                {getMetricValue(data, content)}
              </Box>
            </div>
          );
        }
        return null;
      })}
    </ColumnLayout>
  );
}
