import * as React from "react";
import Modal from "@cloudscape-design/components/modal";
import Box from "@cloudscape-design/components/box";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import fetcher from "../fetcher";
import { mutate } from "swr";
import { v4 as uuidv4 } from "uuid";
import { useFlash } from "./useFlash";
import { useModal } from "./modalContext";

interface HandleApiWithFlashOptions {
  data?: any;
  successMessage: string;
  errorMessage: string;
  mutateKey?: string;
  deleteMessage?: string;
}

interface UseDocumentAction {
  handleApiWithFlash: (
    url: string,
    method: "PATCH" | "DELETE" | "POST" | "PUT",
    options: HandleApiWithFlashOptions,
  ) => Promise<ResponsePayload | undefined>;
}

interface ConfirmDeleteModalProps {
  onConfirm: () => void;
  onCancel: () => void;
}

interface ResponsePayload {
  success: boolean;
  errorCode?: number;
  errorMessage?: string;
}

export const useApiWithFlash = (): UseDocumentAction => {
  const { addFlash, removeFlash } = useFlash();
  const { showModal, hideModal } = useModal();

  const handleApiWithFlash = async (
    url: string,
    method: "PATCH" | "DELETE" | "POST" | "PUT",
    options: HandleApiWithFlashOptions,
  ): Promise<ResponsePayload | undefined> => {
    if (method === "DELETE") {
      console.log("Attempting to show modal...");
      showModal(
        <ConfirmDeleteModal
          deleteMessage={
            options.deleteMessage || "Are you sure you want to delete?"
          }
          onConfirm={async () => {
            return await actualApiCall(url, method, options).then(hideModal);
          }}
          onCancel={hideModal}
        />,
      );
    } else {
      return await actualApiCall(url, method, options);
    }
  };

  const actualApiCall = async (
    url: string,
    method: "PATCH" | "DELETE" | "POST" | "PUT",
    options: HandleApiWithFlashOptions,
  ): Promise<ResponsePayload> => {
    try {
      await fetcher(url, method, options.data);
      const messageId = uuidv4();
      addFlash({
        type: "success",
        content: options.successMessage,
        dismissible: true,
        dismissLabel: "Dismiss",
        onDismiss: () => removeFlash(messageId),
        id: messageId,
      });
      if (options.mutateKey) {
        await mutate(options.mutateKey);
      }
      return { success: true };
    } catch (error: any) {
      const messageId = uuidv4();
      const errorCode = error.status || 500; // assuming error object has a status property
      const errorMessage = error.message || "An error occurred!";
      addFlash({
        type: "error",
        content: options.errorMessage,
        dismissible: true,
        dismissLabel: "Dismiss",
        onDismiss: () => removeFlash(messageId),
        id: messageId,
      });
      return { success: false, errorCode, errorMessage };
    }
  };

  return { handleApiWithFlash };
};

interface ConfirmDeleteModalProps {
  onConfirm: () => void;
  onCancel: () => void;
  deleteMessage: string;
}

export const ConfirmDeleteModal: React.FC<ConfirmDeleteModalProps> = ({
  onConfirm,
  onCancel,
  deleteMessage,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const handleConfirm = async () => {
    setIsLoading(true);
    await onConfirm(); // Assumes onConfirm is async, or returns a promise.
    setIsLoading(false);
  };

  return (
    <Modal
      visible={true}
      onDismiss={onCancel}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={handleConfirm}
              loading={isLoading}
            >
              Confirm
            </Button>
          </SpaceBetween>
        </Box>
      }
      header={"This action cannot be undone"}
    >
      {deleteMessage}
    </Modal>
  );
};
