import React from 'react';
import useSWR from 'swr';
import fetcher from '../../utils/fetcher'
import Header from '@cloudscape-design/components/header';
import SpaceBetween from '@cloudscape-design/components/space-between';
import {ContentLayout} from '@cloudscape-design/components';
import MessagesTable from "./components/table";

export default function Messages() {
    const {data, isLoading, error} = useSWR('/messages', fetcher, {
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        refreshInterval: 0,
    });

    return (
        <ContentLayout
            header={
                <Header
                    variant="h1"
                    description="Manage message group configuration for VOICE and OMNI"
                >
                    Manage Groups
                </Header>
            }
        >
            <SpaceBetween size="m">
                <MessagesTable isLoading={isLoading} error={error} messages={data?.data}/>
            </SpaceBetween>
        </ContentLayout>
    );
}

