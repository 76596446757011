import { useEffect, useState } from 'react';
import DateRangePicker from '@cloudscape-design/components/date-range-picker';

export default function CxDateRangePicker({ setDateRangeValue, rangeSelectorMode, defaults, limitDuration = true }) {
  const [value, setValue] = useState({
    key: 'day',
    amount: '',
    unit: '',
    type: 'relative',
  });

  useEffect(() => {
    if (defaults && !defaults.startDate && !defaults.endDate) {
      setValue(defaults);
      return;
    }

    if (defaults === undefined || !defaults.startDate || !defaults.endDate) {
      return;
    }

    setValue({
      type: 'absolute',
      startDate: new Date(defaults.startDate).toISOString(),
      endDate: new Date(defaults.endDate).toISOString(),
    });
  }, [defaults]);

  const handleDateRangeChange = ({ detail }) => {
    const { value: rangeValue } = detail;
    console.log('handleDateRangeChange', rangeValue);
    setDateRangeValue(rangeValue);
    setValue(rangeValue);
  };

  const formatRelativeRange = (v) => {
    const { unit, amount, key } = v;

    if (key === 'previous-5-minutes') {
      return 'Last 5 minutes';
    }
    if (key === 'previous-30-minutes') {
      return 'Last 30 minutes';
    }
    if (key === 'previous-1-hour') {
      return 'Last 1 hour';
    }
    if (key === 'previous-6-hours') {
      return 'Last 6 hours';
    }
    if (key === 'last-3-days') {
      return 'Last 3 days';
    }
    if (key === 'last-7-days') {
      return 'Last 7 days';
    }
    if (key === 'day') {
      return 'Today';
    }
    if (key === 'week') {
      return 'Current week';
    }
    if (key === 'month') {
      return 'Current month';
    }
    if (key === 'year') {
      return 'Current year';
    }
    // Custom range
    if (unit) {
      return `Last ${amount} ${unit}s`;
    }
    return 'InvalidRange';
  };

  const isValidRange = (range) => {
    if (range.type === 'absolute') {
      const startDate = new Date(range.startDate);
      const endDate = new Date(range.endDate);

      // eslint-disable-next-line no-restricted-globals
      if (isNaN(startDate) || isNaN(endDate)) {
        return {
          valid: false,
          errorMessage: 'DateRangeIncomplete',
        };
      }

      if (startDate > endDate) {
        return {
          valid: false,
          errorMessage: 'DateRangeInvalid',
        };
      }

      if (limitDuration) {
        const maxDuration = 31 * 24 * 60 * 60 * 1000; // 31 days in milliseconds
        const duration = endDate.getTime() - startDate.getTime();

        if (duration > maxDuration) {
          return {
            valid: false,
            errorMessage: 'Date range exceeds 31 days',
          };
        }
      }
    }

    return { valid: true };
  };

  return (
    <>
      <DateRangePicker
        onChange={handleDateRangeChange}
        value={value}
        {...(rangeSelectorMode ? { rangeSelectorMode } : {})}
        relativeOptions={[
          {
            key: 'previous-5-minutes',
            amount: 5,
            unit: 'minute',
            type: 'relative',
          },
          {
            key: 'previous-30-minutes',
            amount: 30,
            unit: 'minute',
            type: 'relative',
          },
          {
            key: 'previous-1-hour',
            amount: 1,
            unit: 'hour',
            type: 'relative',
          },
          {
            key: 'previous-6-hours',
            amount: 6,
            unit: 'hour',
            type: 'relative',
          },
          {
            key: 'last-3-days',
            amount: 3,
            unit: 'day',
            type: 'relative',
          },
          {
            key: 'last-7-days',
            amount: 7,
            unit: 'day',
            type: 'relative',
          },
          {
            key: 'day',
            amount: '',
            unit: '',
            type: 'relative',
          },
          {
            key: 'week',
            amount: null,
            unit: null,
            type: 'relative',
          },
          {
            key: 'month',
            amount: null,
            unit: null,
            type: 'relative',
          },
          {
            key: 'year',
            amount: null,
            unit: null,
            type: 'relative',
          },
        ]}
        isValidRange={isValidRange}
        i18nStrings={{ formatRelativeRange }}
        placeholder={'Select data time ranges'}
      />
    </>
  );
}
