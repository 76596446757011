import * as React from "react";
import Select from "@cloudscape-design/components/select";
import tzList from "../../objects/tz";

interface Tz {
  label: string;
  value: string;
}

interface TimeZoneSelectProps {
  onChange: (tz: Tz) => void;
  value: Tz;
}

export const TimeZoneSelect: React.FC<TimeZoneSelectProps> = ({
  onChange,
  value,
}) => {
  const [selectedOption, setSelectedOption] = React.useState<Tz>(
    value || {
      label: "Europe/London",
      value: "Europe/London",
    },
  );

  // Update the selected option if the value prop changes
  React.useEffect(() => {
    if (value) {
      setSelectedOption(value);
    }
  }, [value]);

  const handleChange = (option: any) => {
    setSelectedOption(option);
    onChange(option); // calling the parent's onChange function
  };

  const options = tzList.map((tz) => ({
    label: tz.label,
    value: tz.value,
  }));

  return (
    <>
      <Select
        selectedOption={selectedOption}
        onChange={({ detail }) => handleChange(detail.selectedOption)}
        options={options}
      />
    </>
  );
};

export default TimeZoneSelect;
