import { SideNavigationProps } from '@cloudscape-design/components/side-navigation';
import { BreadcrumbGroupProps } from '@cloudscape-design/components/breadcrumb-group';

export type NavigationItem = SideNavigationProps.Item;

export const items: NavigationItem[] = [
  {
    type: 'section-group',
    title: 'Simplify',
    items: [
      {
        type: 'link',
        text: 'Messages',
        href: '/messages',
      },
      {
        type: 'link',
        text: 'Closures',
        href: '/closures',
      },
      {
        type: 'link',
        text: 'Dial Plan',
        href: '/dial-plan',
      },
    ],
  },
  { type: 'divider' },
  {
    type: 'section-group',
    title: 'Intuition',
    items: [
      {
        type: 'link',
        text: 'Visualizations',
        href: '/visualizations',
      },
      {
        type: 'link',
        text: 'Contact Search',
        href: '/contact-search',
      },
      {
        type: 'link',
        text: 'Utilities',
        href: '/utilities',
      },
    ],
  },
  { type: 'divider' },
  {
    type: 'section-group',
    title: 'xQ',
    items: [
      {
        type: 'link',
        text: 'Gen AI Chatbot',
        href: '/chatbot-testing',
      },
      {
        type: 'link',
        text: 'Documents',
        href: '/documents',
      },
      {
        type: 'link',
        text: 'Prompt Engineering',
        href: '/prompt-engineering',
      },
    ],
  },
  { type: 'divider' },
  {
    type: 'section-group',
    title: 'Administration',
    items: [
      {
        type: 'link',
        text: 'Settings',
        href: '/settings',
      },
      {
        type: 'link',
        text: 'User Admin',
        href: '/user-admin',
      },
      {
        type: 'link',
        text: 'Audit Log',
        href: '/audit-log',
      },
    ],
  },
];

type BreadcrumbItem = BreadcrumbGroupProps['items'][0];
type BreadcrumbMapping = {
  [key: string]: BreadcrumbItem[];
};

const breadcrumbMapping: BreadcrumbMapping = {
  '/': [{ text: 'Home', href: '/' }],
  '/audit-log': [{ text: 'Audit Log', href: '/audit-log' }],
  '/user-admin': [{ text: 'User Admin', href: '/user-admin' }],
  '/user-admin/create': [{ text: 'Create', href: '/user-admin/create' }],
  '/prompt-engineering': [{ text: 'Prompt Engineering', href: '/prompt-engineering' }],
  '/prompt-engineering/create': [{ text: 'Create', href: '/prompt-engineering/create' }],
  '/prompt-engineering/edit': [{ text: 'Edit', href: '/prompt-engineering/edit' }],
  '/dial-plan': [{ text: 'Dial Plan', href: '/dial-plan' }],
  '/dial-plan/create': [{ text: 'Create', href: '/dial-plan/create' }],
  '/dial-plan/edit': [{ text: 'Edit', href: '/dial-plan/edit' }],
  '/messages': [{ text: 'Message Groups', href: '/messages' }],
  '/messages/create': [{ text: 'Create', href: '/messages/create' }],
  '/messages/edit': [{ text: 'Edit', href: '/messages/edit' }],
  '/closures': [{ text: 'Closures', href: '/closures' }],
  '/closures/create': [{ text: 'Create', href: '/closures/create' }],
  '/closures/edit': [{ text: 'Edit', href: '/dial-plan/edit' }],
  '/contact-search': [{ text: 'Contact Search', href: '/contact-search' }],
  '/contact-search/view': [{ text: 'View', href: '/contact-search/view' }],
  '/settings': [{ text: 'View', href: '/settings' }],
  '/visualizations': [{ text: 'Visualizations', href: '/visualizations' }],
};

export function getBreadcrumbsForPath(path: string): BreadcrumbItem[] {
  const pathParts = path.split('/').filter((p) => p);

  const breadcrumbItems = [];
  let currentPath = '';

  for (const part of pathParts) {
    currentPath += `/${part}`;

    if (breadcrumbMapping[currentPath]) {
      breadcrumbItems.push(...breadcrumbMapping[currentPath]);
    } else if (part.startsWith('edit')) {
      const itemBreadcrumbs = [...breadcrumbMapping['/prompt-engineering/edit']];
      itemBreadcrumbs[2].href = currentPath;
      breadcrumbItems.push(...itemBreadcrumbs);
    }
  }

  return breadcrumbItems.length ? breadcrumbItems : [{ text: 'Home', href: '/' }];
}
