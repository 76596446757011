import React from 'react';
import {
  Container,
  Header,
  ContentLayout,
  Spinner,
  ColumnLayout,
  SpaceBetween,
  Grid,
} from '@cloudscape-design/components';
import fetcher from '../../../utils/fetcher';
import useSWR from 'swr';
import { useParams } from 'react-router-dom';
import { useFlash } from '../../../utils/hooks/useFlash';
import { v4 as uuidv4 } from 'uuid';
import ContactSummary from './components/contactSummary';
import ConversationSummary from './components/conversationSummary';
import ContactAnalysisVoice from './components/voice/contactAnalysisVoice';
import AutomatedQa from './components/shared/atomatedQa';
import sampleVoiceObject from './components/voice/sampleVoiceObject.json';

export default function ContactSearchView() {
  // Hooks
  const { contactId } = useParams();
  const { addFlash, removeFlash } = useFlash();

  const { data, isLoading, error } = useSWR(`/queries?templateType=getContactRecord&contactId=${contactId}`, fetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshInterval: 0,
  });

  if (error) {
    const messageId = uuidv4();
    addFlash({
      type: 'error',
      content: `Error retrieving ${contactId}`,
      dismissible: true,
      dismissLabel: 'Dismiss',
      onDismiss: () => removeFlash(messageId),
      id: messageId,
    });
  }

  if (!data || Object.keys(data).length === 0) {
    return (
      <Container header={<Header headingTagOverride="h3">Error</Header>}>
        <ColumnLayout columns={2} variant="text-grid">
          <SpaceBetween size="xs">Unable to find a record matching {JSON.stringify(contactId)}</SpaceBetween>
          <AutomatedQa />
        </ColumnLayout>
      </Container>
    );
  }

  // @ts-ignore
  return (
    <ContentLayout header={<Header variant="h1">{isLoading ? <Spinner /> : data?.data[0]?.contactId}</Header>}>
      {!error && (
        <Grid
          gridDefinition={[
            { colspan: { default: 12, xxs: 12 } },
            { colspan: { default: 12, xxs: 12 } },
            { colspan: { default: 12, xxs: 12 } },
            { colspan: { default: 12, xxs: 12 } },
          ]}
        >
          <ContactSummary contactObject={data?.data[0]} />
          <ConversationSummary />
          <AutomatedQa />
          {data?.data[0].channel === 'VOICE' && <ContactAnalysisVoice contactObject={sampleVoiceObject.data[0]} />}
        </Grid>
      )}
    </ContentLayout>
  );
}
