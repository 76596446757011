import React from 'react';
import { Header, LineChart } from '@cloudscape-design/components';
import { commonChartProps, dateTimeFormatter } from '../chart-commons';
import { WidgetConfig } from '../interfaces';
import GetWidgetData from '../../../../../../../utils/getWidgetData';
import { useItemContext } from '../../../../dashboardContext';
import { colorChartsStatusCritical, colorChartsStatusPositive } from '@cloudscape-design/design-tokens';

export const getUserFeedback: WidgetConfig = {
  definition: { defaultRowSpan: 4, defaultColumnSpan: 2, minRowSpan: 3 },
  data: {
    icon: 'lineChart',
    title: 'Chatbot Feedback',
    description: 'Thumbs up/down feedback from users of the chatbot (xQ)',
    header: GetConversationsHeader,
    content: GetUserFeedback,
    staticMinHeight: 200,
    multipleAllowed: false,
  },
};

function GetConversationsHeader() {
  return (
    <Header variant="h2" description="Volume of user feedback">
      Feedback
    </Header>
  );
}

interface IAggregate {
  thumbsUp: number;
  thumbsDown: number;
}

function transformDataForLineChart(apiData: any[]): { thumbsUp: any[]; thumbsDown: any[] } {
  if (!apiData || apiData.length === 0) {
    return { thumbsUp: [], thumbsDown: [] };
  }

  // Aggregate the data by interval_start
  const aggregateByInterval: Record<string, IAggregate> = {};

  apiData.forEach((entry) => {
    if (!aggregateByInterval[entry.interval_start]) {
      aggregateByInterval[entry.interval_start] = { thumbsUp: 0, thumbsDown: 0 };
    }

    if (entry.rating === '1') {
      aggregateByInterval[entry.interval_start].thumbsUp = Number(entry.count);
    } else if (entry.rating === '0') {
      aggregateByInterval[entry.interval_start].thumbsDown = Number(entry.count);
    }
  });

  // Transform the data for plotting
  const transformedDataUp = Object.keys(aggregateByInterval).map((interval) => ({
    x: new Date(interval),
    y: aggregateByInterval[interval].thumbsUp,
  }));

  const transformedDataDown = Object.keys(aggregateByInterval).map((interval) => ({
    x: new Date(interval),
    y: aggregateByInterval[interval].thumbsDown,
  }));

  // Sort data by date
  const sorter = (a: { x: Date }, b: { x: Date }) => a.x.getTime() - b.x.getTime();
  transformedDataUp.sort(sorter);
  transformedDataDown.sort(sorter);

  return { thumbsUp: transformedDataUp, thumbsDown: transformedDataDown };
}

export default function GetUserFeedback({ widgetId }: { widgetId: string }) {
  const { item } = useItemContext();
  const widget = item.data.widgets.find((widget: { id: string }) => widget.id === widgetId);

  const period = item.data.period;

  const { data, isLoading, error } = GetWidgetData({ widget, period });

  const { thumbsUp: transformedDataUp, thumbsDown: transformedDataDown } = transformDataForLineChart(data?.data);

  // Calculate xDomain
  const xMin = new Date(
    Math.min(transformedDataUp[0]?.x.getTime() ?? Infinity, transformedDataDown[0]?.x.getTime() ?? Infinity)
  );
  const xMax = new Date(
    Math.max(
      transformedDataUp[transformedDataUp.length - 1]?.x.getTime() ?? -Infinity,
      transformedDataDown[transformedDataDown.length - 1]?.x.getTime() ?? -Infinity
    )
  );

  const xDomain: readonly Date[] = [xMin, xMax];

  const allYValues = [...transformedDataUp.map((data) => data.y), ...transformedDataDown.map((data) => data.y)];

  const maxY = Math.max(...allYValues);
  const yUpperLimit: number = maxY * 1.2;

  return (
    <>
      <LineChart
        {...commonChartProps}
        hideFilter={true}
        fitHeight={true}
        statusType={isLoading ? 'loading' : error ? 'error' : 'finished'}
        height={150}
        series={[
          {
            title: 'Thumbs Up',
            type: 'line',
            color: colorChartsStatusPositive,
            data: transformedDataUp,
            valueFormatter: function (e: number): string {
              return e.toString();
            },
          },
          {
            title: 'Thumbs Down',
            type: 'line',
            color: colorChartsStatusCritical,
            data: transformedDataDown,
            valueFormatter: function (e: number): string {
              return e.toString();
            },
          },
        ]}
        yDomain={[0, yUpperLimit] as const}
        xDomain={xDomain}
        xScaleType="time"
        xTitle="Time (UTC)"
        yTitle="Count"
        ariaLabel="Count"
        ariaDescription={`Line chart showing count of user feedback scores`}
        i18nStrings={{
          ...commonChartProps.i18nStrings,
          filterLabel: 'Filter displayed data',
          filterPlaceholder: 'Filter data',
          xTickFormatter: dateTimeFormatter,
        }}
      />
    </>
  );
}
