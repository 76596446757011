import { useState, useEffect } from "react";
import _ from "lodash";

function useChangeDetector<T>(initialValue: T) {
  const [item, setItem] = useState<T>(initialValue);
  const [itemShadow, setItemShadow] = useState<T>(initialValue);
  const [changesDetected, setChangesDetected] = useState(false);

  useEffect(() => {
    const changesDetected = !_.isEqual(item, itemShadow);
    // console.log("changesDetected", changesDetected)
    // console.log("item", item)
    // console.log("itemShadow", itemShadow)
    setChangesDetected(changesDetected);
  }, [item, itemShadow]);

  return {
    item,
    setItem,
    itemShadow,
    setItemShadow,
    changesDetected,
  };
}

export default useChangeDetector;
