import React, { useContext, useEffect, useRef } from 'react';

import {
  ChatWebSocketContext,
  ChatWebSocketContextType,
  // @ts-ignore
} from '../contexts/chatWebSocketContext.tsx';

// @ts-ignore
import MessageBubble from './messageBubble.tsx';

export default function ChatBlock() {
  const { messages, onMessageState, ready } = useContext<ChatWebSocketContextType>(ChatWebSocketContext);

  // const [messages, setMessages] = useState<MessageInterfaceType | null>(null);
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Scroll to the bottom when new content is added
    if (divRef.current) {
      divRef.current.scrollTop = divRef.current.scrollHeight;
    }
  }, [messages, onMessageState]);

  const queryParams = new URLSearchParams(window.location.search);
  const chatWidgetHeight = queryParams.get('chatWidgetHeight') || '500px';
  let dateTime = new Date();

  return (
    <div className="message-container " ref={divRef} style={{ height: chatWidgetHeight, overflow: 'auto' }}>
      {ready && (
        <MessageBubble
          contentType="Intro"
          message="Hi, I am Jeni. I am a genAI chatbot.  Please ask me any questions about your documents that you have uploaded."
          state="Delivered"
          timeStamp={dateTime}
          messageId={''}
          className="fade-in-text"
        />
      )}
      {messages &&
        Array.from(messages.values()).map((message: any) => (
          <React.Fragment key={message.createdAt}>
            <MessageBubble
              contentType={message.messageType}
              message={message.message}
              state={message.state}
              timeStamp={message.createdAt}
              messageId={message.messageId}
            />
          </React.Fragment>
        ))}
      {onMessageState === 'IN_PROGRESS' && <div className="dot-flashing"></div>}
    </div>
  );
}
