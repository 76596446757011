/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useState } from "react";
import { Group } from "@visx/group";
import { hierarchy, Tree } from "@visx/hierarchy";
import { LinearGradient } from "@visx/gradient";
import { pointRadial } from "d3-shape";
import useForceUpdate from "./useForceUpdate";
import { arc as d3Arc } from "d3-shape";
import getLinkComponent from "./getLinkComponents";

interface TreeNode {
  name: string;
  value?: number;
  forwarded?: number;
  isExpanded?: boolean;
  children: TreeNode[];
  avgSentiment?: number;
  sentiments?: number[];
  depth?: number;
}

// const data: TreeNode = {
//     name: 'MainMenu',
//     value: 500,
//     forwarded: 400,
//     children: [
//         {
//             name: 'Sales',
//             value: 400,
//             forwarded: 200,
//             children: [
//                 {name: 'New Product', value: 47,},
//                 {name: 'Existing Order', value: 150,},
//                 {name: 'Re order', value: 132,},
//                 {
//                     name: 'Business Sales',
//                     value: 132,
//                     children: [
//                         {
//                             name: 'Fleet',
//                             value: 68,
//                         },
//                         {
//                             name: 'NonFleet',
//                             value: 64,
//                             children: [
//                                 {
//                                     name: 'Blue',
//                                     value: 24
//                                 },
//                                 {
//                                     name: 'Yellow',
//                                     value: 10
//                                 },
//                                 {
//                                     name: 'Green',
//                                     value: 30
//                                 },
//                             ],
//                         },
//                     ],
//                 },
//             ],
//         },
//         {
//             name: 'Returns',
//             value: 32,
//         },
//         {
//             name: 'Support',
//             value: 68,
//             children: [{
//                 name: 'IT',
//                 value: 24
//             }, {name: 'HR', value: 4}, {name: 'Finance', value: 40}],
//         },
//     ],
// };

// Test data and invocation
const root: TreeNode = {
  name: "Root",
  children: [],
};

const data = [
  [
    "Main Menu",
    "Sales",
    "New Product",
    "Queue",
    { name: "Agent", sentiment: 4.0 },
  ],
  [
    "Main Menu",
    "Sales",
    "New Product",
    "Queue",
    { name: "Agent", sentiment: 2.0 },
  ],
  ["Main Menu", "Sales", "New Product", "Queue", "CallBack"],
  ["Main Menu", "Sales", "New Product", "Queue"],
  ["Main Menu", "Sales", "New Product"],
  ["Main Menu", "Sales"],
  ["Main Menu", "Sales", "Refund"],
  ["Sales", "Refund"],
];

function insertJourney(
  tree: TreeNode[],
  journey: (string | { name: string; sentiment: number })[],
  depth = 0,
): void {
  if (journey.length === 0) {
    return;
  }

  const [first, ...rest] = journey;

  let nodeName = typeof first === "string" ? first : first.name;
  let sentimentScore = typeof first === "object" ? first.sentiment : undefined;

  let childNode = tree.find((child) => child.name === nodeName);

  if (!childNode) {
    childNode = {
      name: nodeName,
      value: 0,
      forwarded: 0,
      children: [],
      sentiments: [],
    };
    tree.push(childNode);
  }

  if (childNode) {
    childNode.isExpanded = depth >= 2;

    // Increment the value
    childNode.value = (childNode.value ?? 0) + 1;

    // If there are more items in the journey, go deeper
    if (rest.length > 0) {
      childNode.forwarded = (childNode.forwarded ?? 0) + 1;
      insertJourney(childNode.children, rest, depth + 1); // Increase depth here
    }
  }

  if (sentimentScore !== undefined && nodeName === "Agent") {
    if (!childNode.sentiments) {
      childNode.sentiments = [];
    }
    childNode.sentiments.push(sentimentScore);
    childNode.avgSentiment =
      childNode.sentiments.reduce((a, b) => a + b, 0) /
      childNode.sentiments.length;
  }
}

for (const journey of data) {
  insertJourney(root.children, journey); // The depth is automatically set to 0 by the default parameter
}

const finalTree = {
  name: "root",
  children: root.children,
};

const defaultMargin = { top: 30, left: 30, right: 30, bottom: 70 };

export type LinkTypesProps = {
  width: number;
  height: number;
  margin?: { top: number; right: number; bottom: number; left: number };
};

export default function IVRLinkTypeChart({
  width: totalWidth,
  height: totalHeight,
  margin = defaultMargin,
}: LinkTypesProps) {
  const [hasExpanded, setHasExpanded] = useState(false);
  const [layout, setLayout] = useState<string>("cartesian");
  const [orientation, setOrientation] = useState<string>("vertical");
  const [linkType, setLinkType] = useState<string>("diagonal");
  const [stepPercent, setStepPercent] = useState<number>(0.5);
  const forceUpdate = useForceUpdate();

  const innerWidth = totalWidth - margin.left - margin.right;
  const innerHeight = totalHeight - margin.top - margin.bottom;

  let origin: { x: number; y: number };
  let sizeWidth: number;
  let sizeHeight: number;

  if (layout === "polar") {
    origin = {
      x: innerWidth / 2,
      y: innerHeight / 2,
    };
    sizeWidth = 2 * Math.PI;
    sizeHeight = Math.min(innerWidth, innerHeight) / 2;
  } else {
    origin = { x: 0, y: 0 };
    if (orientation === "vertical") {
      sizeWidth = innerWidth;
      sizeHeight = innerHeight;
    } else {
      sizeWidth = innerHeight;
      sizeHeight = innerWidth;
    }
  }

  const LinkComponent = getLinkComponent({ layout, linkType, orientation });

  return totalWidth < 10 ? null : (
    <div>
      <svg width={totalWidth} height={totalHeight}>
        <LinearGradient id="links-gradient" />
        <rect
          width={totalWidth}
          height={totalHeight - 30}
          rx={14}
          fill="#fff"
        />
        <Group top={margin.top} left={margin.left}>
          <Tree
            root={hierarchy<TreeNode>(finalTree, (d: TreeNode) =>
              d.isExpanded ? null : d.children,
            )}
            size={[sizeWidth, sizeHeight]}
            separation={(a, b) => (a.parent === b.parent ? 1 : 0.5) / a.depth}
          >
            {(tree) => (
              <Group top={origin.y} left={origin.x}>
                {tree.links().map((link, i) => (
                  <LinkComponent
                    key={i}
                    data={link}
                    percent={stepPercent}
                    stroke="rgb(254,110,58,0.6)"
                    strokeWidth="2"
                    fill="none"
                  />
                ))}

                {tree.descendants().map((node, key) => {
                  const width = 40;
                  const height = 20;

                  let top: number;
                  let left: number;
                  if (layout === "polar") {
                    const [radialX, radialY] = pointRadial(node.x, node.y);
                    top = radialY;
                    left = radialX;
                  } else if (orientation === "vertical") {
                    top = node.y;
                    left = node.x;
                  } else {
                    top = node.x;
                    left = node.y;
                  }

                  let percentForwarded = 0;
                  if (node.data.value && node.data.forwarded) {
                    percentForwarded =
                      (node.data.forwarded / node.data.value) * 100;
                  }

                  const arc = d3Arc()
                    .innerRadius(20)
                    .outerRadius(25)
                    .startAngle(0)
                    .endAngle((percentForwarded / 100) * 2 * Math.PI);

                  const arcData = {
                    startAngle: 0,
                    endAngle: (percentForwarded / 100) * 2 * Math.PI,
                    innerRadius: 20, // Match the innerRadius of the arc generator
                    outerRadius: 25, // Match the outerRadius of the arc generator
                  };

                  return (
                    <Group top={top} left={left} key={key}>
                      {node.depth === 0 && (
                        <>
                          <circle
                            r={20}
                            fill="white"
                            onClick={() => {
                              node.data.isExpanded = !node.data.isExpanded;
                              console.log(node);
                              forceUpdate();
                            }}
                          />
                          <path d={arc(arcData) ?? undefined} fill="#03c0dc" />
                        </>
                      )}
                      {node.depth !== 0 && (
                        <>
                          <circle
                            r={20}
                            fill="white"
                            onClick={() => {
                              node.data.isExpanded = !node.data.isExpanded;
                              console.log(node);
                              forceUpdate();
                            }}
                          />
                          <path d={arc(arcData) ?? undefined} fill="#03c0dc" />
                        </>
                      )}

                      <text
                        dy="0.3em"
                        fontSize={14}
                        fontFamily="Arial"
                        textAnchor="middle"
                        style={{ pointerEvents: "none" }}
                        fill={node.children ? "black" : "black"}
                      >
                        {node.data.name}
                      </text>

                      <text
                        dy="1.2em"
                        y={2} // M
                        fontSize={14}
                        fontFamily="Arial"
                        textAnchor="middle"
                        style={{ pointerEvents: "none" }}
                        fill={node.children ? "blue" : "blue"}
                      >
                        {node.data.value}
                      </text>

                      {node.data.name === "Agent" &&
                        node.data.avgSentiment !== undefined && (
                          <>
                            {/* Background color based on sentiment score */}
                            <rect
                              x={-50} // Half of the text box width, adjust as needed
                              y={25} // Position from the top of the circle, adjust as needed
                              width={100} // Text box width
                              height={20} // Text box height
                              fill={
                                node.data.avgSentiment < -1.5
                                  ? "red"
                                  : node.data.avgSentiment < 1.5
                                  ? "blue"
                                  : "green"
                              }
                            />
                            <text
                              dy="2.2em"
                              y={8}
                              fontSize={14}
                              fontFamily="Arial"
                              textAnchor="middle"
                              style={{ pointerEvents: "none" }}
                              fill="white" // Changed to white text
                            >
                              {`Sentiment: ${node.data.avgSentiment.toFixed(
                                2,
                              )}`}
                            </text>
                          </>
                        )}
                    </Group>
                  );
                })}
              </Group>
            )}
          </Tree>
        </Group>
      </svg>
    </div>
  );
}
