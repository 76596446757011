import React, {useState} from 'react';
import {Button, SpaceBetween} from "@cloudscape-design/components";
import DateRangePicker, {DateRangePickerProps} from "@cloudscape-design/components/date-range-picker";
import {formatDateTimePickerApi} from "../../../utils/timeUtils";
import PropertyFilters from "./PropertyFilters";

type ChangeDetail = {
    value: DateRangePickerProps.Value | null;
};

// @ts-ignore
export default function SearchBar({isLoading, changesMade, setChangesMade, setDateRange, setSearchFilters, setSubmitSearch}) {
    const [value, setValue] = useState<any | null>({"key": "previous-6-hours", "amount": 6, "unit": "hour", "type": "relative"});

    const handleDateChange = (event: {
        detail: ChangeDetail
    }) => {
        const {value} = event.detail;
        if (value !== null) { // Only set the value if it's not null
            setValue(value);
            setChangesMade(true)
            setDateRange(formatDateTimePickerApi(value));
        }
    };

    return (
        <SpaceBetween size="xs">
            <DateRangePicker
                onChange={handleDateChange}
                value={value}
                relativeOptions={[
                    {
                        key: "previous-5-minutes",
                        amount: 5,
                        unit: "minute",
                        type: "relative"
                    },
                    {
                        key: "previous-30-minutes",
                        amount: 30,
                        unit: "minute",
                        type: "relative"
                    },
                    {
                        key: "previous-1-hour",
                        amount: 1,
                        unit: "hour",
                        type: "relative"
                    },
                    {
                        key: "previous-6-hours",
                        amount: 6,
                        unit: "hour",
                        type: "relative"
                    }
                ]}
                isValidRange={range => {
                    if (!range) {
                        return {
                            valid: false,
                            errorMessage: "The range provided is invalid."
                        };
                    }
                    if (range.type === "absolute") {
                        if (!range.startDate || !range.endDate) {
                            return {
                                valid: false,
                                errorMessage: "The selected date range is incomplete. Select a start and end date for the date range."
                            };
                        }
                        const [startDateWithoutTime] = range.startDate.split("T");
                        const [endDateWithoutTime] = range.endDate.split("T");

                        // This condition is optional since you're not really using the date without time in this function
                        if (!startDateWithoutTime || !endDateWithoutTime) {
                            return {
                                valid: false,
                                errorMessage: "The selected date range is invalid. Please ensure the dates are in a proper format."
                            };
                        }
                        if (new Date(range.startDate) > new Date(range.endDate)) {
                            return {
                                valid: false,
                                errorMessage: "The selected date range is invalid. The start date must be before the end date."
                            };
                        }
                    }
                    return {valid: true};
                }}
                placeholder="Filter by a date and time range"
            />


            <PropertyFilters setSearchFilters={setSearchFilters} setChangesMade={setChangesMade}/>
            <Button
                onClick={() => setSubmitSearch(true)}
                loading={isLoading}
                disabled={!changesMade}
            >Search</Button>
        </SpaceBetween>
    )
}
