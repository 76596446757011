// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0
import React from 'react';
import ButtonDropdown from '@cloudscape-design/components/button-dropdown';
import BoardItem from '@cloudscape-design/board-components/board-item';
import {WidgetDataType} from '../widgets/interfaces';
import {boardItemI18nStrings} from '../../i18n-strings';

interface BaseWidgetProps {
    config: WidgetDataType;
    onRemove: () => void;
    removeConfirmationText?: string;
    actions?: ReadonlyArray<{
        text: string;
        onClick: () => void
    }>;
    id: string;
}

export function ConfigurableWidget({config, id, onRemove, actions = [], removeConfirmationText}: BaseWidgetProps) {

    if (!config) {
        return null
    }

    const Content = config.content;

    return (
        <>
            <BoardItem
                header={<config.header/>}
                disableContentPaddings={config.disableContentPaddings}
                i18nStrings={boardItemI18nStrings}
                settings={
                    <ButtonDropdown
                        items={[
                            ...actions.map(action => ({id: action.text, text: action.text})),
                            {id: 'remove', text: 'Remove'},
                        ]}
                        ariaLabel="Widget settings"
                        variant="icon"
                        onItemClick={(event) => {
                            if (event.detail.id === 'remove') {
                                onRemove();
                            } else {
                                const actionMatch = actions.find(action => action.text === event.detail.id)!;
                                actionMatch.onClick();
                            }
                        }}
                    />
                }
                footer={config.footer && <config.footer/>}
            >
                <Content widgetId={id}/>
            </BoardItem>
        </>
    );
}
