import * as React from "react";
import Multiselect from "@cloudscape-design/components/multiselect";
import { useItemContext } from "../../routes/visualizations/view/dashboardContext";
import { useMemo } from "react";

type OptionDefinition = {
  label: string;
  value: string;
  description: string;
};

interface QueuePickerProps {
  widgetId: string;
}

const ChannelPicker: React.FC<QueuePickerProps> = ({ widgetId }) => {
  const { item, setItem } = useItemContext();
  const [selectedOptions, setSelectedOptions] = React.useState<
    OptionDefinition[]
  >([]);

  const staticOptions: OptionDefinition[] = useMemo(
    () => [
      { label: "All", value: "*", description: "All Channels" },
      { label: "VOICE", value: "VOICE", description: "Voice Channel" },
      { label: "CHAT", value: "CHAT", description: "Chat Channel" },
      { label: "TASK", value: "TASK", description: "Task Channel" },
    ],
    [],
  );

  React.useEffect(() => {
    const getInitialSelectedOptions = (): OptionDefinition[] => {
      const currentWidget = item.data.widgets.find(
        (widget: { id: string }) => widget.id === widgetId,
      );
      if (
        currentWidget &&
        currentWidget.filters &&
        currentWidget.filters.channels
      ) {
        if (currentWidget.filters.channels.includes("*")) {
          return [{ label: "All", value: "*", description: "All Channels" }];
        } else {
          return currentWidget.filters.channels.map((channel: string) => {
            const option = staticOptions.find((opt) => opt.value === channel);
            return (
              option || { label: channel, value: channel, description: "" }
            );
          });
        }
      }
      return [];
    };

    setSelectedOptions(getInitialSelectedOptions);
  }, [item, staticOptions, widgetId]);

  const handleSelectionChange = (selectedOpts: OptionDefinition[]) => {
    if (selectedOpts.some((opt) => opt.value === "*")) {
      setSelectedOptions([
        { label: "All", value: "*", description: "All Channels" },
      ]);
      updateWidgetChannels(["*"]);
    } else {
      setSelectedOptions(selectedOpts);
      updateWidgetChannels(selectedOpts.map((opt) => opt.value));
    }
  };

  const updateWidgetChannels = (selectedChannels: string[]) => {
    setItem(
      (prevItem: {
        data: {
          widgets: any[];
        };
      }) => {
        const updatedWidgets = prevItem.data.widgets.map((widget) => {
          if (widget.id === widgetId) {
            return {
              ...widget,
              filters: {
                ...widget.filters,
                channels: selectedChannels,
              },
            };
          }
          return widget;
        });

        return {
          ...prevItem,
          data: {
            ...prevItem.data,
            widgets: updatedWidgets,
          },
        };
      },
    );
  };

  return (
    <div>
      <Multiselect
        filteringType="auto"
        selectedOptions={selectedOptions}
        onChange={({ detail }) =>
          handleSelectionChange(detail.selectedOptions as OptionDefinition[])
        }
        options={staticOptions}
        placeholder="Select channels"
        tokenLimit={2}
      />
    </div>
  );
};

export default ChannelPicker;
