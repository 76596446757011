import React from "react";
import { ColumnLayout, Container, Header } from "@cloudscape-design/components";
import Beta from "../../../../../components/commons/beta";
import QaBarchart from "./qaBarchart";

export default function AutomatedQs() {
  return (
    <Container
      header={
        <Header headingTagOverride="h3">
          Automated QA <Beta type={"preview"} />
        </Header>
      }
    >
      <ColumnLayout columns={1} variant="text-grid">
        <QaBarchart />
      </ColumnLayout>
    </Container>
  );
}
