import React, { ReactNode } from "react";
import useSWR from "swr";
import {
  Box,
  ColumnLayout,
  Container,
  SpaceBetween,
  SplitPanel,
  Spinner,
} from "@cloudscape-design/components";
import fetcher from "../../../utils/fetcher";

interface AuditSplitPanelDetailsProps {
  selectedItems: any;
}

interface ValueWithLabelProps {
  label: string;
  children: ReactNode;
}

const ValueWithLabel: React.FC<ValueWithLabelProps> = ({ label, children }) => (
  <div>
    <Box variant="awsui-key-label">{label}</Box>
    <div>{children}</div>
  </div>
);

const DialPlanSplitPanelDetails: React.FC<AuditSplitPanelDetailsProps> = ({
  selectedItems,
}) => {
  const { data, error, isLoading } = useSWR(
    `/dialPlans/${selectedItems.detail[0].feature}`,
    fetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshInterval: 0,
    },
  );

  if (isLoading || !data || error) {
    return (
      <SplitPanel header={`Closure Group:`} closeBehavior="collapse">
        {error ? <>{error.info.message}</> : <Spinner />}
      </SplitPanel>
    );
  }

  let event = selectedItems?.detail[0];
  let Payload = data?.data;

  console.log("Payload", Payload);

  return (
    <SplitPanel
      header={`Dial Plan: ${event?.feature}`}
      closeBehavior="collapse"
    >
      <Container>
        <ColumnLayout columns={2} variant="text-grid">
          <SpaceBetween size="l">
            <ValueWithLabel label="Dial Plan Type">
              {Payload?.data?.type}
            </ValueWithLabel>
            <ValueWithLabel label="Resource Name">
              {Payload?.feature}
            </ValueWithLabel>
            <ValueWithLabel label="Description">
              {Payload?.description}
            </ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size="l">
            {Payload?.data?.prefix && (
              <ValueWithLabel label="Prefix">
                {Payload?.data?.prefix}
              </ValueWithLabel>
            )}
          </SpaceBetween>
        </ColumnLayout>
      </Container>
    </SplitPanel>
  );
};

export default DialPlanSplitPanelDetails;
