import React from 'react';
import { Header, LineChart } from '@cloudscape-design/components';
import { commonChartProps, dateTimeFormatter } from '../chart-commons';
import { WidgetConfig } from '../interfaces';
import GetWidgetData from '../../../../../../../utils/getWidgetData';
import { useItemContext } from '../../../../dashboardContext';

export const getMessages: WidgetConfig = {
  definition: { defaultRowSpan: 4, defaultColumnSpan: 2, minRowSpan: 3 },
  data: {
    icon: 'lineChart',
    title: 'Chatbot Message Volumes',
    description: 'Volume of chat messages (xQ)',
    header: GetConversationsHeader,
    content: GetMessages,
    staticMinHeight: 200,
    multipleAllowed: false,
  },
};

function GetConversationsHeader() {
  return (
    <Header variant="h2" description="Volume of chat messages">
      Chatbot Messages
    </Header>
  );
}

function transformDataForLineChart(apiData: any[]) {
  if (!apiData || apiData.length === 0) {
    return [];
  }

  return apiData.map((row) => ({
    x: new Date(row.interval_start),
    y: parseInt(row.count, 10),
  }));
}

export default function GetMessages({ widgetId }: { widgetId: string }) {
  const { item } = useItemContext();
  const widget = item.data.widgets.find((widget: { id: string }) => widget.id === widgetId);

  const period = item.data.period;

  const { data, isLoading, error } = GetWidgetData({ widget, period });

  const transformedData = transformDataForLineChart(data?.data);

  // Calculate yDomain based on data
  const yMax = transformedData.reduce((max, d) => Math.max(max, d.y), 0);
  const yDomain = [0, yMax * 1.1]; // adding 10% padding to the maximum value

  // Calculate xDomain based on data
  const xMin = transformedData.length > 0 ? transformedData[0].x : new Date();
  const xMax = transformedData.length > 0 ? transformedData[transformedData.length - 1].x : new Date();
  const xDomain = [xMin, xMax];

  return (
    <LineChart
      {...commonChartProps}
      hideFilter={true}
      fitHeight={true}
      height={150}
      statusType={isLoading ? 'loading' : error ? 'error' : 'finished'}
      series={[
        {
          title: 'Messages',
          type: 'line',
          data: transformedData,
          valueFormatter: function (e: number): string {
            return e.toString(); // converting number to string
          },
        },
      ]}
      yDomain={yDomain}
      xDomain={xDomain}
      xScaleType="time"
      xTitle="Time (UTC)"
      yTitle="Messages Received"
      ariaLabel="Messages Received"
      ariaDescription={`Line chart showing messages received`}
      i18nStrings={{
        ...commonChartProps.i18nStrings,
        filterLabel: 'Filter displayed data',
        filterPlaceholder: 'Filter data',
        xTickFormatter: dateTimeFormatter,
      }}
    />
  );
}
