import React from 'react';

import Header from '@cloudscape-design/components/header';

import { ContentLayout, ExpandableSection, Grid, SpaceBetween, StatusIndicator } from '@cloudscape-design/components';
import Container from '@cloudscape-design/components/container';
import { useAppSession } from '../../utils/hooks/sessionContext';
import { Link } from 'react-router-dom';

function getGreeting() {
  const currentHour = new Date().getHours();

  if (currentHour >= 5 && currentHour < 12) {
    return 'Good morning';
  } else if (currentHour >= 12 && currentHour < 18) {
    return 'Good afternoon';
  } else {
    return 'Good evening';
  }
}

export default function Home() {
  const appSession = useAppSession();
  const userProfile = appSession?.userProfile;

  return (
    <ContentLayout>
      <SpaceBetween size={'xl'}>
        <Container
          header={
            <Header variant="h2">
              {getGreeting()}, {userProfile?.given_name}
            </Header>
          }
        >
          Welcome to Cato for Amazon Connect.
        </Container>
        <Grid gridDefinition={[{ colspan: { default: 12, s: 8 } }, { colspan: { default: 12, s: 4 } }]}>
          <Container
            header={
              <Header variant="h2" description="Simplify">
                Getting started
              </Header>
            }
            footer={
              <ExpandableSection headerText="Loving Cato and what to learn more" variant="footer">
                Cato delivers in these areas.
                <ul>
                  <li>
                    <b>Simplify</b> for operational efficiency
                  </li>
                  <li>
                    <b>Intuition</b> visualise your real-time and historical Amazon Connect data.
                  </li>
                  <li>
                    <b>Compliance Recording</b> extend the search and playback of Voice and Omni contacts to decades
                  </li>
                  <li>
                    <b>xQ</b> provides a private document Generative AI chatbot using Large Language Modules from AWS
                  </li>
                </ul>
                To learn more about Cato head over to our product page{' '}
                <a href="https://www.cx.studio/cato" target="_blank" rel="noopener noreferrer">
                  https://www.cx.studio/cato
                </a>{' '}
                and contact us.
              </ExpandableSection>
            }
          >
            <h3>Messages</h3>Use Cato to hold all your text to speech messages. Set situational messages and make
            instant changes to your customer experiences. Each message support multi language.
            <h3>Closures</h3>Cato takes the hassle out of managing opening hours for your contact centre. Closures
            supports
            <ul>
              <li>Hours of operation</li>
              <li>Planned closures</li>
              <li>Recurring closures</li>
              <li>Unplanned closures</li>
              <li>Multi languages</li>
              <li>Timezones</li>
            </ul>
            <h3>Dial Plan</h3>Dial plan provides and easy to use VIP and Blocked callers solution (VOICE). React to live
            changes within your contact centre. Dial plans can also be used to restrict outbound calling by group(s)
            <h3>Ready?</h3>
            Head over to the <Link to="/settings">settings</Link> page to create your first message and closure group
            and download our sample contact flow
          </Container>
          <SpaceBetween size={'m'}>
            <Container
              header={
                <Header variant="h2" description="Get the most our of Cato">
                  Hints and tips
                </Header>
              }
            >
              <p>
                <StatusIndicator type="info" />
                <b>Sample contact flow</b> Our sample inbound contact flows is a great place to learn how to use Cato
                Simplify
              </p>
              <p>
                <StatusIndicator type="info" />
                <b>Mobile Ready</b> Need to make changes on the go? Try Cato on a mobile or tablet.
              </p>
              <p>
                <StatusIndicator type="info" />
                <b>Closures</b> You can create multi layer closures to close whole departments / group / countries
              </p>
              <p>
                <StatusIndicator type="info" />
                <b>Message</b> Support multi-languages, you can use our translation service and tweak the results to
                your need.
              </p>
            </Container>
            <Container
              header={
                <Header variant="h2" description="News and insights from CX Studio and AWS.">
                  Recent News
                </Header>
              }
            >
              Coming soon....
            </Container>
          </SpaceBetween>
        </Grid>
      </SpaceBetween>
    </ContentLayout>
  );
}
