import React, { useCallback, useState } from "react";
import {
  ColumnLayout,
  Container,
  Header,
  ContentLayout,
  SpaceBetween,
  Textarea,
  Form,
  Input,
  Button,
  FormField,
} from "@cloudscape-design/components";
import { useNavigate } from "react-router-dom";
import useChangeDetector from "../../../utils/useChangeDetector";
import { useApiWithFlash } from "../../../utils/hooks/useApiWithFlash";

export default function VisualizationsCreate() {
  // state to control changes made
  const { item, setItem } = useChangeDetector<any>({});

  // state
  const [resourceError, setResourceError] = useState<boolean>(true);
  const [resourceErrorMessage, setResourceErrorMessage] = useState<string>("");
  const [formSubmited, setFormSubmited] = useState<boolean>(false);

  // Hooks
  const navigate = useNavigate();
  const { handleApiWithFlash } = useApiWithFlash();

  // Functions to handle form changes
  const handleDropdownChange = useCallback(
    (field: any, value: any) => {
      setItem((prev: any) => ({ ...prev, [field]: value }));
    },
    [setItem],
  );

  const validateResource = (value: string) => {
    // Define patterns
    const generalPattern = /^[a-zA-Z0-9+_-]+$/;

    if (value.length >= 0) {
      if (!generalPattern.test(value)) {
        setResourceError(true);
        setResourceErrorMessage("Only alphanumeric, - _ and + supported");
      } else {
        setResourceError(false);
        setResourceErrorMessage("");
      }
    }
  };

  const handleResourceChange = (value: string) => {
    validateResource(value);
    handleDropdownChange("feature", value);
  };

  const handleDescriptionChange = (value: string) => {
    handleDropdownChange("description", value);
  };

  // Handle form submission
  async function handleSubmitForm() {
    setFormSubmited(true);

    let payload = {
      description: item.description || "",
    };

    const response = await handleApiWithFlash(
      `/dashboards/${item.feature}`,
      "POST",
      {
        successMessage: `Successfully created dashboard  ${item.feature}`,
        errorMessage: `Error creating dashboard ${item.feature}`,
        mutateKey: `/dashboards/${item.feature}`,
        data: payload,
      },
    );

    if (response?.success) {
      navigate(`/visualizations/view/${item.feature}`);
    } else {
      navigate(`/visualizations`);
    }
  }

  return (
    <ContentLayout header={<Header variant="h1"></Header>}>
      <Container
        header={
          <Header
            variant="h2"
            description="Use this form to create a new dashboard. You will add widgets on the next screen"
          >
            Create a new Dashboard
          </Header>
        }
      >
        <form
          onSubmit={(e) => {
            console.log(e);
            e.preventDefault();
            // const value = e.target.elements.prompt.value; // Accessing value of the input/textarea with name="prompt"
            handleSubmitForm();
          }}
        >
          <Form
            variant="embedded"
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  formAction="none"
                  variant="link"
                  onClick={() => navigate("/messages")}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  loading={formSubmited}
                  disabled={resourceError}
                >
                  Submit
                </Button>
              </SpaceBetween>
            }
          >
            <SpaceBetween direction="vertical" size="xl">
              <ColumnLayout columns={2} variant="text-grid">
                <FormField
                  constraintText={"Only alphanumeric, - _ and + supported"}
                  label={"Provide a dashboard name"}
                  errorText={resourceErrorMessage}
                >
                  <Input
                    onChange={({ detail }) =>
                      handleResourceChange(detail.value)
                    }
                    value={item?.feature}
                  />
                </FormField>
                <FormField label="Description">
                  <Textarea
                    onChange={({ detail }) =>
                      handleDescriptionChange(detail.value)
                    }
                    value={item?.description}
                  />
                </FormField>
              </ColumnLayout>
            </SpaceBetween>
          </Form>
        </form>
      </Container>
    </ContentLayout>
  );
}
