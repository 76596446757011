import React, {useState} from 'react';
import PropertyFilter from '@cloudscape-design/components/property-filter';
import fetcher from "../../../utils/fetcher";

// eslint-disable-next-line react/prop-types
export default function PropertyFilters({setChangesMade, setSearchFilters}) {
    const [data, setData] = useState([]);
    const [status, setStatus] = useState('pending');
    const [selectedItems, setSelectedItems] = useState([]);
    const [query, setQuery] = React.useState({
        tokens: [],
        operation: 'and',
    });

    const filteringProperties = [
        {
            key: 'ccr.agentARN',
            tokenConfigKey: 'userSummary',
            tokenValue: 'Username',
            tokenSearchValue: 'Arn',
            operators: ['=', '!='],
            propertyLabel: 'AgentUsername',
            groupValuesLabel: 'AgentUsernameValue',
        },
        {
            key: 'ccr.agentAfterContactWorkDuration',
            operators: ['=', '!=', '>', '<', '<=', '>='],
            propertyLabel: 'AgentACWDuration',
            groupValuesLabel: 'AgentACWDurationValue',
        },
        {
            key: 'ccr.agentConnectionAttempts',
            operators: ['=', '!=', '>', '<', '<=', '>='],
            propertyLabel: 'AgentConnectAttempts',
            groupValuesLabel: 'AgentConnectAttemptsValue',
        },
        {
            key: 'ccr.agentLongestHoldDuration',
            operators: ['=', '!=', '>', '<', '<=', '>='],
            propertyLabel: 'AgentLongestHoldDuration',
            groupValuesLabel: 'AgentLongestHoldDurationValue',
        },
        {
            key: 'ccr.agentHierarchyGroups',
            tokenConfigKey: 'userHierarchy',
            tokenValue: 'HierarchyPath',
            tokenSearchValue: 'Arn',
            operators: ['=', '!='],
            propertyLabel: 'AgentHierarchy',
            groupValuesLabel: 'AgentHierarchyValues',
        },
        {
            key: 'ccr.agentRoutingProfileArn',
            tokenConfigKey: 'routingProfiles',
            tokenValue: 'Name',
            tokenSearchValue: 'Arn',
            operators: ['=', '!='],
            propertyLabel: 'AgentRoutingProfile',
            groupValuesLabel: 'AgentRoutingProfileValues',
        },
        {
            key: 'ccr.agentNumberOfHolds',
            operators: ['=', '!=', '>', '<', '<=', '>='],
            propertyLabel: 'AgentNumberOfHolds',
            groupValuesLabel: 'AgentNumberOfHoldsValue',
        },
        {
            key: 'ccr.attributes',
            operators: ['=', '!='],
            propertyLabel: 'Attributes',
            groupValuesLabel: 'AttributesValue',
        },
        {
            key: 'ccr.channel',
            operators: ['=', '!='],
            propertyLabel: 'Channel',
            groupValuesLabel: 'ChannelValues',
        },
        {
            key: 'ccr.contactId',
            operators: ['='],
            propertyLabel: 'ContactID',
            groupValuesLabel: 'ContactIDValue',
        },
        {
            key: 'ccl.languageCode',
            operators: ['='],
            propertyLabel: 'LanguageCode',
            groupValuesLabel: 'LanguageCodeValue',
        },
        {
            key: 'ccl.conversationCharacteristics.AGENT',
            operators: ['=', '!=', '>', '<', '<=', '>='],
            propertyLabel: 'AgentSentiment',
            groupValuesLabel: 'AgentSentimentValue',
        },
        {
            key: 'ccl.conversationCharacteristics.CUSTOMER',
            operators: ['=', '!=', '>', '<', '<=', '>='],
            propertyLabel: 'CustomerSentiment',
            groupValuesLabel: 'CustomerSentimentValue',
        },
        {
            key: 'ccl.categories',
            operators: ['=', '!='],
            propertyLabel: 'Categories',
            groupValuesLabel: 'CategoriesValue',
        },
        {
            key: 'ccr.agentCustomerHoldDuration',
            operators: ['=', '!=', '>', '<', '<=', '>='],
            propertyLabel: 'CustomerHoldDuration',
            groupValuesLabel: 'CustomerHoldDurationValue',
        },
        {
            key: 'ccr.customerEndpointAddress',
            operators: ['='],
            propertyLabel: 'CustomerPhoneNumber',
            groupValuesLabel: 'CustomerPhoneNumberValue',
        },
        {
            key: 'ccr.disconnectReason',
            operators: ['=', '!='],
            propertyLabel: 'DisconnectedReason',
            groupValuesLabel: 'DisconnectedReasonValues',
        },
        {
            key: 'ccr.initiationMethod',
            operators: ['=', '!='],
            propertyLabel: 'InitiationMethod',
            groupValuesLabel: 'InitiationMethodValues',
        },
        {
            key: 'ccr.queueARN',
            tokenConfigKey: 'queues',
            tokenValue: 'Name',
            tokenSearchValue: 'Arn',
            operators: ['=', '!='],
            propertyLabel: 'Queue',
            groupValuesLabel: 'QueueValues',
        },
        {
            key: 'ccr.queueDuration',
            operators: ['=', '!=', '>', '<', '<=', '>='],
            propertyLabel: 'QueueDuration',
            groupValuesLabel: 'QueueDurationValue',
        },
        {
            key: 'ccr.systemEndpointAddress',
            operators: ['='],
            tokenConfigKey: 'systemPhoneNumbers',
            tokenValue: 'PhoneNumber',
            tokenSearchValue: 'PhoneNumber',
            propertyLabel: 'SystemPhoneNumber',
            groupValuesLabel: 'SystemPhoneNumberValues',
        },
        {
            key: 'ccr.voiceIdResult',
            operators: ['='],
            propertyLabel: 'VoiceIDResult',
            groupValuesLabel: 'VoiceIDResultValues',
        },
    ];

    const getStateLabel = (key) => key;

    // Triggered when user selects a property token
    const handleLoadItems = async ({detail: {filteringProperty}}) => {
        if (filteringProperty === undefined || !filteringProperty.tokenConfigKey) {
            setStatus('finished');
            return;
        }
        setStatus('loading');

        const endpoint = `/instanceConfig/${filteringProperty.tokenConfigKey}`;
        try {
            const response = await fetcher(endpoint);
            const matchedFilteringProperty = filteringProperties.find(
                (property) => property.key === filteringProperty.key
            );

            const res = response?.data?.map((item) => ({
                propertyKey: filteringProperty.key,
                value: item[filteringProperty.tokenValue],
                searchValue: item[matchedFilteringProperty?.tokenSearchValue],
                label: getStateLabel(item[filteringProperty.tokenValue]),
            }));
            setSelectedItems((prevItems) => [
                ...prevItems,
                ...res.filter((item) => !prevItems.some((prevItem) => prevItem.value === item.value)),
            ]);
            setData(res);
        } catch (err) {
            setStatus('error');
        }
        setStatus('finished');
    };

    const handleQueryUpdates = (detail) => {
        const updatedDetail = {...detail};

        updatedDetail.tokens = detail.tokens.map((token) => {
            const filteringProperty = filteringProperties.find(
                (property) => property.key === token.propertyKey
            );
            if (filteringProperty) {
                const item = selectedItems.find((i) => i.label === token.value);

                if (item) {
                    return {...token, value: item.searchValue};
                }
            }

            return token;
        });
        setQuery(detail)
        setChangesMade(true)
        setSearchFilters(updatedDetail);
    };

    const channelOptions = ['VOICE', 'CHAT', 'TASK'].map((channel) => ({
        propertyKey: 'ccr.channel',
        value: channel,
        label: getStateLabel(channel),
    }));

    const disconnectReasonOptions = [
        'CUSTOMER_DISCONNECT',
        'AGENT_DISCONNECT',
        'THIRD_PARTY_DISCONNECT',
        'TELECOM_PROBLEM',
        'BARGED',
        'CONTACT_FLOW_DISCONNECT',
        'OTHER',
        'EXPIRED',
        'API',
    ].map((reason) => ({
        propertyKey: 'ccr.disconnectReason',
        value: reason,
        label: getStateLabel(reason),
    }));

    const initiationMethodOptions = [
        'INBOUND',
        'OUTBOUND',
        'TRANSFER',
        'CALLBACK',
        'API',
        'QUEUE_TRANSFER',
        'EXTERNAL_OUTBOUND',
        'MONITOR',
        'DISCONNECT',
    ].map((method) => ({
        propertyKey: 'ccr.initiationMethod',
        value: method,
        label: getStateLabel(method),
    }));

    const voiceIdOptions = [
        'Authenticated',
        'Not Authenticated',
        'Not Enrolled',
        'Opted Out',
        'Inconclusive',
        'Error',
    ].map((method) => ({
        propertyKey: 'ccr.voiceIdResult',
        value: method,
        label: getStateLabel(method),
    }));

    console.log('data', data);

    const filteringOptions = [
        ...channelOptions,
        ...disconnectReasonOptions,
        ...initiationMethodOptions,
        ...voiceIdOptions,
        ...data,
    ];

    return (
        <PropertyFilter
            onChange={({detail}) => handleQueryUpdates(detail)}
            onLoadItems={handleLoadItems}
            filteringOptions={filteringOptions}
            filteringStatusType={status}
            tokenLimit={2}
            query={query}
            filteringPlaceholder={'Search filters'}
            filteringLoadingText={'LoadingSuggestions'}
            filteringErrorText={'ErrorFetchingSuggestions'}
            filteringRecoveryText={'Retry'}
            filteringFinishedText={'EndOfResults'}
            filteringEmpty={'NoSuggestionsFound'}
            filteringProperties={filteringProperties}
        />
    );
}
