import React, { useCallback, useState } from "react";
import {
  ColumnLayout,
  Container,
  Header,
  ContentLayout,
  SpaceBetween,
  Textarea,
  Form,
  Input,
  Button,
  FormField,
} from "@cloudscape-design/components";
import RadioGroup from "@cloudscape-design/components/radio-group";
import { useNavigate } from "react-router-dom";
import useChangeDetector from "../../../utils/useChangeDetector";
import { useApiWithFlash } from "../../../utils/hooks/useApiWithFlash";

export default function DialPlanCreate() {
  // state to control changes made
  const { item, setItem } = useChangeDetector<any>({});

  // state
  const [resourceError, setResourceError] = useState<boolean>(true);
  const [resourceErrorMessage, setResourceErrorMessage] = useState<string>("");
  const [formSubmited, setFormSubmited] = useState<boolean>(false);

  // Hooks
  const navigate = useNavigate();
  const { handleApiWithFlash } = useApiWithFlash();

  // Functions to handle form changes
  const handleDropdownChange = useCallback(
    (field: any, value: any) => {
      setItem((prev: any) => ({ ...prev, [field]: value }));
    },
    [setItem],
  );

  const handleTypeChange = (value: string) => {
    handleDropdownChange("type", value);
  };

  const validateResource = (value: string) => {
    // Define patterns
    const generalPattern = /^[a-zA-Z0-9+_-]+$/;
    const e164Pattern = /^\+\d{1,15}$/;

    if (item.type !== "RESOURCE") {
      // Check for E.164 format
      if (!e164Pattern.test(value)) {
        setResourceError(true);
        setResourceErrorMessage(
          "Please provide a valid E.164 phone number format",
        );
        return; // Exit after setting the error
      }
    }

    if (value.length >= 6) {
      if (!generalPattern.test(value)) {
        setResourceError(true);
        setResourceErrorMessage(
          "Only alphanumeric, - _ and + supported. Min 6 characters",
        );
      } else {
        setResourceError(false);
        setResourceErrorMessage("");
      }
    }
  };

  const handleResourceChange = (value: string) => {
    validateResource(value);
    handleDropdownChange("feature", value);
  };

  const handleDescriptionChange = (value: string) => {
    handleDropdownChange("description", value);
  };

  const handlePrefixChange = (value: string) => {
    handleDropdownChange("prefix", value);
  };

  // Handle form submission
  async function handleSubmitForm() {
    setFormSubmited(true);

    let payload = {
      feature: item.feature,
      description: item.description || "",
      data: {
        prefix: item.prefix,
        type: item.type,
      },
    };

    const response = await handleApiWithFlash(
      `/dialPlans/${payload.feature}`,
      "POST",
      {
        successMessage: `Successfully created dial plan ${payload.feature}`,
        errorMessage: `Error creating dial plan ${payload.feature}`,
        data: payload,
      },
    );

    if (response) {
      navigate("/dial-plan");
    }
  }

  return (
    <ContentLayout header={<Header variant="h1"></Header>}>
      <Container
        header={
          <Header
            variant="h2"
            description="Use this form to create a new dial plan"
          >
            Create a new dial plan
          </Header>
        }
      >
        <form
          onSubmit={(e) => {
            console.log(e);
            e.preventDefault();
            // const value = e.target.elements.prompt.value; // Accessing value of the input/textarea with name="prompt"
            handleSubmitForm();
          }}
        >
          <Form
            variant="embedded"
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  formAction="none"
                  variant="link"
                  onClick={() => navigate("/dial-plan")}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  loading={formSubmited}
                  disabled={resourceError}
                >
                  Submit
                </Button>
              </SpaceBetween>
            }
          >
            <SpaceBetween direction="vertical" size="xl">
              <RadioGroup
                onChange={({ detail }) => handleTypeChange(detail.value)}
                value={item?.type}
                items={[
                  {
                    value: "RESOURCE",
                    label: "Resource Group",
                    description:
                      "Create a dial plan group, used for controlling outbound calling destinations",
                  },
                  {
                    value: "VIP",
                    label: "VIP Caller",
                    description: "Add a phone number to the VIP list",
                  },
                  {
                    value: "BLOCKED",
                    label: "Blocked Caller",
                    description: "Add a phone number to the blocked list",
                  },
                ]}
              />
              <ColumnLayout columns={2} variant="text-grid">
                <FormField
                  constraintText={
                    item?.type === "RESOURCE"
                      ? "Only alphanumeric, - _ and + supported"
                      : "Must start with a +"
                  }
                  label={
                    item?.type === "RESOURCE"
                      ? "Provide a resource group name"
                      : "Provide the e164 number"
                  }
                  errorText={resourceErrorMessage}
                >
                  <Input
                    onChange={({ detail }) =>
                      handleResourceChange(detail.value)
                    }
                    value={item?.feature}
                  />
                </FormField>
                <FormField label="Description">
                  <Textarea
                    onChange={({ detail }) =>
                      handleDescriptionChange(detail.value)
                    }
                    value={item?.description}
                  />
                </FormField>
              </ColumnLayout>
              {item.type === "RESOURCE" && (
                <ColumnLayout columns={1} variant="text-grid">
                  <FormField
                    constraintText="Provide a comma seperated list of prefixes using e164"
                    label="Prefix"
                  >
                    <Input
                      onChange={({ detail }) =>
                        handlePrefixChange(detail.value)
                      }
                      value={item?.prefix}
                      placeholder={"+449, +44871, +44872"}
                    />
                  </FormField>
                </ColumnLayout>
              )}
            </SpaceBetween>
          </Form>
        </form>
      </Container>
    </ContentLayout>
  );
}
