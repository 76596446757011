import React, { useState } from 'react';
import SpaceBetween from '@cloudscape-design/components/space-between';
import Button from '@cloudscape-design/components/button';
import Header from '@cloudscape-design/components/header';
import ButtonDropdown, { ButtonDropdownProps } from '@cloudscape-design/components/button-dropdown';
import { Pagination, Spinner, Table } from '@cloudscape-design/components';
import PropertyFilter from '@cloudscape-design/components/property-filter';
import Alert from '@cloudscape-design/components/alert';
import { getTextFilterCounterText } from '../../../utils/text-filter';
import { useCollection } from '@cloudscape-design/collection-hooks';
import { useLocalStorage } from '../../../components/use-local-storage';
import { useColumnWidths } from '../../../components/table/use-column-width';
import { FILTERING_PROPERTIES as filteringProperties } from './filtering_poperties';
import { mutate } from 'swr';
import { ClosuresTableProps, ClosureSummary } from '../types';
import { useSplitPanelControl } from '../../../utils/hooks/splitPanelContext';
import { useNavigate } from 'react-router-dom';
import { DEFAULT_PREFERENCES, EDITABLE_COLUMN_DEFINITIONS, Preferences } from './table.config';

import { TableNoMatchState } from '../../../components/table/commons';
import ClosuresSplitPanelDetails from './closuresSplitPanelDetails';
import { useApiWithFlash } from '../../../utils/hooks/useApiWithFlash';

export default function ClosuresTable({ isLoading, closures = [], error }: ClosuresTableProps) {
  // State
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState<ClosureSummary[]>([]);
  const [columnDefinitions, saveWidths] = useColumnWidths(
    'Cato-Closures-TableEditable-Widths',
    EDITABLE_COLUMN_DEFINITIONS
  );
  const [preferences, setPreferences] = useLocalStorage('Cato-Closures-TableEditable-Preferences', DEFAULT_PREFERENCES);

  // Hooks
  const { setSplitPanelOpen, setSplitPanelContent } = useSplitPanelControl();
  const navigate = useNavigate();
  const { handleApiWithFlash } = useApiWithFlash();

  const { items, actions, filteredItemsCount, collectionProps, propertyFilterProps, paginationProps } = useCollection(
    closures,
    {
      propertyFiltering: {
        filteringProperties,
        noMatch: (
          <TableNoMatchState
            onClearFilter={() => {
              actions.setPropertyFiltering({ tokens: [], operation: 'and' });
            }}
          />
        ),
      },
      filtering: {
        noMatch: (
          <TableNoMatchState
            onClearFilter={() => {
              actions.setPropertyFiltering({ tokens: [], operation: 'and' });
            }}
          />
        ),
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: {},
      selection: {},
    }
  );

  // This is used to handle button presses
  const handleUtilityItemClick = async (event: CustomEvent<ButtonDropdownProps.ItemClickDetails>) => {
    const details = event.detail;
    if (details.id === 'edit') {
      navigate(`/closures/edit/${selectedItems[0].feature}`);
    }
    if (details.id === 'delete') {
      await handleApiWithFlash(`/closures/${selectedItems[0].feature}`, 'DELETE', {
        successMessage: `Successfully deleted closure group  ${selectedItems[0].feature}`,
        errorMessage: `Error deleting closure group ${selectedItems[0].feature}`,
        deleteMessage: `Are you sure you want to delete closure group ${selectedItems[0].feature}`,
        mutateKey: '/closures',
      });

      setSelectedItems([]);
      setSplitPanelOpen(false);
      setSplitPanelContent(null);
    }
  };

  const onRefresh = async () => {
    setRefreshLoading(true);
    await mutate('/closures');
    setRefreshLoading(false);
  };

  const refreshButtonProps = { onClick: onRefresh };

  function onSelectionChange(data: { detail: any }) {
    setSplitPanelContent(<ClosuresSplitPanelDetails selectedItems={data.detail[0]} />);
    setSplitPanelOpen(true);
  }

  return (
    <>
      <Table
        {...collectionProps}
        stickyHeader={true}
        resizableColumns={true}
        onColumnWidthsChange={saveWidths}
        onSelectionChange={({ detail: { selectedItems } }) => {
          onSelectionChange({ detail: selectedItems });
          if (selectedItems && selectedItems.length > 0) {
            const selectedItem: ClosureSummary = selectedItems[0];
            setSelectedItems(() => [selectedItem]);
          }
        }}
        selectedItems={selectedItems as any}
        columnDefinitions={columnDefinitions}
        items={items}
        // submitEdit={handleSubmit}
        trackBy="feature"
        loadingText="Loading closure groups"
        columnDisplay={preferences.contentDisplay}
        wrapLines={preferences.wrapLines}
        stripedRows={preferences.stripedRows}
        stickyColumns={preferences.stickyColumns}
        contentDensity={preferences.contentDensity as 'compact' | 'comfortable'}
        selectionType={'single'}
        loading={isLoading || !closures || refreshLoading}
        header={
          <Header
            variant="awsui-h1-sticky"
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button iconName="refresh" ariaLabel="Refresh" {...refreshButtonProps} />
                <ButtonDropdown
                  disabled={!selectedItems || selectedItems.length === 0}
                  onItemClick={handleUtilityItemClick}
                  loadingText={'loading'}
                  items={[
                    {
                      text: 'Edit',
                      id: 'edit',
                      disabled: false,
                    },
                    {
                      text: 'Delete',
                      id: 'delete',
                      disabled: false,
                    },
                  ]}
                >
                  Actions
                </ButtonDropdown>
                <Button variant="primary" onClick={() => navigate('/closures/create')}>
                  Create closure group
                </Button>
              </SpaceBetween>
            }
          >
            Closure Groups {closures && !isLoading ? `(${closures.length})` : <Spinner />}
          </Header>
        }
        filter={
          <PropertyFilter
            {...propertyFilterProps}
            countText={getTextFilterCounterText(filteredItemsCount)}
            expandToViewport={true}
            filteringAriaLabel={'Filter closure groups'}
            filteringPlaceholder={'Filter closure groups'}
          />
        }
        pagination={<Pagination {...paginationProps} />}
        preferences={<Preferences preferences={preferences} setPreferences={setPreferences} />}
      />
      {error && (
        <Alert statusIconAriaLabel="Error" type="error" header="Error getting data">
          Refresh the page to try again.
        </Alert>
      )}
    </>
  );
}
