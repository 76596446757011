import React, {createContext, useEffect, useState, JSX} from 'react';
import {Auth} from 'aws-amplify';
import {concat} from 'lodash';
import {v4 as uuidv4} from 'uuid';
import fetcher from "../../../../utils/fetcher";

// import { createContext, useState, useRef, JSX, useEffect, Dispatch } from 'react';

export type messageTypes = 'Question' | 'Answer' | 'Intro';
export type messageState = 'Delivered' | 'Sending' | 'Error';

export interface IMessageInterface {
    messageType: messageTypes;
    message: string;
    messageId?: string;
    state: string;
    conversationId: string;
    createdAt: Date;
}

export interface IOnMessageStateInterface {
    onMessageState: 'IN_PROGRESS' | 'DONE';
}

export interface ChatWebSocketContextType {
    ready: boolean;
    messages: IMessageInterface[];
    socket?: WebSocket;
    // eslint-disable-next-line no-unused-vars
    updateMessages: (data: IMessageInterface) => void;
    conversationId?: string;
    promptIntro: string;
    promptConfig?: any; // or be more specific about its type if possible
    setConversationId: (id: string) => void;
    setPromptIntro: (intro: string) => void;
    setPromptConfig: (config: any) => void; // adjust type if needed
}

export const defaultChatWebSocketContextValue = {
    ready: false,
    messages: [],
    socket: null,
    // eslint-disable-next-line no-unused-vars
    updateMessages: (data: IMessageInterface) => {
    },
    conversationId: '',
    promptIntro: '',
    promptConfig: {},
    setConversationId: (id: string) => {
    },
    setPromptIntro: (intro: string) => {
    },
    setPromptConfig: (config: any) => {
    },
};

export interface wssRequestObject {
    sessionId: string;
    userId: string;
    conversationId: string;
    questionText: string;
    state: string;
    action: string;
}

// @ts-ignore
export const ChatWebSocketContext = createContext<ChatWebSocketContextType>(
    // @ts-ignore
    defaultChatWebSocketContextValue
);

type UseStateType<T> = [T | null, React.Dispatch<any>];

/**
 * ChatWebSocketContext is used to connect to the chat web-socket
 * @param children
 * @constructor
 */
export function ChatWebSocketProvider({children}: { children: any }): JSX.Element {
    const [isReady, setIsReady] = useState(false) as UseStateType<boolean>;
    const [value, setValue] = useState([]) as UseStateType<IMessageInterface[]>;
    const [socket, setSocket] = useState(null) as UseStateType<WebSocket>;
    const [onMessageState, setOnMessageState] = useState([]) as UseStateType<
        IOnMessageStateInterface[]
    >;
    const [conversationId, setConversationId] = useState<string | undefined>();
    const [promptIntro, setPromptIntro] = useState<string>('');
    const [promptConfig, setPromptConfig] = useState<any>();


    // const ws = useRef<WebSocket | null>(null);

    const updateMessages = (data: IMessageInterface) => {
        // console.log('update messages', data);
        setValue((prevState: IMessageInterface[]) => concat(prevState, data));
    };

    useEffect(() => {
        // Set conversationId to a UUID on initial render
        const newUUID = uuidv4();
        setConversationId(newUUID);

        console.log("newUUID", newUUID)
        // Fetching promptConfig
        const fetchPromptConfig = async () => {
            try {
                const response = await fetcher('/prompt-dictionary'); // assuming fetcher returns a promise
                if (response && response.data && response.data.length > 0) {
                    setPromptConfig(response.data[0].feature);
                }
                console.log("fetchPromptConfig", response.data[0].feature)
            } catch (error) {
                console.error("Failed to fetch promptConfig:", error);
            }
        };

        fetchPromptConfig();
    }, []);

    useEffect(() => {
        // const signedInUser = await Auth.currentSession();
        // console.log('socket setup', process.env.REACT_APP_WSS_API_URL);

        const initializeWebSocket = async () => {
            console.log('socket setup');
            const signedInUser = await Auth.currentSession();
            const wssUrl = process.env.REACT_APP_WSS_API_URL as string;
            const idToken = signedInUser.getIdToken().getJwtToken();
            const ws = new WebSocket(`${wssUrl}?idToken=${idToken}`);
            ws.onopen = () => {
                console.log('socket connect');
                setIsReady(true);
            };
            ws.onclose = () => {
                console.log('socket closed');
                setIsReady(false);
            };
            ws.onmessage = (event) => {
                console.log('onmessage', event);
                setOnMessageState(JSON.parse(event.data).state); // Expose the current message state to the context provider
                const data = JSON.parse(event.data);
                console.log('data', data);
                if (data?.state === 'IN_PROGRESS') {
                    console.log('do nothing');
                    return;
                }

                const messageObject: IMessageInterface = {
                    messageType: 'Answer',
                    message: data.reply,
                    state: data.state,
                    createdAt: new Date(),
                    conversationId: data.conversationId,
                    messageId: data.messageId,
                };
                console.log('newValue', messageObject);
                updateMessages(messageObject);
            };
            setSocket(ws);
        };

        initializeWebSocket();

        console.log('socket setup');

        return () => {
            if (socket) {
                socket.close();
                console.log('socket unmounting');
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (value) {
            console.log('value', value);
        }
    }, [value]);

    // ESLint: The 'ret' object (at line 70) passed as the value prop to the
    // Context provider (at line 74) changes every render. To fix this consider wrapping it in a useMemo hook.(react/jsx-no-constructed-context-values)
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    const ret = {
        ready: isReady,
        messages: value,
        socket,
        updateMessages,
        onMessageState,
        conversationId,
        promptIntro,
        promptConfig,
        setConversationId,
        setPromptIntro,
        setPromptConfig
    };
    // debugger

    // @ts-ignore
    return <ChatWebSocketContext.Provider value={ret}>{children}</ChatWebSocketContext.Provider>;
}

export const useWebSocket = () => {
    const context = React.useContext(ChatWebSocketContext);
    if (context === undefined) {
        throw new Error('useWebSocket must be used within a WebSocketProvider');
    }
    return context;
};
